import { Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { appRoutes } from "@config";
import { ErrorResetBoundary, useOrganizations } from "@components";

/**
 * MainLayout component
 * Handles the main layout of the application
 */
export const MainLayout = () => {
    const { currentOrganization } = useOrganizations();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "lg"));

    // if there is a current organization and the user is on the base route redirect to the organization locations
    if (currentOrganization && location.pathname === appRoutes.base) {
        return <Navigate to={appRoutes.organization.base} replace />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            bgcolor="#F4F7FE"
            component="main"
            flexGrow={1}
            height={isMobile ? "100%" : "auto"}
            sx={{
                overflowX: "auto",
            }}
        >
            <Toolbar />
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <ErrorResetBoundary>
                    <Outlet />
                </ErrorResetBoundary>
            </Box>
        </Box>
    );
};
