import { useAuth } from "@components";
import { appRoutes } from "@config";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import {
    Avatar,
    Box,
    Divider,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * UserMenu component
 * Handles user account actions and logout
 */
export const UserMenu = () => {
    // hooks
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
    const { user, session } = useAuth();

    // state
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // constructor
    const open = Boolean(anchorEl);

    // handle opening the menu
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // handle closing the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // return nothing if user is not defined
    if (!user) {
        return null;
    }

    // Account actions to be displayed in the menu
    const accountActions = [
        {
            label: "Account",
            icon: <PersonIcon color={"primary"} />,
            onClick: () => {
                navigate(appRoutes.account.base);
                handleClose();
            },
        },
        {
            label: "Security",
            icon: <LockIcon color={"primary"} />,
            onClick: () => {
                navigate(appRoutes.account.security);
                handleClose();
            },
        },
    ];

    // Actions to be displayed in the footer of the menu
    const footerActions = [
        {
            label: "Logout",
            icon: <LogoutIcon color={"error"} />,
            onClick: () => {
                handleClose();
                session.end();
            },
        },
    ];

    // User Avatar
    const UserAvatar = () => {
        return (
            <Avatar
                sx={{
                    width: isMobile ? 20 : 24,
                    height: isMobile ? 20 : 24,
                    mr: isMobile ? -1.5 : 0,
                }}
            >
                <Typography fontSize={isMobile ? 10 : 12} fontWeight={600}>
                    {user.first_name[0]}
                    {user.last_name[0]}
                </Typography>
            </Avatar>
        );
    };

    return (
        <div>
            <Button
                variant={"outlined"}
                color={"inherit"}
                startIcon={<UserAvatar />}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                size={isMobile ? "small" : "medium"}
                onClick={handleClick}
                sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                }}
            >
                {isMobile ? "" : `${user?.first_name} ${user?.last_name}`}
            </Button>

            {/* Account Actions Menu*/}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: { pt: 0 },
                }}
                sx={{ pt: 0 }}
                slotProps={{
                    paper: { sx: { width: 280 } },
                }}
            >
                <Box p={2}>
                    <Typography>
                        {`${user?.first_name} ${user?.last_name}`}
                    </Typography>
                    <Typography
                        variant={"body2"}
                        color={theme.palette.text.secondary}
                    >
                        {user?.email}
                    </Typography>
                </Box>
                <Divider sx={{ mb: 1 }} />

                {/* Account Actions */}
                {accountActions.map(({ label, icon, onClick }, index) => (
                    <MenuItem key={index} onClick={onClick} dense>
                        <ListItemIcon>{icon}</ListItemIcon>
                        {label}
                    </MenuItem>
                ))}

                <Divider />

                {/* Footer Actions */}
                {footerActions.map(({ label, icon, onClick }, index) => (
                    <MenuItem key={index} onClick={onClick} dense>
                        <ListItemIcon>{icon}</ListItemIcon>
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
