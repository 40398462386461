import { KeyboardEvent } from "react";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { Attachments } from "./comments/Attachments.tsx";
import { FormTextFieldElement } from "@rhf-kit/mui";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { IAnnotation, IFileAttachment } from "@api";
import { UploadAttachment } from "./UploadAttachment.tsx";
import { useFormContext } from "react-hook-form";
import { MAX_ATTACHMENT_LIMIT } from "@config";

interface IProps {
    setIsEditingAnnotation: (isEditing: boolean) => void;
    setAnnotationEditing?: (annotation: IAnnotation | undefined) => void;
    loading: boolean;
}

export const EditAnnotationForm = ({
    setIsEditingAnnotation,
    setAnnotationEditing,
    loading,
}: IProps) => {
    const { watch } = useFormContext();

    const attachments: IFileAttachment[] = watch("attachments");

    // submit form on enter key press
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            (event.target as HTMLElement)
                .closest("form")
                ?.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
        }
    };

    return (
        <Stack gap={1} mt={1}>
            {/* Attachment Thumbnails */}
            <Attachments fileAttachments={attachments} editable />

            {attachments.length === MAX_ATTACHMENT_LIMIT && (
                <Typography variant={"caption"} color={"red"}>
                    Image limit reached
                </Typography>
            )}
            <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                {/* Upload Attachment button */}
                <UploadAttachment
                    disabled={
                        loading || attachments.length === MAX_ATTACHMENT_LIMIT
                    }
                />

                {/* Input element */}
                <FormTextFieldElement
                    name={"title"}
                    variant={"standard"}
                    multiline
                    fullWidth
                    minRows={2}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    color={"primary"}
                                    size={"small"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEditingAnnotation(false);
                                        if (setAnnotationEditing) {
                                            setAnnotationEditing(undefined);
                                        }
                                    }}
                                >
                                    <CancelIcon fontSize={"small"} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-end"} mt={1}>
                <Button loading={loading}>Save</Button>
            </Stack>
        </Stack>
    );
};
