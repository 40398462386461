import axios from "axios";
import { API_URL } from "@config";
import { ILocation, ILocationUpdate } from "./locations.types.ts";
import { ICollection, ICollectionCreate } from "../collections";
import { IFilter, Page } from "../api.types.ts";
import { buildFilterQuery } from "@utils";
import {
    IAnnotation,
    IAnnotationCreate,
    uploadAnnotationAttachments,
} from "../annotations";
import { IFileAttachment } from "../attachments";

const API = `${API_URL}/locations`;

// get a single location
export const getLocation = async (locationId: number) => {
    const result: { data: ILocation } = await axios.get(`${API}/${locationId}`);
    return result.data;
};

// geta all locations
export const getLocations = async () => {
    const result = await axios.get<Page<ILocation>>(`${API}`);
    return result.data;
};

// Update a location
export const updateLocation = async (
    locationId: number,
    body: ILocationUpdate
) => {
    const result: { data: ILocation } = await axios.put(
        `${API}/${locationId}`,
        body
    );
    return result.data;
};

// deletes a location
export const deleteLocation = async (locationId: number) => {
    const result = await axios.delete(`${API}/${locationId}`);
    return result.data;
};

// gets the collections for a location
export const getLocationCollections = async (
    locationId: number,
    page: number = 1,
    size: number = 50,
    filter?: IFilter
) => {
    const result = await axios.get<Page<ICollection>>(
        `${API}/${locationId}/collections?page=${page}&size=${size}&${buildFilterQuery(filter)}`
    );
    return result.data;
};

// creates a collection for a location
export const createCollection = async (
    locationId: number,
    body: ICollectionCreate
) => {
    const result: { data: ICollection } = await axios.post(
        `${API}/${locationId}/collections`,
        body
    );
    return result.data;
};

export const getAnnotations = async (
    locationId: number,
    collectionDate: string | Date | undefined,
    page: number = 1,
    size: number = 50
) => {
    const url = collectionDate
        ? `${API}/${locationId}/annotations?collection_date=${encodeURIComponent(collectionDate as string)}&page=${page}&size=${size}`
        : `${API}/${locationId}/annotations?page=${page}&size=${size}`;
    const result = await axios.get<Page<IAnnotation>>(url);
    return result.data;
};

export const createAnnotation = async (
    locationId: number,
    body: IAnnotationCreate,
    attachments: IFileAttachment[]
) => {
    // If there are attachments create the annotation and then upload the attachments with a ref to the annotation
    if (attachments.length >= 0) {
        const annotationResult = await axios.post<IAnnotation>(
            `${API}/${locationId}/annotations`,
            body
        );

        const createdAnnotation = annotationResult.data;

        return await uploadAnnotationAttachments(
            createdAnnotation.id,
            attachments
        );
    } else {
        return await axios.post<IAnnotation>(
            `${API}/${locationId}/annotations`,
            body
        );
    }
};
