import { ILocation } from "@api";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
    FormControl,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    SelectChangeEvent,
    useMediaQuery,
    useTheme,
} from "@mui/material";

interface ILocationsSwitcherProps {
    /**
     * Current selected location
     */
    selectedLocation: ILocation | undefined;
    /**
     * Handle changing the selected location
     */
    setSelectedLocation: (location: ILocation) => void;
    /**
     * List of locations
     */
    locations: ILocation[];
}

/**
 * Switcher for selecting a location
 */
export const LocationsSwitcher = ({
    locations,
    selectedLocation,
    setSelectedLocation,
}: ILocationsSwitcherProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        // Find the location object based on the selected ID
        const newLocation = locations.find(
            (location) => location.id === event.target.value
        );
        if (newLocation) {
            setSelectedLocation(newLocation);
        }
    };

    return (
        <FormControl>
            <InputLabel id="location-select-label">Location</InputLabel>
            <Select
                labelId={"location-select-label"}
                value={selectedLocation ? selectedLocation.id : ""}
                size={isMobile ? "small" : "medium"}
                renderValue={(value) => {
                    const location = locations.find(
                        (location) => location.id === value
                    );
                    return location ? location.name : "";
                }}
                onChange={handleSelectChange}
                label={"Location"}
                sx={{
                    width: { xs: 80, sm: 140, md: "auto" },
                    minWidth: { xs: 80, sm: 140, md: 200 },
                }}
            >
                <MenuItem
                    id={"menu-header"}
                    value={"locations"}
                    disableRipple
                    dense
                    sx={{
                        backgroundColor: "Menu",
                        ":hover": {
                            backgroundColor: "#ffffff",
                            cursor: "default",
                        },
                    }}
                >
                    Locations
                </MenuItem>
                {locations.length === 0 && (
                    <MenuItem disabled>No locations</MenuItem>
                )}
                {locations.map((location) => (
                    <MenuItem key={location.id} value={location.id} dense>
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        {location.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
