import { API_URL } from "@config";
import axios from "axios";
import { IOrganization } from "../organizations";
import { IProfile, IProfileUpdate } from "./profile.types.ts";
import { Page } from "../api.types.ts";
const API = `${API_URL}/profile`;

export const getProfile = async () => {
    const result: { data: IProfile } = await axios.get(`${API}`);
    return result.data;
};

export const updateProfile = async (data: IProfileUpdate) => {
    const result: { data: IProfile } = await axios.put(`${API}`, data);
    return result.data;
};

export const getUserOrganizations = async (
    page: number = 1,
    size: number = 50
) => {
    const result = await axios.get<Page<IOrganization>>(
        `${API}/organizations?page=${page}&size=${size}`
    );
    return result.data;
};
