import { MembersEditCard } from "./components";
import { BackButton } from "@components";

/**
 * MembersEdit component is a page for editing organization members. It includes a back button and a members edit card.
 */
const MembersEdit = () => {
    return (
        <div>
            <BackButton />
            <MembersEditCard />
        </div>
    );
};

export default MembersEdit;
