import { IApiAxiosError, changePassword } from "@api";
import { CancelButton, useAuth } from "@components";
import PasswordIcon from "@mui/icons-material/Password";
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import { FormButton as Button, FormContainer } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { ChangePasswordForm } from "../ChangePasswordForm";

interface IFormData {
    password: string;
    newPassword: string;
    confirmPassword: string;
}

export const ChangePasswordCard = () => {
    const { user } = useAuth();

    const [formReset, setFormReset] = useState<boolean>(false);

    const updatePasswordMutation = useMutation({
        mutationFn: ({
            currentPassword,
            newPassword,
        }: {
            currentPassword: string;
            newPassword: string;
        }) => changePassword(currentPassword, newPassword),
        onSuccess: () => {
            setFormReset(!formReset);
            enqueueSnackbar("Password changed", { variant: "success" });
        },
        onError: (err: IApiAxiosError) => {
            enqueueSnackbar(err.response?.data.detail, {
                variant: "error",
            });
        },
    });

    const onSubmit = (data: IFormData) => {
        if (!user) {
            enqueueSnackbar("Failed to change password", { variant: "error" });
            return;
        }

        const update = {
            currentPassword: data.password,
            newPassword: data.newPassword,
        };

        updatePasswordMutation.mutate(update);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        sx={{
                            backgroundColor: "transparent",
                            boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.08);",
                        }}
                    >
                        <PasswordIcon color="primary" />
                    </Avatar>
                }
                title={"Change Password"}
                titleTypographyProps={{ variant: "h6" }}
            />
            <FormContainer
                onSuccess={onSubmit}
                defaultValues={{
                    password: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
            >
                <CardContent>
                    <ChangePasswordForm formReset={formReset} />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <CancelButton />
                    <Button variant="contained">Save Changes</Button>
                </CardActions>
            </FormContainer>
        </Card>
    );
};
