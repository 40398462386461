import { useAuth } from "@components";
import { appRoutes, theme } from "@config";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
    Avatar,
    Box,
    List,
    Skeleton,
    Stack,
    SvgIcon,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { AccountNavLink } from "./AccountNavLink";

export interface IAccountNavItem {
    label: string;
    inactiveIcon: typeof SvgIcon;
    activeIcon: typeof SvgIcon;
    path: string;
    end: boolean;
}

export const AccountNav = () => {
    const { user } = useAuth();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "md"));

    const accountNavItems: IAccountNavItem[] = [
        {
            label: "Account",
            inactiveIcon: AccountCircleOutlinedIcon,
            activeIcon: AccountCircleIcon,
            path: appRoutes.account.base,
            end: true,
        },
        {
            label: "Security",
            inactiveIcon: LockOutlinedIcon,
            activeIcon: LockIcon,
            path: appRoutes.account.security,
            end: false,
        },
    ];

    const skeleton = () => {
        return (
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
                width={"100%"}
            >
                <Skeleton variant="circular">
                    <Avatar />
                </Skeleton>
                <Box width={"100%"}>
                    <Typography variant="subtitle1">
                        <Skeleton />
                    </Typography>
                    <Typography
                        variant="caption"
                        color={theme.palette.text.secondary}
                    >
                        <Skeleton />
                    </Typography>
                </Box>
            </Stack>
        );
    };

    const userAvatar = () => {
        // If no user, its safe to assume that the user is loading, show skeleton
        if (!user) return skeleton();
        return (
            <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Avatar>
                    <Typography fontSize={12} fontWeight={600}>
                        {user.first_name[0]}
                        {user.last_name[0]}
                    </Typography>
                </Avatar>
                <Box>
                    <Typography variant="subtitle1">
                        {user.first_name} {user.last_name}
                    </Typography>
                    <Typography
                        variant="caption"
                        color={theme.palette.text.secondary}
                    >
                        {user.email}
                    </Typography>
                </Box>
            </Stack>
        );
    };

    return (
        <Stack minWidth={256} gap={isMobile ? 2 : 0}>
            {isMobile && userAvatar()}
            <Stack gap={1}>
                <Typography variant="caption">Personal</Typography>
                <List>
                    {accountNavItems.map((item, index) => (
                        <AccountNavLink key={index} route={item} />
                    ))}
                </List>
            </Stack>
            {!isMobile && userAvatar()}
        </Stack>
    );
};
