import { getAnnotationComments, ICommentCreate, IFileAttachment } from "@api";
import {
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { CommentContent } from "./CommentContent.tsx";
import { AddComment } from "./AddComment.tsx";
import { useMutationState, useQuery } from "@tanstack/react-query";
import { useAuth } from "@components";
import { CommentHeader } from "./CommentHeader.tsx";
import ErrorIcon from "@mui/icons-material/Error";
import { Attachments } from "./Attachments.tsx";
import { useEffect, useRef } from "react";

interface IOptimisticComment {
    newComment: ICommentCreate;
    attachments: IFileAttachment[];
}

interface IAnnotationCommentsProps {
    /**
     * The annotation id.
     */
    annotationId?: number;
}

export const AnnotationComments = ({
    annotationId,
}: IAnnotationCommentsProps) => {
    const { user } = useAuth();
    const listRef = useRef<HTMLUListElement>(null);

    const {
        data: comments,
        isPending,
        isError,
    } = useQuery({
        queryKey: ["comments", annotationId],
        queryFn: () => getAnnotationComments(annotationId ?? 0),
        enabled: !!annotationId,
    });

    // optimistic comments that are pending
    const optimisticComments = useMutationState<IOptimisticComment>({
        filters: {
            mutationKey: ["addComment", annotationId],
            status: "pending",
        },
        select: (mutation) => mutation.state.variables as IOptimisticComment,
    });

    useEffect(() => {
        // Scroll to the bottom when optimisticComments change
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [optimisticComments]);

    // if there is no annotation id, this is the default state
    if (!annotationId) {
        return (
            <Box display={"flex"} flexDirection={"column"} flexGrow={1} py={2}>
                <AddComment annotationId={0} />
            </Box>
        );
    }

    // if the comments are loading show a loading spinner
    if (isPending)
        return (
            <CircularProgress
                size={24}
                sx={{ mt: 1, maxWidth: "sm", marginX: "auto" }}
            />
        );

    // if there is an error loading comments, show an error message
    if (isError)
        return (
            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                flexGrow={1}
            >
                <ErrorIcon
                    fontSize={"large"}
                    sx={{ color: "text.disabled", mb: 1 }}
                />
                <Typography variant={"body2"} textAlign={"center"} gutterBottom>
                    An error occurred loading comments.
                </Typography>
            </Box>
        );

    // at this point, there are comments
    return (
        <>
            <List
                ref={listRef}
                sx={{ width: "100%", maxHeight: 375, overflowY: "auto" }}
            >
                {/* Render comments */}
                {comments.items.map((comment) => (
                    <Stack key={comment.id}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <CommentHeader
                                        heading={`${comment.created_by.first_name} ${comment.created_by.last_name}`}
                                        comment={comment}
                                    />
                                }
                                secondary={
                                    <CommentContent
                                        text={comment.text}
                                        date={comment.created_at}
                                    />
                                }
                            />
                        </ListItem>

                        {/* Attachment Thumbnails */}
                        <Box mx={2}>
                            <Attachments attachments={comment.attachments} />
                        </Box>
                    </Stack>
                ))}

                {/* Render optimistic comments*/}
                {optimisticComments.map((comment, index) => (
                    <Stack key={index}>
                        <ListItem>
                            <ListItemText
                                primary={`${user?.first_name} ${user?.last_name}`}
                                secondary={
                                    <CommentContent
                                        text={comment.newComment.text}
                                        date={new Date()}
                                    />
                                }
                            />
                        </ListItem>

                        {/* Attachment Thumbnails */}
                        <Box mx={2}>
                            <Attachments
                                fileAttachments={comment.attachments}
                            />
                        </Box>
                    </Stack>
                ))}
            </List>

            <Box flexGrow={1} />

            {annotationId && <AddComment annotationId={annotationId} />}
        </>
    );
};
