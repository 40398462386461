import { FormContainer } from "@rhf-kit/mui";
import { CommentForm } from "./CommentForm.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAnnotationComment, ICommentCreate, IFileAttachment } from "@api";

interface IFormData {
    commentText: string;
    attachments: IFileAttachment[];
}

interface IAddCommentProps {
    /**
     * Annotation Id to which the comment will be added.
     */
    annotationId: number;
}

/**
 * Add comment component that allows the user to add a comment to an annotation.
 */
export const AddComment = ({ annotationId }: IAddCommentProps) => {
    const queryClient = useQueryClient();

    // mutation to create a new comment
    const { mutate, isPending } = useMutation({
        mutationFn: ({
            newComment,
            attachments,
        }: {
            newComment: ICommentCreate;
            attachments: IFileAttachment[];
        }) => createAnnotationComment(newComment, attachments, annotationId),
        onSettled: async () => {
            return await queryClient.invalidateQueries({
                queryKey: ["comments", annotationId],
            });
        },
        mutationKey: ["addComment", annotationId],
    });

    const defaultValues: IFormData = {
        commentText: "",
        attachments: [],
    };

    // handle form submission
    const handleSubmit = (formData: IFormData) => {
        // Do not submit empty comments
        if (formData.commentText.trim().length === 0) return;

        const commentToCreate: ICommentCreate = {
            text: formData.commentText,
        };

        // create the comment
        mutate({
            newComment: commentToCreate,
            attachments: formData.attachments,
        });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
            <CommentForm loading={isPending} disabled={annotationId === 0} />
        </FormContainer>
    );
};
