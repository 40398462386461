import { getOrganizationLocations, ILocation } from "@api";
import { appRoutes, generateRoute } from "@config";
import { Box } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocationsSwitcher } from "./LocationsSwitcher.tsx";

interface ILocationToolbarProps {
    /**
     * Organization ID
     */
    organizationId: number;
    /**
     * Current selected location
     */
    selectedLocation: ILocation | undefined;
    /**
     * Handle changing the selected location
     */
    setSelectedLocation: (location: ILocation | undefined) => void;
}

/**
 * Toolbar for selecting and managing location
 */
export const LocationToolbar = ({
    organizationId,
    setSelectedLocation,
    selectedLocation,
}: ILocationToolbarProps) => {
    const { locationId } = useParams();
    const navigate = useNavigate();

    const { data } = useSuspenseQuery({
        queryKey: ["organizationLocations", organizationId],
        queryFn: () => getOrganizationLocations(organizationId),
    });

    // Handle setting the selected location
    useEffect(() => {
        if (data && data.items.length > 0) {
            // If no locationId is provided and no location is selected, default to the first location
            if (!locationId && !selectedLocation) {
                setSelectedLocation(data.items[0]);
            }

            // If a locationId is provided and no location is selected, set the selected location if applicable
            if (locationId && !selectedLocation) {
                const selected = data.items.find(
                    (location) => location.id === parseInt(locationId)
                );
                setSelectedLocation(selected || data.items[0]);
            }

            // if a selected location is provided, ensure it is still in the list of locations'
            // this is necessary for when a location is deleted
            if (selectedLocation) {
                const selected = data.items.find(
                    (location) => location.id === selectedLocation.id
                );
                if (!selected) {
                    setSelectedLocation(data.items[0]);
                }
            }
        } else {
            setSelectedLocation(undefined);
        }
    }, [data, locationId, selectedLocation, setSelectedLocation]);

    // Handle navigating to the selected location
    useEffect(() => {
        if (selectedLocation) {
            const route = generateRoute(
                appRoutes.organization.map.location.base,
                {
                    locationId: selectedLocation.id,
                }
            );

            // only navigate if the current route does not match the selected location
            if (!window.location.pathname.includes(route)) {
                navigate(route);
            }
        } else if (data.items.length === 0 && !selectedLocation) {
            navigate(appRoutes.organization.map.base);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation, data]);

    return (
        <Box>
            <LocationsSwitcher
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                locations={data.items}
            />
        </Box>
    );
};
