import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { MouseEvent } from "react";
import { NavLink } from "react-router-dom";
import { IAccountNavItem } from "./AccountNav";

interface IProps {
    route: IAccountNavItem;
}

export const AccountNavLink = ({ route }: IProps) => {
    const {
        end,
        inactiveIcon: InactiveIcon,
        activeIcon: ActiveIcon,
        label,
        path,
    } = route;

    const handleClick = (
        event: MouseEvent<HTMLLIElement>,
        isActive: boolean
    ) => {
        if (isActive) {
            event.preventDefault();
        }
    };
    return (
        <NavLink
            to={path}
            style={{
                textDecoration: "none",
                color: "inherit",
            }}
            end={end}
        >
            {({ isActive }) => (
                <ListItem
                    onClick={(event) => handleClick(event, isActive)}
                    key={label}
                    sx={{ padding: 0, mb: 1 }}
                >
                    <ListItemButton
                        selected={isActive}
                        sx={{ borderRadius: 2 }}
                    >
                        <ListItemIcon>
                            {isActive && <ActiveIcon />}
                            {!isActive && <InactiveIcon />}
                        </ListItemIcon>
                        <ListItemText primary={label} />
                    </ListItemButton>
                </ListItem>
            )}
        </NavLink>
    );
};
