import { DashboardContainer, useOrganizations } from "@components";
import { LocationEditor } from "../map";
import { LocationsList } from "./components";

const Locations = () => {
    const { currentOrganization } = useOrganizations();

    if (!currentOrganization) throw new Error("Organization not found");

    return (
        <DashboardContainer
            title={"Locations"}
            action={
                <LocationEditor
                    organizationId={currentOrganization.id}
                    tableView
                />
            }
        >
            <LocationsList organizationId={currentOrganization.id} />
        </DashboardContainer>
    );
};

export default Locations;
