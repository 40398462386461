import { GridFilterInputValueProps } from "@mui/x-data-grid";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import { format, parseISO } from "date-fns";

export const DateFilterInput = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    // input value for the date picker
    const dateValue = item.value ? parseISO(item.value) : null;

    const handleFilterChange: DatePickerProps<Date>["onChange"] = (
        newValue
    ) => {
        if (!newValue) {
            applyValue({ ...item, value: null });
            return;
        }

        // Format value to match date format stored in db
        const formattedValue = format(new Date(newValue), "yyyy-MM-dd");

        applyValue({ ...item, value: formattedValue });
    };

    return (
        <Box width={"150px"} ml={1}>
            <DatePicker
                name={"custom-date-filter"}
                label={"Date"}
                value={dateValue}
                onChange={handleFilterChange}
                formatDensity={"dense"}
                slotProps={{
                    textField: { variant: "standard", focused: true },
                }}
                autoFocus
            />
        </Box>
    );
};
