import { IAttachment, IFileAttachment } from "@api";
import { Stack } from "@mui/material";
import { Attachment } from "../Attachment.tsx";
import { PhotoProvider } from "react-photo-view";

interface IProps {
    attachments?: IAttachment[];
    fileAttachments?: IFileAttachment[];
    editable?: boolean;
}

export const Attachments = ({
    attachments,
    fileAttachments,
    editable,
}: IProps) => {
    return (
        <PhotoProvider maskOpacity={0.9}>
            <Stack
                direction={"row"}
                gap={1.5}
                flexWrap={"wrap"}
                alignItems={"center"}
            >
                {/* DB Attachments */}
                {attachments &&
                    attachments.map((attachment) => (
                        <Attachment
                            key={attachment.id}
                            commentAttachment={attachment}
                            editable={editable}
                        />
                    ))}

                {/* File Attachments */}
                {fileAttachments &&
                    fileAttachments.map((attachment, index) => (
                        <Attachment
                            key={`${attachment.file.name}-${index}`}
                            attachment={attachment}
                            editable={editable}
                        />
                    ))}
            </Stack>
        </PhotoProvider>
    );
};
