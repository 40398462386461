import { RefObject, useEffect, useState } from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { MapRef } from "react-map-gl";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Control } from "@components";
import { Tooltip } from "@mui/material";
import { IGeometryCreate } from "@api";

interface IFullscreenControlProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
    /**
     * The new geometry being created
     */
    newGeometry?: IGeometryCreate;
}

/**
 * Fullscreen control for the map
 */
export const FullscreenControl = ({
    mapRef,
    newGeometry,
}: IFullscreenControlProps) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    // handle fullscreen or exit fullscreen
    const handleFullscreen = () => {
        const container = mapRef.current?.getContainer();
        if (container) {
            if (!document.fullscreenElement) {
                container.requestFullscreen().then(() => setIsFullScreen(true));
            } else {
                document.exitFullscreen().then(() => setIsFullScreen(false));
            }
        }
    };

    // exit fullscreen when a new geometry is created
    useEffect(() => {
        if (newGeometry) {
            const container = mapRef.current?.getContainer();

            // exit fullscreen if the map is in fullscreen mode
            if (container && document.fullscreenElement) {
                document.exitFullscreen().then(() => setIsFullScreen(false));
            }
        }
    }, [mapRef, newGeometry]);

    // prevents exiting fullscreen when space or enter is clicked
    useEffect(() => {
        const preventExitOnSpaceOrEnter = (event: KeyboardEvent) => {
            if (event.key === " " || event.key === "Enter") {
                event.preventDefault();
            }
        };
        if (isFullScreen) {
            document.addEventListener("keydown", preventExitOnSpaceOrEnter);
        } else {
            document.removeEventListener("keydown", preventExitOnSpaceOrEnter);
        }
        return () => {
            document.removeEventListener("keydown", preventExitOnSpaceOrEnter);
        };
    }, [isFullScreen]);

    return (
        <Tooltip
            title={"Fullscreen"}
            PopperProps={{
                disablePortal: true,
            }}
            placement="left"
        >
            <Control onClick={handleFullscreen}>
                {!isFullScreen && <FullscreenIcon />}
                {isFullScreen && <FullscreenExitIcon />}
            </Control>
        </Tooltip>
    );
};
