import { Box, Divider, InputAdornment, Stack, Typography } from "@mui/material";
import { FormTextFieldElement, FormButton as Button } from "@rhf-kit/mui";
import SendIcon from "@mui/icons-material/Send";
import { useFormContext } from "react-hook-form";
import { KeyboardEvent, useEffect } from "react";
import { UploadAttachment } from "../UploadAttachment.tsx";
import { MAX_ATTACHMENT_LIMIT, MAX_COMMENT_LENGTH } from "@config";
import { IFileAttachment } from "@api";
import { Attachments } from "./Attachments.tsx";

interface ICommentFormProps {
    /**
     * Loading state of the form.
     */
    loading?: boolean;
    /**
     * Disabled state of the form.
     */
    disabled?: boolean;
}

/**
 * Comment form component that allows the user to add a comment to an annotation.
 */
export const CommentForm = ({ loading, disabled }: ICommentFormProps) => {
    const { watch, reset } = useFormContext();

    // current length of the comment text
    const commentLength = watch("commentText")
        ? watch("commentText").length
        : 0;

    // current attachments
    const attachments: IFileAttachment[] = watch("attachments");

    // submit on enter key
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            (event.target as HTMLElement)
                .closest("form")
                ?.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
        }
    };

    // if form is successfully submitted, reset the form
    useEffect(() => {
        if (loading) {
            reset();
        }
    }, [reset, loading]);

    return (
        <Stack
            alignItems={"flex-end"}
            direction={"row"}
            width={"100%"}
            spacing={1}
        >
            {/* Upload Attachment button  */}
            <UploadAttachment
                disabled={
                    loading || attachments.length === MAX_ATTACHMENT_LIMIT
                }
            />
            <Box
                width={"100%"}
                border={attachments.length > 0 ? "1px solid" : "none"}
                borderColor={"primary.main"}
                px={1}
                borderRadius={1}
                display={"flex"}
                flexDirection={"column"}
            >
                {/* Attachment Thumbnails */}
                {attachments.length > 0 && (
                    <>
                        <Box minHeight={80} py={1}>
                            <Attachments
                                fileAttachments={attachments}
                                editable
                            />
                        </Box>

                        {attachments.length === MAX_ATTACHMENT_LIMIT && (
                            <Typography variant={"caption"} color={"red"}>
                                Image limit reached
                            </Typography>
                        )}

                        <Divider
                            sx={{ mx: -1, mb: 1, borderColor: "primary.main" }}
                        />
                    </>
                )}

                <Box height={"100%"}>
                    {/* Comment text field */}
                    <FormTextFieldElement
                        name={"commentText"}
                        placeholder={"Comment..."}
                        variant={"standard"}
                        size={"small"}
                        rules={{
                            maxLength: MAX_COMMENT_LENGTH,
                            required: "Comment text is required",
                        }}
                        required
                        fullWidth
                        multiline
                        disabled={disabled || loading}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <Typography
                                        color={
                                            commentLength > MAX_COMMENT_LENGTH
                                                ? "error"
                                                : "text.secondary"
                                        }
                                    >
                                        {`${commentLength} / ${MAX_COMMENT_LENGTH}`}
                                    </Typography>
                                </InputAdornment>
                            ),
                            disableUnderline: attachments.length > 0,
                        }}
                    />
                </Box>
            </Box>
            <Button sx={{ minWidth: 48, maxHeight: 48 }} loading={loading}>
                <SendIcon />
            </Button>
        </Stack>
    );
};
