import {
    createCollection,
    ICollection,
    ICollectionCreate,
    ILocation,
} from "@api";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "@mui/material";
import { FormButton as Button, FormContainer } from "@rhf-kit/mui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { SelectLocation } from "./SelectLocation";
import { UploadImages } from "./UploadImages";

export type Steps = "location" | "upload";

interface IFormData {
    location: ILocation | null;
    collectionDate: Date;
    images: {
        RGB: File | null;
        NIR: File | null;
        RED_EDGE: File | null;
        DSM: File | null;
    };
}

interface IProps {
    /**
     * Function to set the selected collection
     */
    setSelectedCollection: (collection: ICollection | undefined) => void;
    /**
     * An existing collection that can be viewed
     */
    selectedCollection?: ICollection;
}

export const AddDroneCollection = ({
    setSelectedCollection,
    selectedCollection,
}: IProps) => {
    const [step, setStep] = useState<Steps>("location");

    // modal view state
    const [open, setOpen] = useState(false);

    // open modal view
    const handleClickOpen = useCallback(() => {
        setOpen(true);
        if (selectedCollection) {
            setStep("upload");
        } else {
            setStep("location");
        }
    }, [selectedCollection]);

    // close modal view
    const handleClose = () => {
        setOpen(false);
    };

    const queryClient = useQueryClient();

    // Mutation to create a collection
    const { mutate, isPending } = useMutation({
        mutationFn: ({
            locationId,
            newCollection,
        }: {
            locationId: number;
            newCollection: ICollectionCreate;
            images: IFormData["images"];
        }) => createCollection(locationId, newCollection),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ["collections"],
            });
            queryClient.invalidateQueries({
                queryKey: ["location-collections"],
            });
            setSelectedCollection(data);
        },
    });

    const handleSubmit = (formData: IFormData) => {
        if (!formData.location) {
            enqueueSnackbar("Please select a location", {
                variant: "error",
            });
            return;
        }

        // Check if at least one image is attached
        const atLeastOneFileAttached = Object.values(formData.images).some(
            (file) => file !== null
        );

        // If no files are attached, show an error message and return
        if (!atLeastOneFileAttached) {
            enqueueSnackbar("Please attach at least one image", {
                variant: "error",
            });
            return;
        }

        // Create the new collection object
        const newCollection: ICollectionCreate = {
            collection_date: formData.collectionDate,
        };

        // Call the mutation to create the collection
        mutate({
            locationId: formData.location.id,
            newCollection,
            images: formData.images,
        });
    };

    const defaultValues: IFormData = {
        location: null,
        collectionDate: new Date(),
        images: {
            RGB: null,
            NIR: null,
            RED_EDGE: null,
            DSM: null,
        },
    };

    // Function to handle the dialog exiting. This is to wait for the dialog to disappear before resetting the collection
    const handleExited = () => {
        setSelectedCollection(undefined);
    };

    useEffect(() => {
        // If a collection is selected, open the modal view
        if (selectedCollection) {
            handleClickOpen();
        }
    }, [handleClickOpen, selectedCollection]);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
            >
                Add Drone Collection
            </Button>
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
                onTransitionExited={handleExited}
                PaperProps={{
                    sx: {
                        padding: 2,
                    },
                }}
            >
                <FormContainer
                    defaultValues={defaultValues}
                    onSuccess={handleSubmit}
                >
                    {/* Location Select step */}
                    {step === "location" && (
                        <SelectLocation
                            onClose={handleClose}
                            setStep={setStep}
                        />
                    )}

                    {/* Upload step */}
                    {step === "upload" && (
                        <UploadImages
                            handleClose={handleClose}
                            selectedCollection={selectedCollection}
                            isLoading={isPending}
                            setStep={setStep}
                        />
                    )}
                </FormContainer>
            </Dialog>
        </>
    );
};
