import { Box } from "@mui/material";
import { useState } from "react";
import { MainLayout, SideNav, Topbar } from "./components";

const Layout = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    return (
        <Box display={"flex"} minHeight={"100vh"}>
            <Topbar
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
                isClosing={isClosing}
            />
            <SideNav
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
                setIsClosing={setIsClosing}
            />

            <MainLayout />
        </Box>
    );
};

export default Layout;
