import { PropsWithChildren, forwardRef } from "react";
import { FormButtonProps } from "@rhf-kit/mui";
import { theme } from "@config";
import { Button } from "@mui/material";

/**
 * A button component that is reused for map controls
 */
export const Control = forwardRef<
    HTMLButtonElement,
    PropsWithChildren<FormButtonProps>
>(({ children, ...other }, ref) => {
    return (
        <Button
            ref={ref}
            variant="contained"
            sx={{
                width: 36,
                height: 36,
                minWidth: 0,
                "&:disabled": {
                    backgroundColor: theme.palette.primary.main,
                    opacity: 0.95,
                },
            }}
            {...other}
        >
            {children}
        </Button>
    );
});
