import { Box, CardContent, Stack, Typography } from "@mui/material";
import { UploadAttachment } from "./UploadAttachment.tsx";
import { FormTextFieldElement } from "@rhf-kit/mui";
import { KeyboardEvent, useEffect } from "react";
import { MAX_ATTACHMENT_LIMIT } from "@config";
import { useFormContext } from "react-hook-form";
import { Attachments } from "./comments/Attachments.tsx";

interface IProps {
    loading: boolean;
}

export const AnnotationForm = ({ loading }: IProps) => {
    const { watch, reset } = useFormContext();

    const attachments = watch("attachments");

    // submit on enter key
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            (event.target as HTMLElement)
                .closest("form")
                ?.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
        }
    };

    // reset form when loading
    useEffect(() => {
        if (loading) {
            reset();
        }
    }, [loading, reset]);

    return (
        <CardContent
            sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Stack>
                {/* Attachment Thumbnails */}
                {attachments.length > 0 && (
                    <>
                        <Box pb={1}>
                            <Attachments
                                fileAttachments={attachments}
                                editable
                            />
                        </Box>
                        {attachments.length === MAX_ATTACHMENT_LIMIT && (
                            <Typography variant={"caption"} color={"red"}>
                                Image limit reached
                            </Typography>
                        )}
                    </>
                )}
                <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                    {/* Upload attachment button */}
                    <UploadAttachment
                        disabled={
                            loading ||
                            attachments.length === MAX_ATTACHMENT_LIMIT
                        }
                    />

                    {/* Input element */}
                    <FormTextFieldElement
                        name={"title"}
                        label={"Annotation text"}
                        required={true}
                        multiline={true}
                        fullWidth
                        variant={"standard"}
                        onKeyDown={handleKeyPress}
                        autoFocus
                    />
                </Stack>
            </Stack>
        </CardContent>
    );
};
