import { useState, useEffect, useMemo, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    getOrganizationUsers,
    deleteOrganizationAccount,
    IOrganizationAccount,
} from "@api";
import {
    DataTable,
    DeleteConfirmationDialog,
    useOrganizations,
} from "@components";
import { useQuery } from "@tanstack/react-query";
import {
    GridActionsCellItem,
    GridColDef,
    GridRowParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { appRoutes, generateRoute } from "@config";
import { Box } from "@mui/material";
import { useFilterModel } from "@hooks";

export type MemberRow = {
    id: number;
    orgId: number;
    first_name: string;
    last_name: string;
    email: string;
    member: IOrganizationAccount;
};

/**
 * Members Table Component that displays the members of the organization
 */
export const MembersTable = () => {
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const { filterModel, handleFilterModelChange } = useFilterModel();

    const { currentOrganization } = useOrganizations();
    // Rows State
    const [members, setMembers] = useState<MemberRow[]>([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [memberToDelete, setMemberToDelete] =
        useState<IOrganizationAccount>();

    const autosizeOptions = {
        columns: ["first_name", "last_name", "email"],
        includeHeaders: true,
        expand: true,
    };

    // Column Definition for the Table
    const columns: GridColDef<MemberRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                sortingOrder: ["desc", "asc"],
                display: "flex",
            },
            { field: "first_name", headerName: "First Name", display: "flex" },
            { field: "last_name", headerName: "Last Name", display: "flex" },
            { field: "email", headerName: "Email", display: "flex" },
            {
                field: "actions",
                type: "actions",
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon color={"error"} />}
                        label="Delete member"
                        onClick={() => {
                            setMemberToDelete(params.row.member);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        showInMenu
                        icon={<EditIcon color={"primary"} />}
                        label="Edit member"
                        onClick={() => {
                            navigate(
                                generateRoute(
                                    appRoutes.organization.members.edit,
                                    {
                                        memberId: params.row.id,
                                    }
                                )
                            );
                        }}
                    />,
                ],
            },
        ],
        [navigate]
    );

    // Data Fetching
    const { data, isFetching } = useQuery({
        queryKey: [
            "members",
            currentOrganization,
            paginationModel.page,
            paginationModel.pageSize,
            filterModel,
        ],
        queryFn: () =>
            getOrganizationUsers(
                currentOrganization!.id,
                paginationModel.page + 1,
                paginationModel.pageSize,
                filterModel
            ),
    });

    // Update the rows when the data is fetched
    useEffect(() => {
        setMemberToDelete(undefined);
        if (data) {
            const memberData = data.items.map((member) => {
                const memberRow: MemberRow = {
                    id: member.user.id,
                    orgId: member.id,
                    first_name: member.user.first_name,
                    last_name: member.user.last_name,
                    email: member.user.email,
                    member: member,
                };
                return memberRow;
            });
            setMembers(memberData);
        }
    }, [data]);

    useEffect(() => {
        if (memberToDelete) {
            setDeleteConfirmationOpen(true);
        }
    }, [memberToDelete]);

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(data?.total || 0);

    const rowCount = useMemo(() => {
        if (data?.total !== undefined) {
            rowCountRef.current = data?.total;
        }
        return rowCountRef.current;
    }, [data?.total]);

    return (
        <Box minWidth={200} width="100%" height="100%">
            <DataTable
                loading={isFetching}
                rows={members}
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                    },
                }}
                autoSize
                autosizeOptions={autosizeOptions}
                rowCount={rowCount}
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode={"server"}
                filterMode={"server"}
                sortingMode={"server"}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleFilterModelChange}
            />
            {memberToDelete && (
                <DeleteConfirmationDialog
                    idToDelete={memberToDelete.id}
                    type={"Member"}
                    subject={memberToDelete.user.email}
                    deleteFunction={deleteOrganizationAccount}
                    queriesToInvalidate={["members"]}
                    open={deleteConfirmationOpen}
                    setOpen={setDeleteConfirmationOpen}
                />
            )}
        </Box>
    );
};
