import { Stack, Typography } from "@mui/material";
import { formatDate } from "@utils";

interface ICommentContentProps {
    /**
     * Comment text.
     *
     */
    text: string;
    /**
     * Comment Date
     */
    date: Date;
}

/**
 * Comment content component that displays the comment text and date.
 */
export const CommentContent = ({ text, date }: ICommentContentProps) => {
    return (
        <Stack component={"span"} spacing={0.7}>
            <Typography component={"span"} variant={"body2"}>
                {formatDate(date, true)}
            </Typography>
            <Typography component={"span"} color={"text.primary"}>
                {text}
            </Typography>
        </Stack>
    );
};
