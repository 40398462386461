import { deleteAnnotation, IAnnotation } from "@api";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
    MenuItem,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

interface IDeleteAnnotationProps {
    annotation: IAnnotation;
    handleOptionsClose?: () => void;
    setSelectedAnnotation: (annotation: IAnnotation | undefined) => void;
    setAnnotationEditing: React.Dispatch<
        React.SetStateAction<IAnnotation | undefined>
    >;
}

export const DeleteAnnotation = ({
    annotation,
    handleOptionsClose,
    setSelectedAnnotation,
    setAnnotationEditing,
}: IDeleteAnnotationProps) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (handleOptionsClose) {
            handleOptionsClose();
        }
        setOpen(false);
    };

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: () => deleteAnnotation(annotation.id),
        onSuccess: () => {
            enqueueSnackbar("Annotation deleted", { variant: "success" });
            setSelectedAnnotation(undefined);
            setAnnotationEditing(undefined);
            queryClient.invalidateQueries({
                queryKey: ["annotations"],
            });

            handleClose();
        },
        onError: () => {
            enqueueSnackbar(
                "An error occurred while deleting the annotation.",
                {
                    variant: "error",
                }
            );
        },
    });

    return (
        <>
            <MenuItem dense onClick={handleClickOpen}>
                <ListItemIcon>
                    <DeleteIcon color={"error"} />
                </ListItemIcon>
                Delete Annotation
            </MenuItem>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Annotation?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone. All associated data will
                        be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        cancel
                    </Button>
                    <Button
                        onClick={() => mutate()}
                        color={"error"}
                        loading={isPending}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
