import { DashboardContainer } from "@components";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useState } from "react";
import { CreateUser, UsersTable } from "./components";

const Users = () => {
    const [create, setCreate] = useState<boolean>(false);
    return (
        <DashboardContainer
            title={`Users`}
            action={
                !create && (
                    <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        size="small"
                        onClick={() => setCreate(true)}
                    >
                        add user
                    </Button>
                )
            }
        >
            {create ? (
                <CreateUser setCreate={setCreate} />
            ) : (
                <>
                    <UsersTable />
                </>
            )}
        </DashboardContainer>
    );
};

export default Users;
