import { IProfile, IProfileUpdate, updateProfile } from "@api";
import { CancelButton, useAuth } from "@components";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import { FormButton as Button, FormContainer } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { ChangeEmailForm } from "../ChangeEmailForm";

interface IFormData {
    currentEmail: string;
    email: string;
    confirmEmail: string;
}

export const ChangeEmailCard = () => {
    const { user, session } = useAuth();
    const [formReset, setFormReset] = useState<IProfile | undefined>();

    const updateEmailMutation = useMutation({
        mutationFn: (update: IProfileUpdate) => updateProfile(update),
        onSuccess: (data: IProfile) => {
            session.refresh();
            enqueueSnackbar("Email updated", { variant: "success" });
            setFormReset(data);
        },
        onError: () => {
            enqueueSnackbar("Failed to update email", { variant: "error" });
        },
    });

    const onSubmit = (data: IFormData) => {
        if (!user) {
            enqueueSnackbar("Failed to update user email", {
                variant: "error",
            });
            return;
        }

        if (data.email === user.email) {
            enqueueSnackbar("Emails are the same", { variant: "warning" });
            return;
        }

        const update: IProfileUpdate = {
            email: data.email,
        };

        updateEmailMutation.mutate(update);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        sx={{
                            backgroundColor: "transparent",
                            boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.08);",
                        }}
                    >
                        <EmailOutlinedIcon color="primary" />
                    </Avatar>
                }
                title={"Change Email"}
                titleTypographyProps={{ variant: "h6" }}
            />
            <FormContainer
                onSuccess={onSubmit}
                defaultValues={{
                    currentEmail: user?.email,
                    email: "",
                    confirmEmail: "",
                }}
            >
                <CardContent>
                    <ChangeEmailForm formReset={formReset} />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <CancelButton />
                    <Button variant="contained">Save Changes</Button>
                </CardActions>
            </FormContainer>
        </Card>
    );
};
