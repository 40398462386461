import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import { FormButton as Button, FormDatePickerElement } from "@rhf-kit/mui";
import { useFormContext } from "react-hook-form";
import { Steps } from "./AddDroneCollection";
import { LocationsAutocomplete } from "./LocationsAutocomplete";

interface IProps {
    /**
     * Callback function to close the dialog
     */
    onClose: () => void;
    /**
     * Callback function to set the current step
     */
    setStep: (step: Steps) => void;
}

export const SelectLocation = ({ onClose, setStep }: IProps) => {
    const { watch } = useFormContext();

    // Determines whether a user can continue to the next step
    // Only check for location, as the date picker will always have a value
    const canContinue = !!watch("location");

    const handleContinue = () => {
        if (canContinue) {
            setStep("upload");
        }
    };

    return (
        <>
            <DialogTitle>
                <Toolbar disableGutters sx={{ alignItems: "flex-start" }}>
                    <Typography variant={"h6"}>
                        Create Drone Collection
                    </Typography>

                    <Box flexGrow={1} />

                    <IconButton onClick={onClose} edge={"end"}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent>
                {/* Locations Autocomplete */}
                <LocationsAutocomplete />

                {/* Collection Date picker */}
                <FormDatePickerElement
                    label={"Collection Date"}
                    name={"collectionDate"}
                    required
                    inputProps={{
                        fullWidth: true,
                        margin: "normal",
                        helperText:
                            "Select the date for when the images in the drone collection were taken.",
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button type="button" color={"error"} onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    disabled={!canContinue}
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DialogActions>
        </>
    );
};
