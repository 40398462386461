export enum ImageType {
    RGB = "RGB",
    NIR = "NIR",
    DSM = "DSM",
    RED_EDGE = "Red Edge",
}

export interface IImageBase {
    type: ImageType;
    file_name: string;
}

export interface IImageUpload extends IImageBase {}

export interface IImageUpdate extends IImageBase {
    upload_id: string;
    upload_complete: boolean;
    tileset_id: string;
}

export interface IImage extends IImageBase {
    id: number;
    collection_id: number;
    created_by_id: number;
    created_at: string | Date;
    upload_complete: boolean;
    tileset_id: string;
    error?: string;
}

export interface IImageUploadStatus {
    complete: boolean;
    tileset: string;
    error: string;
    id: string;
    name: string;
    modified: string;
    created: string;
    owner: string;
    progress: number;
}
