import { getOrganizationLocations, ILocation } from "@api";
import { useOrganizations } from "@components";
import { FormAutoCompleteElement } from "@rhf-kit/mui";
import { useQuery } from "@tanstack/react-query";

export const LocationsAutocomplete = () => {
    const { currentOrganization } = useOrganizations();

    const { data, isPending } = useQuery({
        queryKey: ["locations-autocomplete", currentOrganization],
        queryFn: () => {
            if (!currentOrganization) return;
            return getOrganizationLocations(currentOrganization.id);
        },
        enabled: !!currentOrganization,
    });

    if (!currentOrganization) return null;

    return (
        <FormAutoCompleteElement
            label={"Location"}
            name={"location"}
            loading={isPending}
            options={data ? data.items : []}
            getOptionLabel={(option: ILocation) => option.name}
            textFieldProps={{
                margin: "normal",
                helperText:
                    "Select a location to create a drone collection for.",
            }}
            required
        />
    );
};
