import { project } from "@config";
import { Box, Typography, Stack } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login as APILogin } from "@api";
import { FormBox, FormScreenContainer, useAuth, Link } from "@components";
import {
    FormContainer,
    FormEmailElement,
    FormPasswordElement,
    FormButton as Button,
} from "@rhf-kit/mui";

type FormData = {
    email: string;
    password: string;
};

const Login = () => {
    const { session, authenticated } = useAuth();
    const navigate = useNavigate();
    const [loginState, loginActions] = useAsync(APILogin);
    const [status, setStatus] = useState<"not-executed" | "loading">(
        "not-executed"
    );
    const [error, setError] = useState<boolean>(false);

    const onSubmit = (data: FormData) => {
        setError(false);
        loginActions.execute(data.email, data.password);
    };

    useEffect(() => {
        if (authenticated) {
            navigate("/");
        }
    }, [authenticated, navigate]);

    useEffect(() => {
        if (loginState.status === "success" && loginState.result) {
            if (status === "not-executed") {
                setStatus("loading");
                session.create(loginState.result.access_token);
            }
        }
        if (loginState.status === "error") {
            setError(true);
        }
    }, [loginState, navigate, session, status]);

    return (
        <FormScreenContainer>
            <FormBox>
                <FormContainer
                    defaultValues={{ email: "", password: "" }}
                    onSuccess={onSubmit}
                >
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                        Welcome back!
                    </Typography>
                    <Typography>
                        Enter your credentials to access your account.
                    </Typography>
                    <FormEmailElement
                        renderIcon={false}
                        name={"email"}
                        label="Email Address"
                        margin="normal"
                        required
                        fullWidth
                    />
                    <FormPasswordElement
                        name={"password"}
                        label="Password"
                        margin="normal"
                        required
                        fullWidth
                    />
                    {error && (
                        <Typography textAlign="center" color="error" my={1}>
                            Invalid email or password.
                        </Typography>
                    )}
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent={"space-between"}
                        sx={{ paddingTop: 2 }}
                    >
                        <Stack spacing={1}>
                            <Typography mt={1}>
                                Don't have an account?{" "}
                                <Link href="/register">Sign Up</Link>
                            </Typography>
                            <Typography mt={2}>
                                <Link href="/password/forgot">
                                    Forgot Password?
                                </Link>
                            </Typography>
                        </Stack>
                        <Button
                            loading={
                                loginState.status === "loading" ||
                                status === "loading"
                            }
                            variant={"contained"}
                            loadingPosition={"end"}
                            sx={{ height: "40px" }}
                        >
                            Sign In
                        </Button>
                    </Stack>
                </FormContainer>
            </FormBox>
            <Box color="GrayText">
                <Typography mt={2}>© 2024 {project.name}</Typography>
            </Box>
        </FormScreenContainer>
    );
};

export default Login;
