import { IOrganization, getOrganizations } from "@api";
import { DataTable } from "@components";
import { appRoutes, generateRoute } from "@config";
import { useFilterModel } from "@hooks";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material/";
import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
    handleOpenDelete: (organization: IOrganization) => void;
}

export const OrganizationsTable = ({ handleOpenDelete }: IProps) => {
    const navigate = useNavigate();

    const { filterModel, handleFilterModelChange } = useFilterModel();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const autoSizeOptions = {
        columns: ["id", "name"],
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    };

    const [rows, setRows] = useState<IOrganization[]>([]);

    const columns: GridColDef<IOrganization>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "Organization ID",
                display: "flex",
            },
            {
                field: "name",
                headerName: "Organization Name",
                editable: true,
                display: "flex",
            },
            {
                field: "actions",
                type: "actions",
                getActions: ({ row }) => {
                    return [
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Edit Organization">
                                    <EditIcon color="primary" />
                                </Tooltip>
                            }
                            label="Edit"
                            showInMenu
                            onClick={() =>
                                navigate(
                                    generateRoute(
                                        appRoutes.admin.organizations.edit,
                                        { id: row.id }
                                    )
                                )
                            }
                        />,
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Delete Organization">
                                    <DeleteIcon color="error" />
                                </Tooltip>
                            }
                            label="Delete"
                            showInMenu
                            onClick={() => handleOpenDelete(row)}
                        />,
                    ];
                },
            },
        ],
        [handleOpenDelete, navigate]
    );

    const { data, isFetching } = useQuery({
        queryKey: [
            "admin-organizations",
            paginationModel.page,
            paginationModel.pageSize,
            filterModel,
        ],
        queryFn: () =>
            getOrganizations(
                paginationModel.page + 1,
                paginationModel.pageSize,
                filterModel
            ),
    });

    useEffect(() => {
        if (data) {
            setRows(data.items);
        }
    }, [data]);

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(data?.total || 0);

    const rowCount = useMemo(() => {
        if (data?.total !== undefined) {
            rowCountRef.current = data?.total;
        }
        return rowCountRef.current;
    }, [data?.total]);

    return (
        <Box minWidth={200} width="100%" height="100%">
            <DataTable
                loading={isFetching}
                autosizeOptions={autoSizeOptions}
                autoSize
                rows={rows}
                columns={columns}
                rowCount={rowCount}
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                editMode="row"
                paginationMode={"server"}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                    },
                }}
                filterMode={"server"}
                sortingMode={"server"}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleFilterModelChange}
            />
        </Box>
    );
};
