import { AuthProvider, ProtectedAdminRoute, ProtectedRoute } from "@components";
import { appRoutes } from "@config";
import {
    Account,
    DroneData,
    EditOrganization,
    ForgotPassword,
    Home,
    Layout,
    Locations,
    Login,
    Map,
    MapPage,
    Members,
    MembersEdit,
    MembersList,
    Organization,
    Organizations,
    Page404,
    PersonalAccount,
    Register,
    ResetPassword,
    Security,
    Settings,
    Users,
} from "@pages";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <SentryRoutes>
                    <Route path="*" element={<Page404 />} />
                    {/* Auth Routes */}
                    <Route path={appRoutes.login} element={<Login />} />
                    <Route path={appRoutes.register} element={<Register />} />
                    <Route
                        path={appRoutes.forgotPassword}
                        element={<ForgotPassword />}
                    />
                    <Route
                        path={appRoutes.resetPassword}
                        element={<ResetPassword />}
                    />

                    {/* Session Routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route path={appRoutes.base} element={<Layout />}>
                            {/* Organization Routes */}
                            <Route
                                path={appRoutes.organization.base}
                                element={<Organization />}
                            >
                                <Route
                                    path={appRoutes.organization.home}
                                    element={<Home />}
                                />
                                {/* Location */}
                                <Route
                                    path={appRoutes.organization.map.base}
                                    element={<MapPage />}
                                >
                                    <Route
                                        path={
                                            appRoutes.organization.map.location
                                                .base
                                        }
                                        element={<Map />}
                                    />
                                </Route>
                                <Route
                                    path={appRoutes.organization.locations.base}
                                    element={<Locations />}
                                />
                                <Route
                                    path={appRoutes.organization.droneData.base}
                                    element={<DroneData />}
                                />
                                <Route
                                    path={appRoutes.organization.members.base}
                                    element={<Members />}
                                >
                                    <Route
                                        path={
                                            appRoutes.organization.members.base
                                        }
                                        element={<MembersList />}
                                    />
                                    <Route
                                        path={
                                            appRoutes.organization.members.edit
                                        }
                                        element={<MembersEdit />}
                                    />
                                </Route>
                                <Route
                                    path={appRoutes.organization.settings}
                                    element={<Settings />}
                                />
                            </Route>

                            {/* Admin Routes */}
                            <Route
                                path={appRoutes.admin.base}
                                element={<ProtectedAdminRoute />}
                            >
                                <Route
                                    path={appRoutes.admin.organizations.base}
                                    element={<Organizations />}
                                />
                                <Route
                                    path={appRoutes.admin.organizations.edit}
                                    element={<EditOrganization />}
                                />
                                <Route
                                    path={appRoutes.admin.users}
                                    element={<Users />}
                                />
                            </Route>

                            {/* Account Routes */}
                            <Route
                                path={appRoutes.account.base}
                                element={<Account />}
                            >
                                <Route
                                    path={appRoutes.account.base}
                                    element={<PersonalAccount />}
                                />
                                <Route
                                    path={appRoutes.account.security}
                                    element={<Security />}
                                />
                            </Route>
                        </Route>
                    </Route>
                </SentryRoutes>
            </AuthProvider>
        </Router>
    );
};

export default App;
