import { IProfile } from "@api";
import { Stack } from "@mui/material";
import { FormTextFieldElement } from "@rhf-kit/mui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface IProps {
    formReset?: IProfile;
}

export const ChangeNameForm = ({ formReset }: IProps) => {
    const { reset } = useFormContext();

    useEffect(() => {
        if (formReset) {
            reset({
                firstName: formReset.first_name,
                lastName: formReset.last_name,
            });
        }
    }, [formReset, reset]);

    return (
        <Stack>
            <FormTextFieldElement
                name="firstName"
                label="First Name"
                margin={"normal"}
            />
            <FormTextFieldElement
                name="lastName"
                label="Last Name"
                margin={"normal"}
            />
        </Stack>
    );
};
