import { DashboardContainer } from "@components";
import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AccountNav } from "./components";

function Account() {
    return (
        <DashboardContainer>
            <Stack
                direction={{ md: "row", xs: "column" }}
                gap={4}
                maxWidth={"lg"}
                width={"100%"}
                marginX={"auto"}
            >
                <AccountNav />
                <Outlet />
            </Stack>
        </DashboardContainer>
    );
}

export default Account;
