import { deleteCollection } from "@api";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { CollectionRow } from "./DroneCollectionsList";

interface IDeleteSelectedProps {
    /**
     * The selected collection rows to delete
     */
    selected: CollectionRow[];
}

/**
 * DeleteSelected component to delete selected collections
 */
export const DeleteSelected = ({ selected }: IDeleteSelectedProps) => {
    const [open, setOpen] = React.useState(false);

    // Open the dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Delete the selected collections
    const deleteSelected = async () => {
        for (const collection of selected) {
            await deleteCollection(collection.id);
        }
    };

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: () => deleteSelected(),
        onSuccess: () => {
            enqueueSnackbar("Collection(s) deleted", { variant: "success" });
            queryClient.invalidateQueries({
                queryKey: ["collections"],
            });
            queryClient.invalidateQueries({
                queryKey: [
                    "location-collections",
                    selected[0].collection.location_id,
                ],
            });

            handleClose();
        },
        onError: () => {
            enqueueSnackbar(
                "An error occurred while deleting the collection(s).",
                {
                    variant: "error",
                }
            );
        },
    });

    return (
        <>
            <Button
                color={"error"}
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}
            >
                Delete Selected
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Delete {selected.length > 1 ? "collections" : "collection"}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deleting the selected collections(s) will remove them
                        from the location. The collection(s) and all associated
                        data will be permanently deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        cancel
                    </Button>
                    <Button
                        onClick={() => mutate()}
                        color={"error"}
                        loading={isPending}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
