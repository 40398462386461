import axios from "axios";
import { API_URL } from "@config";
import {
    deleteAttachments,
    IAnnotation,
    IAnnotationUpdate,
    IComment,
    ICommentCreate,
    IFileAttachment,
    Page,
    uploadCommentAttachments,
} from "@api";

const API = `${API_URL}/annotations`;

export const getAnnotationComments = async (
    annotationId: number,
    page: number = 1,
    size: number = 50
) => {
    const result = await axios.get<Page<IComment>>(
        `${API}/${annotationId}/comments?page=${page}&size=${size}`
    );
    return result.data;
};

export const createAnnotationComment = async (
    comment: ICommentCreate,
    attachments: IFileAttachment[],
    annotationId: number
) => {
    const url = `${API}/${annotationId}/comments`;

    // If there are attachments create comment, then upload attachments with ref to comment
    if (attachments.length > 0) {
        const result = await axios.post<IComment>(url, comment);
        const commentObj = result.data;

        return await uploadCommentAttachments(commentObj.id, attachments);
    } else {
        return await axios.post(url, comment);
    }
};

export const deleteAnnotation = async (annotationId: number) => {
    return await axios.delete(`${API}/${annotationId}`);
};

export const updateAnnotation = async (
    annotationId: number,
    annotation: IAnnotationUpdate,
    attachmentsToDelete?: number[],
    attachments?: IFileAttachment[]
) => {
    // delete attachments if there are attachments to delete
    if (attachmentsToDelete && attachmentsToDelete.length > 0) {
        await deleteAttachments(attachmentsToDelete);
    }

    if (attachments && attachments.length > 0) {
        // Find attachments that have an id of -1, these are new attachments
        const newAttachments = attachments.filter(
            (attachments) => attachments.id === -1
        );

        // If there are new attachments, upload them
        if (newAttachments.length > 0) {
            await uploadAnnotationAttachments(annotationId, newAttachments);
        }
    }

    // update the annotation
    return await axios.put<IAnnotation>(`${API}/${annotationId}`, annotation);
};

export const uploadAnnotationAttachments = async (
    annotationId: number,
    attachments: IFileAttachment[]
) => {
    const formData = new FormData();

    // Append each attachment to the form data
    attachments.forEach((attachment) => {
        formData.append("attachments", attachment.file);
    });

    const result = await axios.post(
        `${API}/${annotationId}/attachments`,
        formData
    );

    return result.data;
};
