import { MembersTable } from "./components";

/**
 * MembersList Page that renders the MembersTable component.
 */
const MembersList = () => {
    return <MembersTable />;
};

export default MembersList;
