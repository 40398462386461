import { IOrganization } from "@api";
import { FormTextFieldElement } from "@rhf-kit/mui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface IProps {
    formReset?: IOrganization;
}

export const EditOrganizationForm = ({ formReset }: IProps) => {
    const { reset } = useFormContext();

    useEffect(() => {
        if (!formReset) return;

        // reset default value with updated organization data
        reset({ name: formReset.name });
    }, [formReset, reset]);

    return (
        <FormTextFieldElement
            name={"name"}
            label={"Organization Name"}
            fullWidth
            autoFocus
        />
    );
};
