import { RefObject } from "react";
import { MapRef } from "react-map-gl";
import { Control } from "@components";
import { ButtonGroup, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface INavigationControlProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
}

/**
 * Navigation control for the map. Implements zoom in and zoom out
 */
export const NavigationControl = ({ mapRef }: INavigationControlProps) => {
    // zoom in
    const handleZoomIn = () => {
        if (mapRef.current) {
            mapRef.current.zoomIn();
        }
    };

    // zoom out
    const handleZoomOut = () => {
        if (mapRef.current) {
            mapRef.current.zoomOut();
        }
    };

    return (
        <ButtonGroup
            orientation={"vertical"}
            sx={{
                "& .MuiButtonGroup-grouped": {
                    minWidth: 0,
                },
            }}
        >
            {/* Zoom in Button */}
            <Tooltip
                title={"Zoom in"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control onClick={handleZoomIn}>
                    <AddIcon />
                </Control>
            </Tooltip>

            {/* Zoom out Button */}
            <Tooltip
                title={"Zoom out"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control onClick={handleZoomOut}>
                    <RemoveIcon />
                </Control>
            </Tooltip>
        </ButtonGroup>
    );
};
