import { IOrganization } from "@api";
import { DashboardContainer } from "@components";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useState } from "react";
import {
    CreateOrganization,
    DeleteOrganizationModal,
    OrganizationsTable,
} from "./components";

const Organizations = () => {
    const [create, setCreate] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [organization, setOrganization] = useState<
        IOrganization | undefined
    >();

    const handleOpenDeleteModal = (organization: IOrganization) => {
        setOrganization(organization);
        setDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setOrganization(undefined);
    };

    return (
        <DashboardContainer
            title={`Organization Management`}
            action={
                !create && (
                    <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        size="small"
                        onClick={() => setCreate(true)}
                    >
                        add organization
                    </Button>
                )
            }
        >
            {create ? (
                <CreateOrganization onPressBack={() => setCreate(false)} />
            ) : (
                <>
                    <OrganizationsTable
                        handleOpenDelete={handleOpenDeleteModal}
                    />

                    <DeleteOrganizationModal
                        open={deleteModal}
                        organization={organization}
                        handleClose={handleCloseDeleteModal}
                    />
                </>
            )}
        </DashboardContainer>
    );
};

export default Organizations;
