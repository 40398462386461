import { Typography, useMediaQuery, useTheme } from "@mui/material";

interface IPageHeaderProps {
    /**
     * Title of the page.
     *
     */
    title: string;
}

/**
 * Page header that is displayed inside of dashboard container usually.
 */
export const PageHeader = ({ title }: IPageHeaderProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Typography
            variant={isMobile ? "h5" : "h4"}
            fontWeight={600}
            color={"#2B3674"}
            gutterBottom
        >
            {title}
        </Typography>
    );
};
