import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormContext } from "react-hook-form";
import { PhotoView } from "react-photo-view";
import { IAttachment, IFileAttachment } from "@api";
import { BUNNY_PULL_ZONE } from "@config";

interface IProps {
    /**
     * Attachment data.
     */
    attachment?: IFileAttachment;
    /**
     * Comment attachment data.
     */
    commentAttachment?: IAttachment;
    /**
     * Should the attachment be editable (removable).
     * @default false
     */
    editable?: boolean;
}

export const Attachment = ({
    attachment,
    commentAttachment,
    editable = false,
}: IProps) => {
    const { setValue, getValues } = useFormContext() || {};

    // build imageUrl with thumbnail image processing based on the attachment type
    const imageUrl =
        attachment && attachment.attachment
            ? `${BUNNY_PULL_ZONE}/${attachment.attachment.file_path}?aspect_ratio=1:1`
            : attachment && !attachment.attachment
              ? URL.createObjectURL(attachment.file)
              : `${BUNNY_PULL_ZONE}/${commentAttachment?.file_path}?aspect_ratio=1:1`;

    const setAttachmentsToDelete = () => {
        // if attachment is not new, add it to attachmentsToDelete list
        if (attachment && attachment.id !== -1) {
            const attachmentsToDelete: number[] = getValues(
                "attachmentsToDelete"
            );

            // add attachment id to attachmentsToDelete list
            setValue("attachmentsToDelete", [
                ...attachmentsToDelete,
                attachment.id,
            ]);
        }
    };

    const removeAttachment = () => {
        // remove attachment from attachments list
        if (attachment && editable) {
            const attachments = getValues("attachments");
            const updatedAttachments = attachments.filter(
                (file: IFileAttachment) => file.file !== attachment?.file
            );
            setValue("attachments", updatedAttachments);

            setAttachmentsToDelete();
        }
    };

    if (attachment && commentAttachment)
        throw new Error(
            "Cannot have both attachment and commentAttachment defined"
        );

    return (
        <Box
            width={70}
            height={70}
            bgcolor={(theme) => theme.palette.grey[100]}
            borderRadius={1}
            position={"relative"}
        >
            {editable && (
                <Box
                    width={16}
                    height={16}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    position={"absolute"}
                    bgcolor={"primary.main"}
                    borderRadius={"50%"}
                    sx={{ outline: "2px solid #ffffff" }}
                    top={-4}
                    right={-4}
                >
                    <IconButton disableRipple onClick={removeAttachment}>
                        <CloseIcon sx={{ color: "white", fontSize: "12px" }} />
                    </IconButton>
                </Box>
            )}

            <PhotoView src={imageUrl.split("?")[0]}>
                <img
                    src={imageUrl}
                    alt={"attachment"}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "4px",
                    }}
                />
            </PhotoView>
        </Box>
    );
};
