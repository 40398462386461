import {
    createLocation,
    ILocation,
    ILocationCreate,
    ILocationUpdate,
    updateLocation,
} from "@api";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import {
    Box,
    Dialog,
    ListItemIcon,
    MenuItem,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FormButton as Button, FormContainer } from "@rhf-kit/mui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { LocationDetails } from "./LocationDetails.tsx";
import { LocationSelect } from "./LocationSelect.tsx";

interface ILocationForm {
    name: string;
    description: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    zoom: number;
}

interface ILocationEditorProps {
    /**
     * Handle setting the location
     */
    setLocation?: (location: ILocation) => void;
    /**
     * Handle setting the editing location
     */
    setEditingLocation?: (location: ILocation | undefined) => void;
    /**
     * Current selected location
     */
    location?: ILocation;
    /**
     * Organization ID
     */
    organizationId?: number;
    /**
     * Handle closing the parent menu
     */
    handleOptionsClose?: () => void;
    /**
     * If the editor is used in a table view
     */
    tableView?: boolean;
    /**
     * Show the button to open the editor
     */
    showButton?: boolean;
    /**
     * If the editor is rendered in a table view
     */
    renderedInTable?: boolean;
}

/**
 * Location editor that allows for creating and editing locations
 */
export const LocationEditor = ({
    location,
    setLocation,
    setEditingLocation,
    organizationId,
    handleOptionsClose,
    tableView = false,
    showButton = true,
    renderedInTable = false,
}: ILocationEditorProps) => {
    const [open, setOpen] = useState(false);
    const [formProgress, setFormProgress] = useState<string>("initial");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
    const queryClient = useQueryClient();

    useEffect(() => {
        if (location && renderedInTable) {
            handleClickOpen();
        }
    }, [location, renderedInTable]);

    // Create location mutation
    const { mutate: createLocationMutate, isPending: createIsPending } =
        useMutation({
            mutationFn: ({
                organizationId,
                newLocation,
            }: {
                organizationId: number;
                newLocation: ILocationCreate;
            }) => createLocation(organizationId, newLocation),
            onSuccess: (location) => {
                enqueueSnackbar("Location created successfully", {
                    variant: "success",
                });

                queryClient.invalidateQueries({
                    queryKey: ["organizationLocations", organizationId],
                });

                queryClient
                    .invalidateQueries({
                        queryKey: ["locationsList"],
                    })
                    .then(() => {
                        if (setLocation) setLocation(location);
                    });

                handleClose();
            },
        });

    // Edit location mutation
    const { mutate: editLocationMutate, isPending: editIsPending } =
        useMutation({
            mutationFn: ({
                locationId,
                locationUpdate,
            }: {
                locationId: number;
                locationUpdate: ILocationUpdate;
            }) => updateLocation(locationId, locationUpdate),
            onSuccess: (location) => {
                queryClient.invalidateQueries({
                    queryKey: ["organizationLocations", organizationId],
                });
                queryClient.invalidateQueries({
                    queryKey: ["locationsList"],
                });

                if (setLocation) setLocation(location);

                enqueueSnackbar("Location updated successfully", {
                    variant: "success",
                });
                handleClose();
            },
        });

    const handleClickOpen = () => {
        setOpen(true);
    };

    // handle dialog close
    const handleClose = () => {
        if (handleOptionsClose) {
            handleOptionsClose();
        }
        setOpen(false);
    };

    // handle form submission
    const handleSubmit = (values: ILocationForm) => {
        if (!location) {
            if (!organizationId) return;

            const newLocation: ILocationCreate = {
                name: values.name,
                description: values.description,
                center_coordinates: [
                    values.coordinates.latitude,
                    values.coordinates.longitude,
                ],
                zoom: values.zoom,
            };
            createLocationMutate({ organizationId, newLocation });
        } else {
            const locationUpdate: ILocationUpdate = {
                name: values.name,
                description: values.description,
                center_coordinates: [
                    values.coordinates.latitude,
                    values.coordinates.longitude,
                ],
                zoom: values.zoom,
            };
            editLocationMutate({ locationId: location.id, locationUpdate });
        }
    };

    // handle form progress
    const handleFormProgress = (progress: string) => {
        setFormProgress(progress);
    };

    // sets the form progress to initial when the dialog is closed
    const handleDialogClosed = () => {
        setFormProgress("initial");
        if (setEditingLocation) {
            setEditingLocation(undefined);
        }
    };

    // initial form values
    const initialValues: ILocationForm = {
        name: location?.name || "",
        description: location?.description || "",
        coordinates: {
            latitude: location?.center_coordinates[0] || 0,
            longitude: location?.center_coordinates[1] || 0,
        },
        zoom: location?.zoom || 10,
    };

    const renderButton = () => {
        if (!showButton) return null;

        if (tableView) {
            return (
                <Button
                    variant={"contained"}
                    startIcon={<AddLocationIcon />}
                    onClick={handleClickOpen}
                    type={"button"}
                    size={"small"}
                >
                    Add Location
                </Button>
            );
        } else {
            if (!location && !isMobile) {
                return (
                    <Button
                        variant={"text"}
                        startIcon={<AddLocationIcon />}
                        onClick={handleClickOpen}
                        type={"button"}
                    >
                        Add Location
                    </Button>
                );
            }

            if (!location && isMobile) {
                return (
                    <MenuItem dense onClick={handleClickOpen}>
                        <ListItemIcon>
                            <AddLocationIcon color={"primary"} />
                        </ListItemIcon>
                        Add Location
                    </MenuItem>
                );
            }

            if (location) {
                return (
                    <MenuItem dense onClick={handleClickOpen}>
                        <ListItemIcon>
                            <EditLocationIcon color={"primary"} />
                        </ListItemIcon>
                        Edit Location
                    </MenuItem>
                );
            }
        }
    };

    return (
        <>
            {renderButton()}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={formProgress === "initial" ? "xl" : "sm"}
                onTransitionExited={handleDialogClosed}
            >
                <FormContainer
                    defaultValues={initialValues}
                    onSuccess={handleSubmit}
                >
                    {/* Map view initial form progress */}
                    <Box
                        display={formProgress !== "initial" ? "none" : "block"}
                    >
                        <LocationSelect
                            handleClose={handleClose}
                            location={location}
                            handleFormProgress={handleFormProgress}
                        />
                    </Box>
                    {/* Location Details form progress */}
                    <Box
                        display={formProgress !== "details" ? "none" : "block"}
                    >
                        <LocationDetails
                            handleClose={handleClose}
                            location={location}
                            handleFormProgress={handleFormProgress}
                            loading={createIsPending || editIsPending}
                        />
                    </Box>
                </FormContainer>
            </Dialog>
        </>
    );
};
