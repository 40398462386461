import {
    createUserWithOrganizations,
    getOrganizations,
    IUserCreateWithOrganizations,
} from "@api";
import { ArrowBack } from "@mui/icons-material";
import {
    Card,
    CardContent,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import {
    FormAutoCompleteElement,
    FormButton,
    FormCheckboxElement,
    FormContainer,
    FormTextFieldElement,
} from "@rhf-kit/mui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type CreateUserProps = {
    setCreate: (create: boolean) => void;
};

export const CreateUser = ({ setCreate }: CreateUserProps) => {
    const queryClient = useQueryClient();
    const { mutate: createUser, isPending } = useMutation({
        mutationFn: createUserWithOrganizations,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            setCreate(false);
        },
    });

    const { data: organizationOptions, isPending: loadingOrganizationOptions } =
        useQuery({
            queryKey: ["organization-options"],
            queryFn: () => getOrganizations(),
        });

    const handleSubmit = (data: IUserCreateWithOrganizations) => {
        createUser(data);
    };

    return (
        <Card
            elevation={1}
            sx={{
                width: "100%",
                p: 4,
                maxWidth: "lg",
            }}
        >
            <CardContent>
                <Stack alignItems="center" direction="row" sx={{ mb: 2 }}>
                    <IconButton onClick={() => setCreate(false)}>
                        <ArrowBack />
                    </IconButton>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        flexGrow={1}
                        textAlign="center"
                    >
                        Create New User
                    </Typography>
                </Stack>
                <FormContainer
                    defaultValues={{
                        first_name: "",
                        last_name: "",
                        email: "",
                        organizations: [],
                        is_admin: false,
                    }}
                    onSuccess={handleSubmit}
                >
                    <Stack spacing={3}>
                        <FormTextFieldElement
                            fullWidth
                            name="first_name"
                            label="First Name"
                            required
                        />
                        <FormTextFieldElement
                            fullWidth
                            name="last_name"
                            label="Last Name"
                            required
                        />
                        <FormTextFieldElement
                            fullWidth
                            name="email"
                            label="Email"
                            required
                        />
                        <FormAutoCompleteElement
                            multiple
                            name="organizations"
                            label="Organizations"
                            loading={loadingOrganizationOptions}
                            getOptionLabel={(option) => option?.name ?? option}
                            options={organizationOptions?.items ?? []}
                        />
                        <FormCheckboxElement name="is_admin" label="Is Admin" />{" "}
                        <FormButton
                            variant="contained"
                            color="primary"
                            loading={isPending}
                            loadingPosition={"end"}
                        >
                            Create User
                        </FormButton>
                    </Stack>
                </FormContainer>
            </CardContent>
        </Card>
    );
};
