import { IAnnotation } from "@api";
import { useAuth } from "@components";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { formatDate } from "@utils";
import { AnnotationOptions } from "./AnnotationOptions.tsx";

interface IAnnotationHeaderProps {
    /**
     * The annotation
     */
    annotation: IAnnotation;
    /**
     * Set the selected annotation
     */
    setSelectedAnnotation?: (annotation: IAnnotation | undefined) => void;
    /**
     * Set if the annotation is currently being edited
     */
    setIsEditingAnnotation: (isEditing: boolean) => void;
    /**
     * If the annotation is currently selected
     */
    isAnnotationSelected: boolean;
    /**
     * Set the annotation that is currently being edited
     */
    setAnnotationEditing?: React.Dispatch<
        React.SetStateAction<IAnnotation | undefined>
    >;
}

/**
 * Annotation header component that displays the user name, date, and options of the annotation
 */
export const AnnotationHeader = ({
    annotation,
    setSelectedAnnotation,
    setIsEditingAnnotation,
    isAnnotationSelected,
    setAnnotationEditing,
}: IAnnotationHeaderProps) => {
    const { user } = useAuth();

    if (!user) return null;

    // determine the user name
    const userName = `${annotation.created_by ? annotation.created_by.first_name : user.first_name} ${annotation.created_by ? annotation.created_by.last_name : user.last_name}`;

    return (
        <>
            <Box display={"flex"} flexDirection={"row"}>
                {/* Name */}
                <Typography>{userName}</Typography>
                <Box flexGrow={1} />

                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    {/* Options */}
                    {setSelectedAnnotation && setAnnotationEditing && (
                        <AnnotationOptions
                            annotation={annotation}
                            setSelectedAnnotation={setSelectedAnnotation}
                            setIsEditingAnnotation={setIsEditingAnnotation}
                            setAnnotationEditing={setAnnotationEditing}
                        />
                    )}

                    {/* Shows that the annotation is selected */}
                    {isAnnotationSelected && (
                        <Chip
                            label={"selected"}
                            color={"primary"}
                            size={"small"}
                            onDelete={() =>
                                setSelectedAnnotation &&
                                setSelectedAnnotation(undefined)
                            }
                        />
                    )}
                </Stack>
            </Box>

            {/* Date */}
            <Typography variant={"body2"} color={"text.secondary"} gutterBottom>
                {formatDate(annotation.created_at ?? new Date(), true)}
            </Typography>
        </>
    );
};
