import { IOrganization, getOrganizations, getUserOrganizations } from "@api";
import { appRoutes } from "@config";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ReactNode, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

interface OrganizationsContextType {
    currentOrganization?: IOrganization;
    organizations: IOrganization[];
    setCurrentOrganization: (
        organization: IOrganization,
        shouldNavigate?: boolean
    ) => void;
    isLoading: boolean;
}

export const OrganizationsContext = createContext<OrganizationsContextType>({
    organizations: [],
    setCurrentOrganization: () => {},
    isLoading: false,
});

export const OrganizationsProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { user } = useAuth();
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [currentOrganization, setCurrentOrganizationState] = useState<
        IOrganization | undefined
    >(() => {
        const savedOrg = localStorage.getItem("currentOrganization");
        return savedOrg ? JSON.parse(savedOrg) : undefined;
    });

    // this state is used to trigger navigation after setting the current organization
    const navigate = useNavigate();

    const organizationsQuery = useSuspenseQuery({
        queryKey: ["user-organizations", user?.is_admin],
        queryFn: () =>
            user?.is_admin ? getOrganizations() : getUserOrganizations(), // get all organizations if the user is an admin, otherwise get the user's organizations
    });

    // sets current organization in local storage and state that way it persists through refreshes
    const setCurrentOrganization = (
        organization: IOrganization,
        shouldNavigate = false
    ) => {
        if (!organization) return;
        setCurrentOrganizationState(organization);
        localStorage.setItem(
            "currentOrganization",
            JSON.stringify(organization)
        );
        setShouldNavigate(shouldNavigate);
    };

    // set the first organization as the current organization if there is no current organization
    useEffect(() => {
        if (
            organizationsQuery.isSuccess &&
            organizationsQuery.data &&
            !currentOrganization
        ) {
            setCurrentOrganization(organizationsQuery.data.items[0], true);
        }
    }, [
        currentOrganization,
        organizationsQuery.data,
        organizationsQuery.isSuccess,
    ]);

    // navigate to the locations page after setting the current organization
    useEffect(() => {
        if (currentOrganization && shouldNavigate) {
            navigate(appRoutes.organization.map.base);
            setShouldNavigate(false);
        }
    }, [currentOrganization, navigate, shouldNavigate]);

    return (
        <OrganizationsContext.Provider
            value={{
                currentOrganization,
                organizations: organizationsQuery.data.items || [],
                setCurrentOrganization,
                isLoading: organizationsQuery.isLoading,
            }}
        >
            {children}
        </OrganizationsContext.Provider>
    );
};
