import { Layer, Source } from "react-map-gl";
import { Tile } from "./LocationMap.tsx";

interface ITileSourceProps {
    /**
     * The tile to display on the map
     */
    tile?: Tile;
}

/**
 * TileSource component that displays the tile on the map
 */
export const TileSource = ({ tile }: ITileSourceProps) => {
    if (!tile) return null;

    return (
        <Source
            key={tile.image.id}
            id={"raster-tile-source"}
            type={"raster"}
            tiles={[tile.tileRef]}
        >
            <Layer
                key={`raster-tile-layer-${tile.image.id}`}
                id={"raster-tile-layer"}
                type={"raster"}
                beforeId={"country-label"}
            />
        </Source>
    );
};
