import { Box, Typography } from "@mui/material";
import { FormContainer, FormButton as Button } from "@rhf-kit/mui";
import { useEffect, useRef, useState } from "react";
import {
    getAttachments,
    IAnnotation,
    IAnnotationUpdate,
    IAttachment,
    IFileAttachment,
    IGeometryCreate,
    updateAnnotation,
} from "@api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Attachments } from "./comments/Attachments.tsx";
import { EditAnnotationForm } from "./EditAnnotationForm.tsx";

interface IFormData {
    title: string;
    attachments: IFileAttachment[];
    attachmentsToDelete?: number[];
}

interface IAnnotationTitleProps {
    /**
     * The annotation
     */
    annotation: IAnnotation;
    /**
     * if the annotation is being edited
     */
    isEditingAnnotation: boolean;
    /**
     * Set if the annotation is being edited
     */
    setIsEditingAnnotation: (isEditing: boolean) => void;
    /**
     * Set the annotation that is currently being edited
     */
    setAnnotationEditing?: (annotation: IAnnotation | undefined) => void;
    /**
     * The new geometry that is being created
     */
    newGeometry?: IGeometryCreate;
    /**
     * Set the new geometry that is being created
     */
    setNewGeometry?: (geometry: IGeometryCreate | undefined) => void;
}

/**
 * Annotation title component that displays the title of the annotation
 */
export const AnnotationTitle = ({
    annotation,
    setIsEditingAnnotation,
    isEditingAnnotation,
    setAnnotationEditing,
    newGeometry,
    setNewGeometry,
}: IAnnotationTitleProps) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [defaultValues, setDefaultValues] = useState<IFormData>({
        title: annotation.title,
        attachments: [],
        attachmentsToDelete: [],
    });
    const textRef = useRef<HTMLParagraphElement>(null);
    const queryClient = useQueryClient();

    // Load attachment files asynchronously
    useEffect(() => {
        const fetchAttachmentFiles = async () => {
            const files = await getAttachments(annotation.attachments);
            setDefaultValues({
                title: annotation.title,
                attachments: files,
                attachmentsToDelete: [],
            });
        };

        fetchAttachmentFiles();
    }, [annotation.attachments, annotation.title]);

    // Mutation to update the annotation
    const { mutate, isPending, variables } = useMutation({
        mutationFn: ({
            annotationUpdate,
            attachmentsToDelete,
            attachments,
        }: {
            annotationUpdate: IAnnotationUpdate;
            attachmentsToDelete?: number[];
            attachments?: IFileAttachment[];
        }) =>
            updateAnnotation(
                annotation.id,
                annotationUpdate,
                attachmentsToDelete,
                attachments
            ),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["annotations"],
            });
            setShowMore(true);
        },
    });

    // handle updating the annotation
    const handleSave = (formData: IFormData) => {
        const annotationUpdate: IAnnotationUpdate = {
            geometry: newGeometry ?? annotation.geometry,
            title: formData.title,
        };

        setIsEditingAnnotation(false);
        if (setAnnotationEditing) {
            setAnnotationEditing(undefined);
        }

        if (setNewGeometry) {
            setNewGeometry(undefined);
        }

        mutate({
            annotationUpdate,
            attachmentsToDelete: formData.attachmentsToDelete,
            attachments: formData.attachments,
        });
    };

    // check if the text is overflowing
    useEffect(() => {
        const textElement = textRef.current;
        if (textElement) {
            setIsOverflowing(
                textElement.scrollHeight > textElement.clientHeight
            );
        }
    }, [annotation.title]);

    return (
        <>
            {/* Title */}
            {!isEditingAnnotation && (
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography
                        variant={"body1"}
                        ref={textRef}
                        sx={
                            !showMore
                                ? {
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "3",
                                      WebkitBoxOrient: "vertical",
                                  }
                                : {}
                        }
                    >
                        {isPending
                            ? variables?.annotationUpdate.title
                            : annotation.title}
                    </Typography>
                    {/* Attachment Thumbnails */}
                    <Attachments
                        attachments={
                            annotation.id !== -1 && !isPending
                                ? (annotation.attachments as IAttachment[])
                                : undefined
                        }
                        fileAttachments={
                            annotation.id === -1 && !isPending
                                ? (annotation.attachments as IFileAttachment[])
                                : isPending
                                  ? variables?.attachments
                                  : undefined
                        }
                    />
                </Box>
            )}

            {/* Edit form */}
            {isEditingAnnotation && (
                <FormContainer
                    defaultValues={defaultValues}
                    onSuccess={handleSave}
                >
                    <EditAnnotationForm
                        setIsEditingAnnotation={setIsEditingAnnotation}
                        setAnnotationEditing={setAnnotationEditing}
                        loading={isPending}
                    />
                </FormContainer>
            )}

            {/* Collapse button */}
            {isOverflowing && !isEditingAnnotation && (
                <Box>
                    <Button
                        type={"button"}
                        size={"small"}
                        sx={{ textTransform: "initial" }}
                        onClick={() => setShowMore((prev) => !prev)}
                    >
                        {showMore ? "Show less" : "Show more"}
                    </Button>
                </Box>
            )}
        </>
    );
};
