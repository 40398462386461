import { FormButton } from "@rhf-kit/mui";
import { useFormContext } from "react-hook-form";

export const CancelButton = () => {
    const {
        reset,
        formState: { isDirty },
    } = useFormContext();

    return (
        <FormButton
            type="button"
            variant="text"
            onClick={() => reset()}
            disabled={!isDirty}
        >
            Cancel
        </FormButton>
    );
};
