import { IProfile, IProfileUpdate, updateProfile } from "@api";
import { CancelButton, useAuth } from "@components";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import { FormButton as Button, FormContainer } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { ChangeNameForm } from "../ChangeNameForm";

interface IFormData {
    firstName: string;
    lastName: string;
}

export const BasicDetailsCard = () => {
    const { user, session } = useAuth();
    const [formReset, setFormReset] = useState<IProfile | undefined>();

    const updateNameMutation = useMutation({
        mutationFn: (update: IProfileUpdate) => updateProfile(update),
        onSuccess: (data: IProfile) => {
            session.refresh();
            enqueueSnackbar("Details updated", { variant: "success" });
            setFormReset(data);
        },
        onError: () => {
            enqueueSnackbar("Failed to update details", { variant: "error" });
        },
    });

    const onSubmit = (data: IFormData) => {
        if (!user) {
            enqueueSnackbar("Failed to update user details", {
                variant: "error",
            });
            return;
        }

        if (
            data.firstName === user.first_name &&
            data.lastName === user.last_name
        ) {
            return;
        }

        const update: IProfileUpdate = {
            first_name: data.firstName,
            last_name: data.lastName,
        };

        updateNameMutation.mutate(update);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        sx={{
                            backgroundColor: "transparent",
                            boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.08);",
                        }}
                    >
                        <PersonOutlineOutlinedIcon color="primary" />
                    </Avatar>
                }
                title={"Basic Details"}
                titleTypographyProps={{ variant: "h6" }}
            />
            <FormContainer
                onSuccess={onSubmit}
                defaultValues={{
                    firstName: user?.first_name || "",
                    lastName: user?.last_name || "",
                }}
            >
                <CardContent>
                    <ChangeNameForm formReset={formReset} />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <CancelButton />
                    <Button
                        variant="contained"
                        loadingPosition="end"
                        loading={updateNameMutation.isPending}
                    >
                        Save Changes
                    </Button>
                </CardActions>
            </FormContainer>
        </Card>
    );
};
