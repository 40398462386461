import StraightenIcon from "@mui/icons-material/Straighten";
import { Control } from "@components";
import { RefObject, useEffect, useRef } from "react";
import RulerControl from "@mapbox-controls/ruler";
import { MapRef } from "react-map-gl";
import { theme } from "@config";
import { Tooltip } from "@mui/material";

interface IMeasureControlProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
}

/**
 * Measure control for the map. implements the RulerControl
 */
export const MeasureControl = ({ mapRef }: IMeasureControlProps) => {
    const measureRef = useRef<RulerControl | null>(null);

    useEffect(() => {
        // Wait for the map to be initialized
        if (!mapRef.current) return;

        const measure = new RulerControl({
            units: "miles",
            labelFormat: (n) => `${n.toFixed(2)} mi`,
            invisible: true,
            linePaint: {
                "line-color": theme.palette.primary.main,
            },
        });

        measureRef.current = measure;
        mapRef.current.addControl(measure);

        return () => {
            if (mapRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                mapRef.current.removeControl(measure);
            }
        };
    }, [mapRef]);

    // handles activation and deactivation of the measure control
    const handleMeasure = () => {
        if (measureRef.current) {
            if (measureRef.current.isActive) {
                measureRef.current.deactivate();
            } else {
                measureRef.current.activate();
            }
        }
    };

    return (
        <Tooltip
            title={"Measure tool"}
            PopperProps={{
                disablePortal: true,
            }}
            placement="left"
        >
            <Control onClick={handleMeasure}>
                <StraightenIcon />
            </Control>
        </Tooltip>
    );
};
