import { deleteLocation, ILocation } from "@api";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
    MenuItem,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";

interface IDeleteLocationProps {
    location?: ILocation;
    renderedInTable?: boolean;
    setLocation?: (location: ILocation | undefined) => void;
    handleOptionsClose?: () => void;
    showButton?: boolean;
}

export const DeleteLocation = ({
    location,
    handleOptionsClose,
    setLocation,
    showButton = true,
    renderedInTable = false,
}: IDeleteLocationProps) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (handleOptionsClose) {
            handleOptionsClose();
        }
        setOpen(false);
    };

    const handleTransitionExited = () => {
        if (setLocation) {
            setLocation(undefined);
        }
    };

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: () => deleteLocation(location?.id || 0),
        onSuccess: () => {
            enqueueSnackbar("Location deleted", { variant: "success" });
            queryClient.invalidateQueries({
                queryKey: ["organizationLocations"],
            });
            queryClient.invalidateQueries({
                queryKey: ["locationsList"],
            });

            handleClose();
        },
        onError: () => {
            enqueueSnackbar("An error occurred while deleting the location.", {
                variant: "error",
            });
        },
    });

    useEffect(() => {
        if (location && renderedInTable) {
            handleClickOpen();
        }
    }, [location, renderedInTable]);

    return (
        <>
            {showButton && (
                <MenuItem dense onClick={handleClickOpen}>
                    <ListItemIcon>
                        <DeleteIcon color={"error"} />
                    </ListItemIcon>
                    Delete Location
                </MenuItem>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                onTransitionExited={handleTransitionExited}
            >
                <DialogTitle>Delete {location?.name}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deleting this location will remove it from the
                        organization. This location and all associated data will
                        be permanently deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        cancel
                    </Button>
                    <Button
                        onClick={() => mutate()}
                        color={"error"}
                        loading={isPending}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
