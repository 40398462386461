import { Stack } from "@mui/material";
import { FormPasswordElement } from "@rhf-kit/mui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const ChangePasswordForm = ({ formReset }: { formReset: boolean }) => {
    const { reset } = useFormContext();

    useEffect(() => {
        reset();
    }, [formReset, reset]);

    return (
        <Stack spacing={2}>
            <FormPasswordElement
                name="password"
                label="Current Password"
                required
            />
            <FormPasswordElement
                name="newPassword"
                label="New Password"
                required
            />
            <FormPasswordElement
                name="confirmPassword"
                label="Confirm Password"
                required
                rules={{
                    validate: (value, formValues) => {
                        return (
                            value === formValues.newPassword ||
                            "Confirmation password does not match"
                        );
                    },
                }}
            />
        </Stack>
    );
};
