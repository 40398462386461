import { ICollection, ILocation, ImageType } from "@api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui";
import { useFormContext } from "react-hook-form";
import { Steps } from "./AddDroneCollection";
import { UploadDropBox } from "./UploadDropBox";

interface IProps {
    /**
     * An existing collection that can be viewed
     */
    selectedCollection?: ICollection;
    /**
     * Function to close the modal
     */
    handleClose: () => void;
    /**
     * Loading indicator shown when creating a collection
     */
    isLoading: boolean;
    /**
     * Function to set the current step
     */
    setStep: (step: Steps) => void;
}

const IMAGE_TYPES = [
    {
        name: "RGB",
        value: "RGB",
    },
    {
        name: "NIR",
        value: "NIR",
    },
    {
        name: "Red Edge",
        value: "RED_EDGE",
    },
    {
        name: "DSM",
        value: "DSM",
    },
];

export const UploadImages = ({
    selectedCollection,
    handleClose,
    isLoading,
    setStep,
}: IProps) => {
    const { watch } = useFormContext();

    const location: ILocation = watch("location");

    const handleBack = () => {
        setStep("location");
    };

    return (
        <>
            <DialogTitle>
                <Toolbar disableGutters sx={{ alignItems: "flex-start" }}>
                    {selectedCollection && (
                        <>
                            <Box display={"flex"} flex={1}>
                                <Stack>
                                    <Typography variant={"h6"}>
                                        Collection #{selectedCollection.id}
                                    </Typography>

                                    <Typography>
                                        <strong>Location</strong>:{" "}
                                        {selectedCollection.location.name}
                                    </Typography>
                                    <Typography variant={"body2"}>
                                        <strong>Collection date</strong>:{" "}
                                        {new Date(
                                            selectedCollection.collection_date
                                        ).toLocaleDateString()}
                                    </Typography>
                                </Stack>
                            </Box>
                        </>
                    )}

                    {!selectedCollection && (
                        <Stack
                            spacing={1}
                            direction={"row"}
                            alignItems={"flex-start"}
                        >
                            <IconButton
                                edge="start"
                                color="primary"
                                onClick={handleBack}
                            >
                                <ArrowBackIcon />
                            </IconButton>

                            <Stack>
                                <Typography variant={"h6"}>
                                    Create Drone Collection
                                </Typography>

                                <Typography>
                                    <strong>Location</strong>: {location.name}
                                </Typography>
                                <Typography variant={"body2"}>
                                    <strong>Collection date</strong>:{" "}
                                    {watch(
                                        "collectionDate"
                                    ).toLocaleDateString()}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}

                    <Box display={"flex"} flex={1} justifyContent={"flex-end"}>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
                <Box display={"flex"} flex={1}></Box>
                <Box display={"flex"} flex={2} justifyContent={"center"}>
                    {/* Date Picker form element */}
                </Box>
                <Box
                    display={"flex"}
                    flex={1}
                    justifyContent={"flex-end"}
                ></Box>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={2}>
                    {IMAGE_TYPES.map((imageType, index) => (
                        <UploadDropBox
                            key={`${imageType.value}-${index}`}
                            handleClose={handleClose}
                            imageType={imageType.name}
                            collection={selectedCollection}
                            collectionImage={selectedCollection?.images.find(
                                (image) => {
                                    return (
                                        image.type ===
                                        ImageType[
                                            imageType.value as keyof typeof ImageType
                                        ]
                                    );
                                }
                            )}
                        />
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                {!selectedCollection && (
                    <Button variant={"contained"} loading={isLoading}>
                        Create Collection
                    </Button>
                )}
            </DialogActions>
        </>
    );
};
