import { useAuth, useOrganizations } from "@components";
import { appRoutes } from "@config";
import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    Box,
    Drawer,
    IconButton,
    List,
    Stack,
    SvgIcon,
    Toolbar,
    Typography,
} from "@mui/material";
import { OrganizationSwitcher } from "../top-bar/OrganizationSwitcher.tsx";
import { SideNavLink } from "./SideNavLink.tsx";

export interface ISideNavRoute {
    /**
     * Display name of the route
     */
    name: string;
    /**
     * Path to navigate to
     */
    path: string;
    /**
     * Icon to display
     */
    icon: typeof SvgIcon;
}

// Width of the drawer
const drawerWidth = 240;

interface ISideNavProps {
    /**
     * Whether the mobile drawer is open
     */
    mobileOpen: boolean;
    /**
     * Function to set the mobile drawer open state
     */
    setMobileOpen: (open: boolean) => void;
    /**
     * Function to set the closing state
     */
    setIsClosing: (closing: boolean) => void;
}

/**
 * SideNav is responsible for rendering the side navigation drawer. This is responsive and will render a permanent drawer on desktop and a temporary drawer on mobile that is opened with a button.
 */
export const SideNav = ({
    mobileOpen,
    setMobileOpen,
    setIsClosing,
}: ISideNavProps) => {
    const { user } = useAuth();
    const { currentOrganization } = useOrganizations();

    // Close the drawer on mobile
    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    // Once the drawer is closed, set the closing state to false
    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    // static organization routes
    const organizationRoutes: ISideNavRoute[] = [
        {
            name: "Map",
            path: appRoutes.organization.map.base,
            icon: MapIcon,
        },
        {
            name: "Locations",
            path: appRoutes.organization.locations.base,
            icon: LocationOnIcon,
        },
        {
            name: "Drone Data",
            path: appRoutes.organization.droneData.base,
            icon: PhotoCameraIcon,
        },
        {
            name: "Members",
            path: appRoutes.organization.members.base,
            icon: GroupIcon,
        },
        {
            name: "Settings",
            path: appRoutes.organization.settings,
            icon: SettingsIcon,
        },
    ];

    // static admin routes
    const adminRoutes: ISideNavRoute[] = [
        {
            name: "Organizations",
            path: appRoutes.admin.organizations.base,
            icon: BusinessIcon,
        },
        {
            name: "Users",
            path: appRoutes.admin.users,
            icon: GroupIcon,
        },
    ];

    // If the user is not logged in, return null. This should never happen but is a safety check
    if (!user) return null;

    // Content to be rendered in either the desktop or mobile drawer
    const sideNavContent = (
        <>
            <Toolbar>
                <Box flexGrow={1} />
                <IconButton
                    onClick={handleDrawerClose}
                    edge={"end"}
                    sx={{ display: { md: "block", lg: "none" }, height: 40 }}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>

            <Box sx={{ overflow: "auto" }}>
                <Stack spacing={2} mt={2}>
                    <Box
                        width={"100%"}
                        sx={{ display: { xs: "block", sm: "none" } }}
                    >
                        <OrganizationSwitcher
                            fullWidth
                            color={"primary"}
                            variant={"outlined"}
                            handleDrawerClose={handleDrawerClose}
                        />
                    </Box>
                    {/* Organization Tabs */}
                    {currentOrganization && (
                        <Box>
                            <Typography
                                fontWeight={600}
                                color={"#000000"}
                                sx={{ opacity: 0.6 }}
                                ml={2.5}
                            >
                                Organization
                            </Typography>
                            <List dense>
                                {organizationRoutes.map((route) => (
                                    <SideNavLink
                                        key={route.name}
                                        route={route}
                                        handleDrawerClose={handleDrawerClose}
                                    />
                                ))}
                            </List>
                        </Box>
                    )}
                    {/* Admin Tabs */}
                    {user.is_admin && (
                        <Box>
                            <Typography
                                fontWeight={600}
                                color={"#000000"}
                                sx={{ opacity: 0.6 }}
                                ml={2.5}
                            >
                                Administration
                            </Typography>
                            <List dense>
                                {adminRoutes.map((route) => (
                                    <SideNavLink
                                        key={route.name}
                                        route={route}
                                        handleDrawerClose={handleDrawerClose}
                                    />
                                ))}
                            </List>
                        </Box>
                    )}
                </Stack>
            </Box>
        </>
    );

    return (
        <>
            {/* Render for desktop*/}
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    display: { xs: "none", lg: "block" },
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
            >
                {sideNavContent}
            </Drawer>

            {/*  Render for mobile */}
            <Drawer
                variant={"temporary"}
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                    width: drawerWidth,
                    display: { md: "block", lg: "none" },
                    flexShrink: 0,
                    zIndex: (theme) => theme.zIndex.drawer + 2,

                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
            >
                {sideNavContent}
            </Drawer>
        </>
    );
};
