import { Box, Stack, Typography } from "@mui/material";
import { BasicDetailsCard, ChangeEmailCard } from "./components";

export const PersonalAccount = () => {
    return (
        <Box maxWidth={"lg"} width={"100%"}>
            <Typography variant={"h4"} gutterBottom>
                Account
            </Typography>

            <Stack gap={4}>
                <BasicDetailsCard />
                <ChangeEmailCard />
            </Stack>
        </Box>
    );
};
