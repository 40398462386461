import React, { Key } from "react";
import { PlaceType } from "./LocationAutoComplete.tsx";
import parse from "autosuggest-highlight/parse";
import Grid from "@mui/material/Grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface CustomHTMLAttributes extends React.HTMLAttributes<HTMLLIElement> {
    key?: Key;
}

interface IRenderOptionProps {
    /**
     * Props to pass to the li element
     */
    props: CustomHTMLAttributes;
    /**
     * The option to render
     */
    option: PlaceType;
}

/**
 * Renders an option in the autocomplete dropdown
 */
export const RenderOption = ({ props, option }: IRenderOptionProps) => {
    const { key, ...rest } = props;

    // extract the matched substrings from the option to be bolded
    const matches =
        option.structured_formatting.main_text_matched_substrings || [];

    // splits the main text into parts indicating which parts should be bold
    const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match) => [match.offset, match.offset + match.length])
    );

    return (
        <li key={key} {...rest}>
            <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                    {/* Render main (bold) text */}
                    {parts.map((part, index) => (
                        <Box
                            key={index}
                            component="span"
                            sx={{
                                fontWeight: part.highlight ? "bold" : "regular",
                            }}
                        >
                            {part.text}
                        </Box>
                    ))}

                    {/* Render secondary text */}
                    <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                    </Typography>
                </Grid>
            </Grid>
        </li>
    );
};
