export const sanitizeFileName = (fileName: string) => {
    // Replace special characters with underscores
    const sanitizedFileName = fileName.replace(
        /[ &/\\#,+()$~%'":*?<>{}]/g,
        "_"
    );

    // Remove multiple underscores
    return sanitizedFileName.replace(/_+/g, "_");
};
