import { ICollection, IFilter, ILocation, ILocationCreate } from "@api";
import { API_URL } from "@config";

import { buildFilterQuery } from "@utils";
import axios from "axios";
import { Page } from "../api.types.ts";
import { IOrganizationAccount } from "../organizationAccounts/index.ts";
import {
    IOrganization,
    IOrganizationCreate,
    IOrganizationUpdate,
} from "./organizations.types";
const API = `${API_URL}/organizations`;

export const createLocation = async (
    organizationId: number,
    location: ILocationCreate
) => {
    const result: { data: ILocation } = await axios.post(
        `${API}/${organizationId}/locations`,
        location
    );
    return result.data;
};

export const getOrganizations = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter = {}
) => {
    const result = await axios.get<Page<IOrganization>>(
        `${API}?page=${page}&size=${size}&${buildFilterQuery(filter)}`
    );
    return result.data;
};

export const createOrganization = async (data: IOrganizationCreate) => {
    const result = await axios.post<IOrganization>(`${API}`, data);
    return result.data;
};

export const getOrganizationById = async (organizationId: string | number) => {
    const result = await axios.get<IOrganization>(`${API}/${organizationId}`);
    return result.data;
};

export const updateOrganization = async (
    organizationId: number,
    data: IOrganizationUpdate
) => {
    const result = await axios.put<IOrganization>(
        `${API}/${organizationId}`,
        data
    );
    return result.data;
};

export const deleteOrganization = async (organizationId: string | number) =>
    await axios.delete(`${API}/${organizationId}`);

export const getOrganizationUsers = async (
    organizationId: string | number,
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<Page<IOrganizationAccount>>(
        `${API}/${organizationId}/accounts?page=${page}&size=${size}&${buildFilterQuery(filter)}`
    );
    return result.data;
};

export const createOrganizationUser = async ({
    organizationId,
    userId,
}: {
    organizationId: string | number;
    userId: string | number;
}) => {
    const result = await axios.post<IOrganizationAccount>(
        `${API}/${organizationId}/accounts`,
        { user_id: userId }
    );
    return result.data;
};

export const getOrganizationLocations = async (
    organizationId: number,
    page: number = 1,
    size: number = 50,
    filter: IFilter = {}
) => {
    const result = await axios.get<Page<ILocation>>(
        `${API}/${organizationId}/locations?page=${page}&size=${size}&${buildFilterQuery(filter)}`
    );
    return result.data;
};

export const createOrganizationLocation = async ({
    organizationId,
    data,
}: {
    organizationId: string;
    data: ILocation;
}) => {
    const result = await axios.post<ILocation>(
        `${API}/${organizationId}/locations`,
        data
    );
    return result.data;
};

export const getOrganizationCollections = async (
    organizationId: string | number,
    page: number = 1,
    size: number = 50,
    filter: IFilter = {}
) => {
    const result = await axios.get<Page<ICollection>>(
        `${API}/${organizationId}/collections?page=${page}&size=${size}&${buildFilterQuery(filter)}`
    );
    return result.data;
};
