import {
    DashboardContainer,
    ErrorResetBoundary,
    useOrganizations,
} from "@components";
import { Outlet } from "react-router-dom";

/**
 * Members component is a parent component for the members page of an organization. It includes a dashboard container and an outlet for nested routes.
 */
const Members = () => {
    const { currentOrganization } = useOrganizations();

    if (!currentOrganization) {
        return null;
    }

    return (
        <DashboardContainer title={`${currentOrganization.name} Members`}>
            <ErrorResetBoundary>
                <Outlet />
            </ErrorResetBoundary>
        </DashboardContainer>
    );
};

export default Members;
