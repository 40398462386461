import { getOrganizationById } from "@api";
import { BackButton, DashboardContainer } from "@components";
import { Box } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { EditOrganizationCard } from "./components";

export default function EditOrganization() {
    const { id } = useParams();

    const { data } = useSuspenseQuery({
        queryKey: ["organizationToEdit", id],
        queryFn: () => getOrganizationById(id || -1),
    });

    return (
        <DashboardContainer title={"Organization Management"}>
            <Box>
                <BackButton />
            </Box>
            <Box maxWidth={"md"}>
                <EditOrganizationCard organization={data} />
            </Box>
        </DashboardContainer>
    );
}
