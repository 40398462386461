import { IAttachment, IFileAttachment } from "@api";
import { BUNNY_PULL_ZONE } from "@config";
import { API_URL } from "@config";
import axios from "axios";

const API = `${API_URL}/attachments`;

// get attachments from file server
export const getAttachments = async (
    attachments: IAttachment[] | IFileAttachment[]
) => {
    const files: IFileAttachment[] = await Promise.all(
        attachments.map(async (attachment) => {
            // if the attachment is already a file attachment, return it
            if (attachment.id === -1) return attachment as IFileAttachment;

            // set the attachment as a db attachment
            const dbAttachment = attachment as IAttachment;

            // fetch the file from the file server
            const response = await fetch(
                `${BUNNY_PULL_ZONE}/${dbAttachment.file_path}`
            );

            // create a file object from the response
            const blob = await response.blob();
            const file = new File([blob], dbAttachment.file_name, {
                type: dbAttachment.file_type,
                lastModified: new Date(dbAttachment.created_at).getTime(),
            });
            return { id: dbAttachment.id, file, attachment } as IFileAttachment;
        })
    );

    return files;
};

export const deleteAttachments = async (attachmentIds: number[]) => {
    return await Promise.all(
        attachmentIds.map(async (id) => {
            await axios.delete(`${API}/${id}`);
        })
    );
};
