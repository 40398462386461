import axios from "axios";
import { API_URL } from "@config";
import {
    ICollection,
    ICollectionUpdate,
    IUploadImage,
} from "./collections.types.ts";
import { IImage } from "../images";
import { Page } from "../api.types.ts";
import { sanitizeFileName } from "@utils";

const API = `${API_URL}/collections`;

// gets a single collection
export const getCollection = async (collectionId: number) => {
    const result: { data: ICollection } = await axios.get(
        `${API}/${collectionId}`
    );
    return result.data;
};

// update a collection
export const updateCollection = async (
    collectionId: number,
    body: ICollectionUpdate
) => {
    const result: { data: ICollection } = await axios.put(
        `${API}/${collectionId}`,
        body
    );
    return result.data;
};

// delete a collection
export const deleteCollection = async (collectionId: number) => {
    const result = await axios.delete(`${API}/${collectionId}`);
    return result.data;
};

export const uploadImage = async (imageUpload: IUploadImage) => {
    const { collectionId, fileName, type, image } = imageUpload;

    const formData = new FormData();
    formData.append("image", image);

    return await axios.post<IImage>(
        `${API}/${collectionId}/uploads?file_name=${sanitizeFileName(fileName)}&type=${type}`,
        formData
    );
};

export const getCollectionImages = async (
    collectionId: number,
    page: number = 1,
    size: number = 50
) => {
    const result = await axios.get<Page<IImage>>(
        `${API}/${collectionId}/images?page=${page}&size=${size}`
    );
    return result.data;
};
