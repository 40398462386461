import {
    AppBar,
    Box,
    IconButton,
    Slide,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from "@mui/material";
import Logo from "/logo-transparent.png";
import { UserMenu } from "./UserMenu.tsx";
import { OrganizationSwitcher } from "./OrganizationSwitcher.tsx";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactElement } from "react";

interface IHideOnScrollProps {
    children: ReactElement;
}

const HideOnScroll = ({ children }: IHideOnScrollProps) => {
    const trigger = useScrollTrigger({
        threshold: 50,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

interface ITopBarProps {
    /**
     * Mobile drawer open state
     */
    mobileOpen: boolean;
    /**
     * Set mobile drawer open state
     */
    setMobileOpen: (open: boolean) => void;
    /**
     * To determine if the drawer is closing
     */
    isClosing: boolean;
}

/**
 * Top bar component
 * Responsible for rendering the top bar of the application.
 * on mobile, it will render a menu button to open the drawer.
 */
export const Topbar = ({
    mobileOpen,
    setMobileOpen,
    isClosing,
}: ITopBarProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "lg"));

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    return (
        <HideOnScroll>
            <AppBar
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                elevation={1}
            >
                <Toolbar>
                    {/* Menu button (mobile only)*/}
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* Logo */}
                    <Box display={"flex"}>
                        <img
                            src={Logo}
                            alt="UAVistas Logo"
                            width={isMobile ? 70 : 90}
                        />
                    </Box>

                    {/* Organization Switcher (desktop only) */}
                    <Box
                        flexGrow={1}
                        sx={{ display: { xs: "none", sm: "block" } }}
                    >
                        <OrganizationSwitcher />
                    </Box>

                    {/* Spacer (mobile only) */}
                    <Box
                        flexGrow={1}
                        sx={{ display: { xs: "block", sm: "none" } }}
                    />

                    {/* User Menu*/}
                    <UserMenu />
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
};
