import { ICollection } from "@api";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
    /**
     * Collections for the location
     */
    collections: ICollection[];
    /**
     * Current collection
     */
    currentCollection?: ICollection;
    /**
     * Set the current collection
     */
    setCollection: (collection: ICollection) => void;
}

/**
 * History control that allows the user to select a collection based on the collection date
 */
export const DroneCollectionSwitcher = ({
    collections,
    currentCollection,
    setCollection,
}: IProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();
    const [collectionDate] = useState(searchParams.get("collectionDate"));

    // sort collections by collection date descending
    const sortedCollections = collections.sort(
        (a, b) =>
            new Date(b.collection_date).getTime() -
            new Date(a.collection_date).getTime()
    );

    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        // Find the location object based on the selected ID
        const newCollection = collections.find(
            (collection) => collection.id === event.target.value
        );
        if (newCollection) {
            setCollection(newCollection);
        }
    };

    // Set collection when collections are loaded
    useEffect(() => {
        if (collections) {
            const collectionsWithUploadedImages = collections
                .filter((collection) =>
                    collection.images.some((image) => image.upload_complete)
                )
                .sort(
                    (a, b) =>
                        new Date(b.collection_date).getTime() -
                        new Date(a.collection_date).getTime()
                );
            if (collectionDate) {
                const collection = collectionsWithUploadedImages.find(
                    (collection) =>
                        new Date(
                            collection.collection_date
                        ).toLocaleDateString() === collectionDate
                );
                if (collection) {
                    setCollection(collection);
                } else {
                    setCollection(collectionsWithUploadedImages[0]);
                }
            } else {
                setCollection(collectionsWithUploadedImages[0]);
            }
        }
    }, [collectionDate, collections, setCollection]);

    return (
        <FormControl>
            <InputLabel id="collection-select-label">
                Drone Collection Date
            </InputLabel>
            <Select
                labelId={"collection-select-label"}
                value={currentCollection ? currentCollection.id : ""}
                size={isMobile ? "small" : "medium"}
                renderValue={(value) => {
                    const collection = collections.find(
                        (collection) => collection.id === value
                    );
                    return collection
                        ? format(collection.collection_date, "PPP")
                        : "";
                }}
                onChange={handleSelectChange}
                label={"Drone Collection Date"}
                sx={{
                    width: { xs: 80, sm: 140, md: "auto" },
                    minWidth: { xs: 80, sm: 140, md: 200 },
                }}
            >
                <MenuItem
                    id={"menu-header"}
                    value={"Drone Collections"}
                    disableRipple
                    dense
                    sx={{
                        backgroundColor: "Menu",
                        ":hover": {
                            backgroundColor: "#ffffff",
                            cursor: "default",
                        },
                    }}
                >
                    Drone Collections
                </MenuItem>
                {sortedCollections.map((collection) => (
                    <MenuItem key={collection.id} value={collection.id} dense>
                        {format(collection.collection_date, "PPP")}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
