import { Box, Typography } from "@mui/material";
import { FormBox, FormScreenContainer, useAuth } from "@components";
import { useAsync } from "@react-hookz/web";

import { forgotPassword } from "@api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
    FormContainer,
    FormEmailElement,
    FormButton as Button,
} from "@rhf-kit/mui";
import { project } from "@config";

interface FormData {
    email: string;
}

const ForgotPassword = () => {
    const { authenticated } = useAuth();
    const [forgotPasswordState, forgotPasswordActions] =
        useAsync(forgotPassword);
    const navigate = useNavigate();

    const onSubmit = (data: FormData) => {
        forgotPasswordActions.execute(data.email);
        enqueueSnackbar(
            "If there is an account registered with that email address, we will send a link to reset your password.",
            {
                variant: "success",
            }
        );
    };

    useEffect(() => {
        if (authenticated) {
            navigate("/");
        }
    }, [authenticated, navigate]);

    return (
        <FormScreenContainer>
            <FormBox>
                <FormContainer
                    defaultValues={{ email: "" }}
                    onSuccess={onSubmit}
                >
                    <Typography
                        variant={"h4"}
                        fontWeight={600}
                        textAlign={"center"}
                        gutterBottom
                    >
                        Forgot Password
                    </Typography>
                    <Typography gutterBottom>
                        Enter your email address below and we'll send you a link
                        to reset your password.
                    </Typography>
                    <FormEmailElement
                        renderIcon={false}
                        name="email"
                        required
                        fullWidth
                        label="Email Address"
                        margin="normal"
                    />
                    <Box
                        display="flex"
                        justifyContent={"flex-end"}
                        sx={{ paddingTop: 2 }}
                    >
                        <Button
                            variant="contained"
                            loading={forgotPasswordState.status === "loading"}
                        >
                            Submit
                        </Button>
                    </Box>
                </FormContainer>
            </FormBox>
            <Box color="GrayText">
                <Typography mt={2}>© 2024 {project.name}</Typography>
            </Box>
        </FormScreenContainer>
    );
};

export default ForgotPassword;
