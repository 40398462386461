import { ErrorResetBoundary, useOrganizations } from "@components";
import { Outlet } from "react-router-dom";

const Organization = () => {
    const { currentOrganization } = useOrganizations();

    if (!currentOrganization) {
        return null;
    }

    return (
        <ErrorResetBoundary>
            <Outlet />
        </ErrorResetBoundary>
    );
};

export default Organization;
