import { Typography, Button, Box } from "@mui/material";
import { useAuth, useOrganizations } from "@components";
import { appRoutes, project } from "@config";
import { Navigate } from "react-router-dom";

const Home = () => {
    const { session } = useAuth();
    const { currentOrganization } = useOrganizations();

    // For now if the user is on the base route and has a current organization redirect them to the organization locations
    if (
        currentOrganization &&
        location.pathname === appRoutes.organization.base
    ) {
        return <Navigate to={appRoutes.organization.map.base} replace />;
    }

    return (
        <Box
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            display="flex"
        >
            <Typography fontSize="2rem" fontWeight="bold">
                Welcome to the {project.name} home page!
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => session.end()}
            >
                Logout
            </Button>
        </Box>
    );
};

export default Home;
