import { Layer, Source } from "react-map-gl";
import { theme } from "@config";
import { IAnnotation } from "@api";

interface FeatureLayerMap {
    [key: string]: {
        type: "fill" | "line" | "circle";
        paint: Record<string, string | number>;
        selectedPaint: Record<string, string | number>;
        editingPaint: Record<string, string | number>;
    };
}

interface IAllAnnotationsSourceProps {
    /**
     * Annotations to render
     */
    annotations?: IAnnotation[];
    /**
     * Selected annotation
     */
    selectedAnnotation?: IAnnotation;
    /**
     * Annotation currently being edited
     */
    editingAnnotation?: IAnnotation;
}

/**
 * Source component that renders a list of annotations on a map
 */
export const AllAnnotationsSource = ({
    annotations,
    selectedAnnotation,
    editingAnnotation,
}: IAllAnnotationsSourceProps) => {
    // Don't render anything if no annotations
    if (!annotations) return null;

    const featureLayerMap: FeatureLayerMap = {
        Polygon: {
            type: "fill",
            paint: {
                "fill-color": theme.palette.grey[500],
                "fill-opacity": 0.3,
            },
            selectedPaint: {
                "fill-color": theme.palette.primary.main,
                "fill-opacity": 0.5,
            },
            editingPaint: {
                "fill-opacity": 0,
            },
        },
        LineString: {
            type: "line",
            paint: {
                "line-color": theme.palette.grey[500],
                "line-width": 3,
                "line-opacity": 0.6,
            },
            selectedPaint: {
                "line-color": theme.palette.primary.main,
                "line-width": 3,
                "line-opacity": 0.8,
            },
            editingPaint: {
                "line-opacity": 0,
            },
        },
        Point: {
            type: "circle",
            paint: {
                "circle-color": theme.palette.grey[500],
                "circle-radius": 12,
                "circle-opacity": 0.6,
            },
            selectedPaint: {
                "circle-color": theme.palette.primary.main,
                "circle-radius": 12,
                "circle-opacity": 0.8,
            },
            editingPaint: {
                "circle-opacity": 0,
            },
        },
    };

    const renderLayers = () => {
        return annotations.map((annotation) => {
            const feature = annotation.geometry.geojson;
            const featureLayer =
                featureLayerMap[
                    feature.geometry.type as keyof typeof featureLayerMap
                ];

            // determine if feature is currently selected
            const isSelected =
                selectedAnnotation?.geometry.geojson.id === feature.id;

            // determine if feature is currently being edited
            const isEditing =
                editingAnnotation?.geometry.geojson.id === feature.id;

            return (
                <Source
                    type={"geojson"}
                    data={feature}
                    key={feature.id}
                    id={`${feature.id}`}
                >
                    <Layer
                        key={`annotation-${featureLayer}-${feature.id}`}
                        id={`${feature.id}`}
                        type={featureLayer.type}
                        paint={
                            isEditing
                                ? featureLayer.editingPaint
                                : isSelected
                                  ? featureLayer.selectedPaint
                                  : featureLayer.paint
                        }
                    />

                    {
                        // If the feature is a polygon, render the line as well
                        feature.geometry.type === "Polygon" && (
                            <Layer
                                key={`annotation-line-${feature.id}`}
                                id={`${feature.id}-border`}
                                type={featureLayerMap.LineString.type}
                                paint={
                                    isEditing
                                        ? featureLayerMap.LineString
                                              .editingPaint
                                        : isSelected
                                          ? featureLayerMap.LineString
                                                .selectedPaint
                                          : featureLayerMap.LineString.paint
                                }
                            />
                        )
                    }

                    {
                        // If the feature is a line, we need to render a buffer around it to make it easier to interact with
                        feature.geometry.type === "LineString" && (
                            <Layer
                                key={`annotation-line-buffer-${feature.id}`}
                                id={`${feature.id}-buffer`}
                                type={"line"}
                                paint={{ "line-width": 24, "line-opacity": 0 }}
                            />
                        )
                    }
                </Source>
            );
        });
    };

    return renderLayers();
};
