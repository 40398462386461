import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@components";
import { appRoutes } from "@config";

/**
 * ProtectedAdminRoute component
 * Handles the protected admin routes
 */
const ProtectedAdminRoute = () => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to={appRoutes.login} replace />;
    }

    if (!user.is_admin) {
        return <Navigate to={appRoutes.base} replace />;
    }

    return <Outlet />;
};

export default ProtectedAdminRoute;
