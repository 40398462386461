import {
    IOrganizationCreate,
    IUser,
    createOrganization,
    searchUsers,
} from "@api";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
    Autocomplete,
    Card,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useDebouncedEffect } from "@react-hookz/web";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

interface IProps {
    /**
     * Called when the user clicks the backspace icon
     */
    onPressBack: () => void;
}

interface IFormData {
    name: string;
}

export const CreateOrganization = ({ onPressBack }: IProps) => {
    const queryClient = useQueryClient();

    const [inputValue, setInputValue] = useState<string>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const [foundUsers, setFoundUsers] = useState<IUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

    const { data, isFetching } = useQuery({
        queryKey: ["create-org-users", searchValue],
        queryFn: () => searchUsers(searchValue),
    });

    const createOrganizationMutation = useMutation({
        mutationFn: (data: IOrganizationCreate) => createOrganization(data),
        onSuccess: () => {
            // Refresh the organizations table
            queryClient.invalidateQueries({
                queryKey: ["admin-organizations"],
            });
            queryClient.invalidateQueries({ queryKey: ["user-organizations"] });

            enqueueSnackbar("Organization created", { variant: "success" });
            // Go back to the list of organizations
            onPressBack();
        },
        onError: () => {
            enqueueSnackbar("Failed to create organization", {
                variant: "error",
            });
        },
    });

    // We want to have debounce on the mutation so we don't make an API call every keystroke
    useDebouncedEffect(
        () => {
            setSearchValue(inputValue);
        },
        [inputValue],
        250
    );

    useEffect(() => {
        if (data) {
            setFoundUsers(data);
        }
    }, [data]);

    const onSubmit = (data: IFormData) => {
        const newOrganization: IOrganizationCreate = {
            name: data.name,
            users: selectedUsers,
        };

        createOrganizationMutation.mutate(newOrganization);
    };

    return (
        <Card sx={{ width: "100%", p: 4 }}>
            <Stack alignItems="center" direction="row" sx={{ mb: 2 }}>
                <IconButton onClick={() => onPressBack()}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    flexGrow={1}
                    textAlign="center"
                >
                    Create New Organization
                </Typography>
            </Stack>
            <FormContainer defaultValues={{ name: "" }} onSuccess={onSubmit}>
                <Stack spacing={2}>
                    <FormTextFieldElement
                        name="name"
                        required
                        label="Name"
                        size="small"
                    />
                    <Autocomplete
                        options={foundUsers}
                        loading={isFetching}
                        multiple
                        onChange={(_, selected) => {
                            setSelectedUsers(selected.map((user) => user.id));
                            setInputValue("");
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name}`
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Users (optional)"
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <Grid container>
                                    <Stack>
                                        <Typography>
                                            {option.first_name}{" "}
                                            {option.last_name}
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color="grey.500"
                                        >
                                            {option.email}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </li>
                        )}
                        filterOptions={(x) => x}
                        size="small"
                    />
                    <FormButton
                        variant="contained"
                        color="primary"
                        sx={{ maxWidth: { md: "25%" } }}
                    >
                        Create organization
                    </FormButton>
                </Stack>
            </FormContainer>
        </Card>
    );
};
