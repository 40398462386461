// API constants
export const API_URL = import.meta.env.VITE_API_URL;

// annotation constants
export const MAX_ATTACHMENT_LIMIT = 6;
export const MAX_COMMENT_LENGTH = 200;
export const ACCEPTED_IMAGE_FORMATS = ".jpeg, .jpg, .png, .webp";

// Bunny CDN constants
export const BUNNY_PULL_ZONE = import.meta.env.VITE_BUNNY_PULL_ZONE;

// Mapbox constants
export const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
