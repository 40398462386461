import { Stack, Typography } from "@mui/material";
import { CommentOptions } from "./CommentOptions.tsx";
import { IComment } from "@api";
import { useAuth } from "@components";
interface ICommentHeaderProps {
    /**
     * The heading of the comment.
     */
    heading: string;
    /**
     * The comment object.
     */
    comment: IComment;
}

/**
 * Comment header component that displays the heading and comment options.
 */
export const CommentHeader = ({ heading, comment }: ICommentHeaderProps) => {
    const { user } = useAuth();

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Typography>{heading}</Typography>
            {user?.id === comment.created_by.id && (
                <CommentOptions comment={comment} />
            )}
        </Stack>
    );
};
