import { IOrganization, deleteOrganization } from "@api";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";

interface IProps {
    organization?: IOrganization;
    open: boolean;
    handleClose: () => void;
}

export const DeleteOrganizationModal = ({
    organization,
    open,
    handleClose,
}: IProps) => {
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
        mutationFn: (organizationId: number) =>
            deleteOrganization(organizationId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["admin-organizations"],
            });
            queryClient.invalidateQueries({ queryKey: ["user-organizations"] });
            handleClose();
            enqueueSnackbar("Organization deleted", { variant: "success" });
        },
        onError: () => {
            handleClose();
            enqueueSnackbar("Failed to delete organization", {
                variant: "error",
            });
        },
    });

    if (!organization) {
        return null;
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle>
                <Toolbar variant="dense" disableGutters>
                    <Typography>Delete {organization.name}</Typography>
                    <Box flexGrow={1} />
                    <IconButton edge="end" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete the {organization.name}{" "}
                organization?
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => deleteMutation.mutate(organization.id)}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
