import { ErrorResetBoundary } from "@components";
import { theme } from "@config";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./styles.css";
import "react-photo-view/dist/react-photo-view.css";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
        },
    },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <QueryClientProvider client={queryClient}>
                    <ErrorResetBoundary>
                        <App />
                    </ErrorResetBoundary>
                    <CssBaseline />
                    <SnackbarProvider autoHideDuration={5000} />
                </QueryClientProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </React.StrictMode>
);
