import { project } from "@config";
import { Box, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { IApiAxiosError, registerUser } from "@api";
import { useEffect } from "react";
import { useAuth, FormBox, FormScreenContainer, Link } from "@components";
import { useNavigate } from "react-router-dom";
import {
    FormContainer,
    FormEmailElement,
    FormPasswordElement,
    FormTextFieldElement,
    FormButton as Button,
} from "@rhf-kit/mui";
import Alert from "@mui/material/Alert";

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

const Register = () => {
    const { authenticated, session } = useAuth();
    const [registerState, registerActions] = useAsync(registerUser);
    const navigate = useNavigate();

    const errorAlert = (registerState.error as IApiAxiosError)?.response?.data
        ?.detail;

    const onSubmit = (data: FormData) => {
        registerActions.execute({
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            password: data.password,
        });
    };

    useEffect(() => {
        if (registerState.status === "success" && registerState.result) {
            session.create(registerState.result.access_token);
        }
    }, [registerState.result, registerState.status, session]);

    useEffect(() => {
        if (authenticated) {
            navigate("/");
        }
    }, [authenticated, navigate]);

    return (
        <FormScreenContainer>
            <FormBox>
                <FormContainer onSuccess={onSubmit}>
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                        Get Started Now
                    </Typography>
                    <Typography gutterBottom>
                        Welcome to {project.name}. Please enter your details
                        below to create an account.
                    </Typography>
                    {errorAlert && (
                        <Box>
                            <Alert severity="error">
                                <Typography color="error.main">
                                    {errorAlert}
                                </Typography>
                            </Alert>
                        </Box>
                    )}
                    <Box display="flex" gap={1} justifyContent="space-between">
                        <FormTextFieldElement
                            name="firstName"
                            required
                            fullWidth
                            label="First Name"
                            margin="normal"
                        />
                        <FormTextFieldElement
                            name="lastName"
                            required
                            fullWidth
                            label="Last Name"
                            margin="normal"
                        />
                    </Box>
                    <FormEmailElement
                        renderIcon={false}
                        name="email"
                        required
                        fullWidth
                        label="Email Address"
                        margin="normal"
                    />

                    <FormPasswordElement
                        required
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                    />
                    <FormPasswordElement
                        required
                        fullWidth
                        label="Confirm Password"
                        margin="normal"
                        name="confirmPassword"
                        rules={{
                            validate: (value, formValues) =>
                                value === formValues.password ||
                                "Passwords do not match",
                        }}
                    />

                    <Button
                        fullWidth
                        loadingPosition={"end"}
                        loading={registerState.status === "loading"}
                        variant={"contained"}
                    >
                        Sign Up
                    </Button>
                    <Typography mt={2}>
                        Already have an account?{" "}
                        <Link href="/login">Sign In</Link>
                    </Typography>
                </FormContainer>
            </FormBox>
            <Box color="GrayText">
                <Typography mt={2}>© 2024 {project.name}</Typography>
            </Box>
        </FormScreenContainer>
    );
};

export default Register;
