import { IOrganization, IOrganizationUpdate, updateOrganization } from "@api";
import { CancelButton, useOrganizations } from "@components";
import BusinessIcon from "@mui/icons-material/Business";
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import { FormButton as Button, FormContainer } from "@rhf-kit/mui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { EditOrganizationForm } from "./EditOrganizationForm";

interface IFormData {
    name: string;
}

interface IProps {
    organization: IOrganization;
}

export const EditOrganizationCard = ({ organization }: IProps) => {
    const queryClient = useQueryClient();
    const [formReset, setFormReset] = useState<IOrganization>();
    const { currentOrganization, setCurrentOrganization } = useOrganizations();

    const { mutate, isPending } = useMutation({
        mutationFn: ({
            organizationId,
            data,
        }: {
            organizationId: number;
            data: IOrganizationUpdate;
        }) => updateOrganization(organizationId, data),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["user-organizations"] });
            queryClient.invalidateQueries({
                queryKey: ["organizationToEdit"],
            });
            enqueueSnackbar("Organization updated", {
                variant: "success",
            });

            // tell the form to reset with the updated data
            setFormReset(data);

            // update the current organization if it's the one being edited
            if (currentOrganization && currentOrganization.id === data.id) {
                setCurrentOrganization(data);
            }
        },
        onError: () => {
            enqueueSnackbar("Failed to update organization", {
                variant: "error",
            });
        },
    });

    const handleSubmit = (formData: IFormData) => {
        mutate({
            organizationId: organization.id,
            data: {
                name: formData.name,
            },
        });
    };

    const defaultValues: IFormData = {
        name: organization.name,
    };

    return (
        <Card>
            <FormContainer
                defaultValues={defaultValues}
                onSuccess={handleSubmit}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{
                                backgroundColor: "transparent",
                                boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.08);",
                            }}
                        >
                            <BusinessIcon color="primary" />
                        </Avatar>
                    }
                    title={`Edit ${organization.name}`}
                    titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                    <EditOrganizationForm formReset={formReset} />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <CancelButton />
                    <Button
                        variant="contained"
                        loadingPosition="end"
                        loading={isPending}
                    >
                        Save Changes
                    </Button>
                </CardActions>
            </FormContainer>
        </Card>
    );
};
