import { IProfile } from "@api";
import { Stack, TextField } from "@mui/material";
import { FormEmailElement } from "@rhf-kit/mui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface IProps {
    formReset?: IProfile;
}

export const ChangeEmailForm = ({ formReset }: IProps) => {
    const { reset, watch } = useFormContext();

    useEffect(() => {
        if (formReset) {
            reset({
                currentEmail: formReset.email,
                email: "",
                confirmEmail: "",
            });
        }
    }, [formReset, reset]);

    return (
        <Stack spacing={2}>
            <TextField
                label="Current Email"
                value={watch("currentEmail")}
                disabled
            />
            <FormEmailElement
                name="email"
                label="New Email"
                renderIcon={false}
                required
            />
            <FormEmailElement
                name="confirmEmail"
                label="Confirm new Email"
                renderIcon={false}
                required
                rules={{
                    validate: (value, formValues) => {
                        return (
                            value === formValues.email ||
                            "Confirmation email does not match"
                        );
                    },
                }}
            />
        </Stack>
    );
};
