import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
    MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteComment, IComment } from "@api";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

interface IDeleteCommentProps {
    /**
     * Comment being deleted
     */
    comment: IComment;
    /**
     * Function to close the options menu
     */
    handleOptionsClose?: () => void;
}

/**
 * Delete comment component that displays a dialog to confirm the deletion of a comment.
 */
export const DeleteComment = ({
    comment,
    handleOptionsClose,
}: IDeleteCommentProps) => {
    const [open, setOpen] = useState(false);

    // open the dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // close the dialog and the options menu
    const handleClose = () => {
        if (handleOptionsClose) {
            handleOptionsClose();
        }
        setOpen(false);
    };

    const queryClient = useQueryClient();
    // mutation to delete the comment
    const { mutate, isPending } = useMutation({
        mutationFn: () => deleteComment(comment.id),
        onSuccess: () => {
            enqueueSnackbar("Comment deleted", { variant: "success" });
            queryClient.invalidateQueries({
                queryKey: ["comments", comment.annotation_id],
            });

            handleClose();
        },
        onError: () => {
            enqueueSnackbar("An error occurred while deleting the location.", {
                variant: "error",
            });
        },
    });

    return (
        <>
            <MenuItem dense onClick={handleClickOpen}>
                <ListItemIcon>
                    <DeleteIcon color={"error"} />
                </ListItemIcon>
                Delete comment
            </MenuItem>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Comment?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        cancel
                    </Button>
                    <Button
                        onClick={() => mutate()}
                        color={"error"}
                        loading={isPending}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
