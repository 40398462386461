import axios from "axios";
import { API_URL } from "@config";
import { IOrganizationAccount } from "./organizationAccounts.types.ts";
const API = `${API_URL}/organization-accounts`;

export const getOrganizationAccount = async (accountId: string | number) => {
    const result: { data: IOrganizationAccount } = await axios.get(
        `${API}/${accountId}`
    );
    return result.data;
};

export const deleteOrganizationAccount = async (accountId: number) => {
    const result: { data: IOrganizationAccount[] } = await axios.delete(
        `${API}/${accountId}`
    );
    return result.data;
};
