import { getOrganizationLocations, ILocation } from "@api";
import { DataTable, DateFilterInput } from "@components";
import { useFilterModel } from "@hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { Box } from "@mui/material";
import {
    GridActionsCellItem,
    GridColDef,
    GridRowParams,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { DeleteLocation, LocationEditor } from "../../map";

interface LocationRow extends ILocation {
    created_at: Date;
}

interface IProps {
    organizationId: number;
}

export const LocationsList = ({ organizationId }: IProps) => {
    const [rows, setRows] = useState<LocationRow[]>([]);
    const [locationToDelete, setLocationToDelete] = useState<ILocation>();
    const [locationToEdit, setLocationToEdit] = useState<ILocation>();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const { filterModel, handleFilterModelChange } = useFilterModel();

    const autosizeOptions = {
        columns: ["name", "created_at"],
        includeHeaders: true,
        expand: true,
    };

    const { data: locations, isFetching } = useQuery({
        queryKey: [
            "locationsList",
            paginationModel.page,
            paginationModel.pageSize,
            organizationId,
            filterModel,
        ],
        queryFn: () =>
            getOrganizationLocations(
                organizationId,
                paginationModel.page + 1,
                paginationModel.pageSize,
                filterModel
            ),
    });

    // Column Definition for the Table
    const columns: GridColDef<LocationRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                sortingOrder: ["desc", "asc"],
                display: "flex",
            },
            { field: "name", headerName: "Name", display: "flex" },
            {
                field: "description",
                headerName: "Description",
                display: "flex",
                flex: 1,
                minWidth: 200,
                maxWidth: 600,
            },
            {
                field: "created_at",
                headerName: "Date Created",

                filterOperators: [
                    {
                        label: "Date is",
                        value: "dateIs",
                        getApplyFilterFn: (filterItem) => {
                            if (!filterItem.value) {
                                return null;
                            }
                            return ({ value }) => {
                                return value === filterItem.value;
                            };
                        },
                        InputComponent: DateFilterInput,
                    },
                ],
                display: "flex",
                valueFormatter: (value) => {
                    if (value) {
                        return format(value, "MMM dd, yyyy");
                    }
                    return "";
                },
            },
            {
                field: "actions",
                type: "actions",
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        showInMenu
                        icon={<EditLocationIcon color="primary" />}
                        label="Edit Location"
                        onClick={() => {
                            setLocationToEdit(params.row);
                        }}
                    />,
                    <GridActionsCellItem
                        showInMenu
                        icon={<DeleteIcon color="error" />}
                        label="Delete Location"
                        onClick={() => {
                            setLocationToDelete(params.row);
                        }}
                    />,
                ],
            },
        ],
        []
    );

    useEffect(() => {
        if (locations) {
            const rows = locations.items.map((location) => ({
                ...location,
                created_at: new Date(location.created_at),
            }));
            setRows(rows);
        }
    }, [locations]);

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(locations?.total || 0);

    const rowCount = useMemo(() => {
        if (locations?.total !== undefined) {
            rowCountRef.current = locations?.total;
        }
        return rowCountRef.current;
    }, [locations?.total]);

    return (
        <>
            <Box minWidth={200} width="100%" height="100%">
                <DataTable
                    loading={isFetching}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "id", sort: "desc" }],
                        },
                    }}
                    autoSize
                    autosizeOptions={autosizeOptions}
                    rowCount={rowCount}
                    pageSizeOptions={[10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode={"server"}
                    filterMode={"server"}
                    sortingMode={"server"}
                    onFilterModelChange={handleFilterModelChange}
                    onSortModelChange={handleFilterModelChange}
                />
            </Box>
            <DeleteLocation
                location={locationToDelete}
                showButton={false}
                setLocation={setLocationToDelete}
                renderedInTable
            />
            <LocationEditor
                location={locationToEdit}
                showButton={false}
                setEditingLocation={setLocationToEdit}
                renderedInTable
            />
        </>
    );
};
