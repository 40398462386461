import { DashboardContainer, useOrganizations } from "@components";
import { Box } from "@mui/material";
import { EditOrganizationCard } from "../admin/organizations";

const Settings = () => {
    const { currentOrganization } = useOrganizations();

    if (!currentOrganization) {
        return null;
    }

    return (
        <DashboardContainer title={`${currentOrganization.name} Settings`}>
            <Box maxWidth={"md"}>
                <EditOrganizationCard organization={currentOrganization} />
            </Box>
        </DashboardContainer>
    );
};
export default Settings;
