import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FormButton as Button } from "@rhf-kit/mui";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ICollection, IImage } from "@api";
import { ImagePanel } from "./ImagePanel.tsx";
import { PendingImagePanel } from "./PendingImagePanel.tsx";
import { VisuallyHiddenInput } from "@components";

interface IUploadDropBoxProps {
    /**
     * The type of image being uploaded
     */
    imageType: string;
    /**
     * The collection the image belongs to
     */
    collection?: ICollection;
    /**
     * The image object to display in replace of the drop box if it exists
     */
    collectionImage?: IImage;
    /**
     * Function to close the modal
     */
    handleClose: () => void;
}

/**
 * UploadDropBox component is a drop box that allows users to upload images. It handles the uploading of images and displays the image if it exists.
 */
export const UploadDropBox = ({
    imageType,
    collection,
    collectionImage,
    handleClose,
}: IUploadDropBoxProps) => {
    const { setValue, watch } = useFormContext();
    const [image, setImage] = useState<IImage>();

    // Add file to form data
    const addFile = (file?: File) => {
        if (file) {
            setValue(`images.${imageType}`, file);
        }
    };

    // Handle file attachment when user selects a file
    const handleFileAttach = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];
        addFile(file);
    };

    // Prevent default behavior when dragging over the drop box
    function dragOverHandler(event: React.DragEvent<HTMLDivElement>) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
    }

    // Handle file drop when user drops a file
    const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            addFile(file);
        }
    };

    // Set image if collectionImage is provided
    useEffect(() => {
        if (collectionImage) {
            setImage(collectionImage);
        }
    }, [collectionImage]);

    // If there is an image, and it is not a part of the form, return the image panel
    // This indicates that the image being displayed exists in the database
    if (image && collection) {
        return (
            <ImagePanel
                image={image}
                setImage={setImage}
                collection={collection}
                handleClose={handleClose}
            />
        );
    }

    // If there is an image in the form, return the pending image panel
    // This indicates that the image is pending or starting an uploaded and does not yet exist in the database
    if (watch(`images.${imageType}`) && !image) {
        return (
            <PendingImagePanel
                setImage={setImage}
                image={watch(`images.${imageType}`)}
                collectionId={collection?.id}
                imageType={imageType}
            />
        );
    }

    // If there is no image, and no collection, return the drop box which handles file uploads
    if (!watch(`images.${imageType}`) && !image && !collection) {
        return (
            <div onDragOver={dragOverHandler} onDrop={handleFileDrop}>
                {/* Drop box area */}
                <Stack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    p={2}
                    spacing={2}
                    border={"1px dashed"}
                    borderRadius={2}
                    borderColor={"primary.main"}
                    width={"100%"}
                >
                    <Stack alignItems={"center"} spacing={1}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing={0.5}
                        >
                            <UploadFileIcon
                                color="inherit"
                                fontSize={"large"}
                            />
                            <Typography variant={"h6"}>{imageType}</Typography>
                        </Stack>
                        <Typography
                            variant="caption"
                            textAlign={"center"}
                            color={"#617889"}
                            gutterBottom
                        >
                            Drag and drop a <strong>.tif</strong> file to upload
                            as {imageType} for this collection.
                        </Typography>
                    </Stack>

                    {/* Select a file button */}
                    <Button
                        component={"label"}
                        variant={"contained"}
                        size={"small"}
                        loadingPosition={"end"}
                        startIcon={<AttachFileIcon fontSize={"inherit"} />}
                    >
                        Select a file
                        <VisuallyHiddenInput
                            type={"file"}
                            accept={".tif"}
                            onChange={handleFileAttach}
                        />
                    </Button>
                </Stack>
            </div>
        );
    }

    // if the collection exists and there is no image, return null
    if (collection && !image) {
        return null;
    }
};
