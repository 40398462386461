import { IImage, ImageType, IUploadImage, uploadImage } from "@api";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface IPendingImagePanel {
    /**
     * The image to be uploaded
     */
    image: File;
    /**
     * The id of the collection to which the image will be uploaded
     */
    collectionId?: number;
    /**
     * The type of the image
     */
    imageType: string;
    /**
     * Function to set the image after it has been uploaded
     */
    setImage: (image: IImage) => void;
}

/**
 * A panel that displays the image that is ready to be uploaded, or is starting an upload
 */
export const PendingImagePanel = ({
    image,
    collectionId,
    imageType,
    setImage,
}: IPendingImagePanel) => {
    const queryClient = useQueryClient();
    const { setValue } = useFormContext();

    const [imageUploaded, setImageUploaded] = useState<boolean>(false);

    // mutation to upload the image
    const uploadImageMutation = useMutation({
        mutationFn: (imageUpload: IUploadImage) => uploadImage(imageUpload),
        mutationKey: ["uploadImage"],
        onSettled: (result) => {
            queryClient.invalidateQueries({ queryKey: ["collections"] });
            queryClient.invalidateQueries({
                queryKey: ["location-collections"],
            });
            if (result) setImage(result.data);
        },
    });

    const handleImageUpload = useCallback(() => {
        if (collectionId && !imageUploaded) {
            uploadImageMutation.mutate({
                collectionId,
                fileName: image.name,
                type: imageType as ImageType,
                image: image,
            } as IUploadImage);
            setImageUploaded(true);
        }
    }, [collectionId, image, imageType, imageUploaded, uploadImageMutation]);

    // Upload the image when the collectionId is set
    useEffect(() => {
        handleImageUpload();
    }, [handleImageUpload]);

    // remove the image from the form
    const removeImage = () => {
        setValue(`images.${imageType}`, null);
    };

    return (
        <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            spacing={2}
            border={1}
            borderColor={"primary.main"}
            borderRadius={2}
            width={"100%"}
            direction={"row"}
        >
            <Stack>
                {/* Starting upload indicator when mutation is pending */}
                {uploadImageMutation.isPending && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Skeleton
                            variant="circular"
                            width={6}
                            height={6}
                            sx={{
                                bgcolor: "warning.main",
                            }}
                        ></Skeleton>
                        <Typography variant={"caption"} color={"#0e2127"}>
                            Starting upload
                        </Typography>
                    </Stack>
                )}
                <Typography variant={"h6"} color={"primary"}>
                    {image.name}
                </Typography>
                <Typography variant={"body2"}>
                    Image type: {imageType}
                </Typography>
                <Typography variant={"caption"} color={"#617889"}>
                    {(image.size / 1000000).toFixed(2)} MB
                </Typography>
            </Stack>
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                justifyContent={"center"}
            >
                {/* Shows a icon button to remove the image if it hasn't been uploaded yet. */}
                {!uploadImageMutation.isPending && (
                    <IconButton onClick={removeImage}>
                        <ClearIcon fontSize={"small"} color={"error"} />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
};
