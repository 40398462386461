import { IconButton, Tooltip } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { VisuallyHiddenInput } from "@components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ACCEPTED_IMAGE_FORMATS, MAX_ATTACHMENT_LIMIT } from "@config";
import { IFileAttachment } from "@api";
import { enqueueSnackbar } from "notistack";

interface IProps {
    disabled?: boolean;
}

export const UploadAttachment = ({ disabled }: IProps) => {
    const { setValue, getValues, watch } = useFormContext();

    // Handle file attachment when user selects a file
    const handleFileAttach = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];

        if (!file) return;

        const attachments = getValues("attachments");

        // Check for duplicates
        const isDuplicate = attachments.some(
            (attachment: IFileAttachment) =>
                attachment.file.name === file.name &&
                attachment.file.type === file.type
        );

        if (isDuplicate) {
            // show warning
            enqueueSnackbar("File already attached", { variant: "warning" });

            // Reset the input value
            event.target.value = "";
            return;
        }

        const fileAttachment: IFileAttachment = { id: -1, file };
        setValue("attachments", [...attachments, fileAttachment]);

        // Reset the input value
        event.target.value = "";
    };

    return (
        <Tooltip title={`Accepted image formats: ${ACCEPTED_IMAGE_FORMATS}`}>
            <span>
                <IconButton
                    size={"medium"}
                    disabled={disabled}
                    component={
                        watch("attachments").length < MAX_ATTACHMENT_LIMIT
                            ? "label"
                            : "button"
                    }
                >
                    <AddPhotoAlternateIcon fontSize="medium" />
                    <VisuallyHiddenInput
                        type={"file"}
                        accept={ACCEPTED_IMAGE_FORMATS}
                        onChange={handleFileAttach}
                    />
                </IconButton>
            </span>
        </Tooltip>
    );
};
