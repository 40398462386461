import { Box, Toolbar } from "@mui/material";
import { PropsWithChildren } from "react";
import { PageHeader } from "./PageHeader.tsx";

interface IDashboardContainerProps {
    /**
     * Title of the dashboard page
     */
    title?: string;
    /**
     * Action to be rendered in the toolbar
     */
    action?: React.ReactNode;
}

/**
 * DashboardContainer component is a wrapper component for dashboard pages. It includes a page header and children components.
 * @param title - Title of the dashboard page
 * @param children - Children components to be rendered inside the dashboard container
 *
 */
export const DashboardContainer = ({
    title,
    action,
    children,
}: PropsWithChildren<IDashboardContainerProps>) => {
    const showToolbar = title || action;

    return (
        <Box
            display="flex"
            flexDirection="column"
            p={{ xs: 2, sm: 4 }}
            flexGrow={1}
            overflow={"hidden"}
        >
            {showToolbar && (
                <Toolbar disableGutters>
                    {title && <PageHeader title={title} />}
                    <Box flexGrow={1} />
                    {action && action}
                </Toolbar>
            )}

            {children}
        </Box>
    );
};
