import {
    AutocompleteRenderInputParams,
    CircularProgress,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";

interface IRenderInputProps {
    /**
     * Autocomplete render input params
     */
    params: AutocompleteRenderInputParams;
    /**
     * Loading state
     */
    loading: boolean;
}

/**
 * Render input component for the location autocomplete
 */
export const RenderInput = ({ params, loading }: IRenderInputProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <TextField
            {...params}
            label="Search a location"
            fullWidth
            size={isMobile ? "small" : "medium"}
            variant={"standard"}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? (
                            <CircularProgress color="inherit" size={16} />
                        ) : null}
                        {params.InputProps.endAdornment}
                    </>
                ),
            }}
        />
    );
};
