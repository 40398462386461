import { ILocation } from "@api";
import { Control } from "@components";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Tooltip } from "@mui/material";
import { RefObject, useCallback, useEffect, useState } from "react";
import { MapRef } from "react-map-gl";
import { IViewState } from "../../location-editor/LocationSelect";

interface ILocationButtonProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
    /**
     * Current location
     */
    location: ILocation;
    /**
     * Current view state of the map
     */
    viewState: IViewState;
}

/**
 * Location control button that flies to the location when clicked
 */
export const LocationControl = ({
    mapRef,
    location,
    viewState,
}: ILocationButtonProps) => {
    const [disabled, setDisabled] = useState(true);

    // Fly to the location when the button is clicked
    const handleClick = () => {
        mapRef.current?.flyTo({
            center: [
                location.center_coordinates[1],
                location.center_coordinates[0],
            ],
            zoom: location.zoom,
        });

        // Disable the button after the location is reached
        setDisabled(true);
    };

    // Disable the button if the view state is the same as the location
    const handleButtonDisable = useCallback(() => {
        setDisabled(
            viewState.latitude.toFixed(8) ===
                location.center_coordinates[0].toFixed(8) &&
                viewState.longitude.toFixed(8) ===
                    location.center_coordinates[1].toFixed(8) &&
                viewState.zoom === location.zoom
        );
    }, [location, viewState]);

    // call the function to disable the button when the view state changes
    useEffect(() => {
        handleButtonDisable();
    }, [handleButtonDisable, mapRef, viewState]);

    return (
        <Tooltip
            title={"Go to location"}
            PopperProps={{
                disablePortal: true,
            }}
            placement="left"
        >
            <span>
                <Control disabled={disabled} onClick={handleClick}>
                    <LocationOnIcon />
                </Control>
            </span>
        </Tooltip>
    );
};
