import {
    Box,
    ListItemIcon,
    Menu,
    MenuItem,
    ButtonProps,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useState } from "react";
import { useAuth, useOrganizations } from "@components";
import { IOrganization } from "@api";
import BusinessIcon from "@mui/icons-material/Business";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import { useQueryClient } from "@tanstack/react-query";

interface IOrganizationSwitcherProps {
    /**
     * If true, the button will take up the full width of its container.
     * @default false
     */
    fullWidth?: boolean;
    /**
     * The color of the component. It supports those theme colors from theme.palette.
     * @default "inherit"
     */
    color?: ButtonProps["color"];
    /**
     * The variant to use.
     * @default "text"
     */
    variant?: ButtonProps["variant"];
    /**
     * Function to handle closing the drawer when the organization is changed.
     * @default undefined
     */
    handleDrawerClose?: () => void;
}

/**
 * OrganizationSwitcher component
 * Handles switching between organizations using the `OrganizationsContext`
 */
export const OrganizationSwitcher = ({
    fullWidth = false,
    color = "inherit",
    variant = "text",
    handleDrawerClose,
}: IOrganizationSwitcherProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
    const queryClient = useQueryClient();

    const {
        currentOrganization,
        organizations,
        setCurrentOrganization,
        isLoading,
    } = useOrganizations();

    // state to handle closing the drawer if the user is on mobile and the organization is changed
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [shouldClose, setShouldClose] = useState(false);

    const open = Boolean(anchorEl);

    // opens the menu
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // closes the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // closes the drawer after the menu closes if the user is on mobile and the organization is changed
    const handleTransitionExited = () => {
        if (isMobile && handleDrawerClose && shouldClose) {
            handleDrawerClose();
            setShouldClose(false);
        }
    };

    const { user } = useAuth();

    const handleOrganizationChange = (organization: IOrganization) => {
        if (currentOrganization && currentOrganization.id === organization.id) {
            handleClose();
            return;
        }
        setCurrentOrganization(organization, true);
        queryClient.invalidateQueries({
            queryKey: ["organizationLocations"],
        });
        setShouldClose(true);
        handleClose();
    };

    // return nothing if user is not defined
    if (!user) {
        return null;
    }

    return (
        <Box flexGrow={1} mx={2}>
            <Button
                variant={variant}
                color={color}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={handleClick}
                loading={isLoading}
                loadingPosition={"end"}
                fullWidth={fullWidth}
                disableRipple
                sx={{ textTransform: "none" }}
            >
                {currentOrganization
                    ? currentOrganization.name
                    : "Select an organization"}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onTransitionExited={handleTransitionExited}
            >
                <MenuItem disabled dense>
                    Organizations
                </MenuItem>
                {organizations.map((organization) => (
                    <MenuItem
                        key={organization.id}
                        selected={currentOrganization?.id === organization.id}
                        onClick={() => handleOrganizationChange(organization)}
                        dense
                    >
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        {organization.name}
                    </MenuItem>
                ))}
                {organizations.length === 0 && (
                    <MenuItem dense disableRipple>
                        <ListItemIcon>
                            <DomainDisabledIcon />
                        </ListItemIcon>
                        No organizations found
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};
