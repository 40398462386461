import { IAnnotation, IGeometryCreate } from "@api";
import { Control } from "@components";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import BorderClearSharpIcon from "@mui/icons-material/BorderClearSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import PolylineIcon from "@mui/icons-material/Polyline";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { ButtonGroup, Tooltip } from "@mui/material";
import { Feature } from "geojson";
import { IControl } from "mapbox-gl";
import { RefObject, useEffect, useRef } from "react";
import { MapRef } from "react-map-gl";

interface IDrawControlProps {
    /**
     * Reference to the map component
     */
    mapRef: RefObject<MapRef>;
    /**
     * Function to set the new geometry
     */
    setNewGeometry: (geometry: IGeometryCreate | undefined) => void;
    /**
     * The new geometry being created
     */
    newGeometry?: IGeometryCreate;
    /**
     * The annotation being edited
     */
    annotationEditing?: IAnnotation;
}

/**
 * Control for drawing different type of geometries on the map and editing existing annotations
 */
export const DrawControl = ({
    mapRef,
    setNewGeometry,
    newGeometry,
    annotationEditing,
}: IDrawControlProps) => {
    const drawRef = useRef<MapboxDraw | null>(null);

    useEffect(() => {
        // Wait for the map to be initialized
        if (!mapRef.current) return;

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {},
        });

        drawRef.current = draw;
        mapRef.current.addControl(draw as IControl);

        // Listen for when a new geometry is created
        mapRef.current.on(
            "draw.create" as unknown as never,
            (e: { features: Array<Feature> }) => {
                const geojson = e.features[0];

                console.log(e);

                const createGeometry: IGeometryCreate = {
                    geojson,
                };

                setNewGeometry(createGeometry);
            }
        );

        // Listen for when a geometry is updated
        mapRef.current.on(
            "draw.update" as unknown as never,
            (e: { features: Array<Feature> }) => {
                const geojson = e.features[0];
                console.log(e);
                const createGeometry: IGeometryCreate = {
                    geojson,
                };

                setNewGeometry(createGeometry);
            }
        );

        return () => {
            if (mapRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                mapRef.current.removeControl(draw as IControl);
            }
        };
    }, [mapRef, setNewGeometry]);

    // handles clicking the draw button for different modes
    const handleDrawButtonClick = (mode: string) => {
        if (drawRef.current) {
            // Toggle the draw mode on button click
            const isDrawing =
                drawRef.current.getMode() ===
                    drawRef.current.modes.DRAW_POLYGON ||
                drawRef.current.getMode() ===
                    drawRef.current.modes.DRAW_LINE_STRING ||
                drawRef.current.getMode() === drawRef.current.modes.DRAW_POINT;

            // If the user is already drawing, change the mode to simple_select
            if (isDrawing && mode === drawRef.current.getMode()) {
                drawRef.current.changeMode("simple_select");
            } else {
                // If there is an existing geometry, remove it
                setNewGeometry(undefined);
                // If the user is not drawing, change the mode to the selected mode
                setTimeout(() => {
                    drawRef.current?.changeMode(mode);
                }, 0);
            }
        }
    };

    // deletes the geometry being drawn
    const handleDeleteButtonClick = () => {
        setNewGeometry(undefined);
    };

    // Listens for newGeometry being unset and deletes the geometry from the map
    useEffect(() => {
        if (!newGeometry && drawRef.current) {
            drawRef.current.deleteAll();
        }
    }, [newGeometry]);

    useEffect(() => {
        if (annotationEditing && drawRef.current) {
            // add the editable geometry to the draw control
            drawRef.current.add(annotationEditing.geometry.geojson);

            // change draw mode to allow for editing
            if (annotationEditing.geometry.geojson.geometry.type === "Point") {
                // Use simple select as direct_select is not supported for points
                drawRef.current.changeMode("simple_select", {
                    featureIds: [
                        annotationEditing.geometry.geojson.id as string,
                    ],
                });
            } else {
                drawRef.current.changeMode("direct_select", {
                    featureId: annotationEditing.geometry.geojson.id as string,
                });
            }
        } else if (!annotationEditing && drawRef.current) {
            // if annotation is no longer being edited, remove the geometry from the draw control
            drawRef.current.deleteAll();
        }
    }, [annotationEditing]);

    return (
        <ButtonGroup
            orientation={"vertical"}
            sx={{
                "& .MuiButtonGroup-grouped": {
                    minWidth: 0,
                },
            }}
        >
            {/* Polygon Button */}
            <Tooltip
                title={"Draw polygon"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control
                    onClick={() => {
                        if (drawRef.current) {
                            handleDrawButtonClick(
                                drawRef.current.modes.DRAW_POLYGON
                            );
                        }
                    }}
                >
                    <BorderClearSharpIcon />
                </Control>
            </Tooltip>

            {/* Line Button */}
            <Tooltip
                title={"Draw line"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control
                    onClick={() => {
                        if (drawRef.current) {
                            handleDrawButtonClick(
                                drawRef.current.modes.DRAW_LINE_STRING
                            );
                        }
                    }}
                >
                    <PolylineIcon />
                </Control>
            </Tooltip>

            {/* Point Button */}
            <Tooltip
                title={"Draw point"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control
                    onClick={() => {
                        if (drawRef.current) {
                            handleDrawButtonClick(
                                drawRef.current.modes.DRAW_POINT
                            );
                        }
                    }}
                >
                    <RadioButtonCheckedIcon />
                </Control>
            </Tooltip>

            {/* Delete button only appears when a new geometry is present */}
            {newGeometry && !annotationEditing && (
                <Tooltip
                    title={"Delete geometry"}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    placement="left"
                >
                    <Control onClick={handleDeleteButtonClick}>
                        <DeleteIcon />
                    </Control>
                </Tooltip>
            )}
        </ButtonGroup>
    );
};
