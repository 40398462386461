import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import { useDebouncedEffect } from "@react-hookz/web";
import DOMPurify from "dompurify";
import {
    RefObject,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { MapRef } from "react-map-gl";
import { IViewState } from "../LocationSelect.tsx";
import { RenderInput } from "./RenderInput.tsx";
import { RenderOption } from "./RenderOption.tsx";
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import AutocompleteService = google.maps.places.AutocompleteService;
import PlacesService = google.maps.places.PlacesService;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;
import PlaceResult = google.maps.places.PlaceResult;

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService: {
    current: AutocompleteService | null;
} = { current: null };
const placesService: {
    current: PlacesService | null;
} = { current: null };

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
    place_id: string;
}

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

interface IAddressAutoCompleteProps {
    /**
     * The current view state of the map
     */
    viewState: IViewState;
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
    /**
     * Determines if the map is transitioning
     */
    isMapTransitioning: boolean;
    /**
     * Determines if the mouse is moving
     */
    isMouseMoving: boolean;
}

export const LocationAutoComplete = ({
    viewState,
    mapRef,
    isMapTransitioning,
    isMouseMoving,
}: IAddressAutoCompleteProps) => {
    const { setValue: setFormValue } = useFormContext();
    const [value, setValue] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly PlaceType[]>([]);
    const loaded = useRef(false);
    const [loading, setLoading] = useState(false);
    const [shouldReverseGeocode, setShouldReverseGeocode] = useState(true);

    // load the google maps script
    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&loading=async&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }

        loaded.current = true;
    }

    const setNameValue = useCallback(
        (name: string) => {
            // google recommends sanitizing the name to prevent XSS attacks as the place.name is what the user entered
            const cleanName = DOMPurify.sanitize(name);
            setFormValue("name", cleanName);
        },
        [setFormValue]
    );

    // handle setting the marker and flying to the location
    const setMarkerAndFlyTo = useCallback(
        (place: google.maps.places.PlaceResult) => {
            if (!place.geometry?.location) return;

            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();

            const zoom = 15;

            // if the place has a name, we want to prefill the name field in the form
            if (place.name) {
                setNameValue(place.name);
            }

            mapRef.current?.flyTo({
                center: [longitude, latitude],
                zoom: zoom,
            });
        },
        [mapRef, setNameValue]
    );

    // fetch details for a specific place
    const fetchPlaceDetails = useCallback(
        (place: PlaceType) => {
            setLoading(true);
            placesService.current?.getDetails(
                { placeId: place.place_id },
                (place: PlaceResult | null, status: PlacesServiceStatus) => {
                    setLoading(false);
                    if (status === "OK" && place) {
                        setMarkerAndFlyTo(place);
                    }
                }
            );
        },
        [setMarkerAndFlyTo]
    );

    const handleValue = useCallback(
        (value: PlaceType | null, shouldGetDetails = false) => {
            if (!value) return;

            setValue(value);

            setNameValue(value.structured_formatting.main_text);

            if (shouldGetDetails) {
                // if places service is not loaded, load it
                if (!placesService.current && window.google) {
                    placesService.current =
                        new window.google.maps.places.PlacesService(
                            document.createElement("div")
                        );
                }

                // if places service is still not loaded, return
                if (!placesService.current) {
                    return undefined;
                }

                // if a value (place) is set, fetch the place details

                fetchPlaceDetails(value);
            }
        },
        [fetchPlaceDetails, setNameValue]
    );

    const reverseGeocode = (latitude: number, longitude: number) => {
        setLoading(true);
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: latitude, lng: longitude };

        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results) {
                const result = results[0];
                const address = result.formatted_address;

                fetchOptions(true, address, true);
            }
        });
    };

    // function to handle fetching place predictions
    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results: AutocompletePrediction[] | null) => void
                ) => {
                    setLoading(true);
                    autocompleteService.current?.getPlacePredictions(
                        request,
                        callback
                    );
                },
                400
            ),
        []
    );

    const fetchOptions = useCallback(
        (active: boolean, inputVal: string, shouldSetFirstOption = false) => {
            // if autocomplete service is not loaded, load it
            if (!autocompleteService.current && window.google) {
                autocompleteService.current =
                    new window.google.maps.places.AutocompleteService();
            }

            // if autocomplete service is still not loaded, return
            if (!autocompleteService.current) {
                return undefined;
            }

            //  if the input value is empty set the options to the current value or an empty array
            if (inputVal === "") {
                setOptions(value ? [value] : []);
                return undefined;
            }

            // fetch the place predictions
            fetch(
                { input: inputVal },
                (results: AutocompletePrediction[] | null) => {
                    setLoading(false);
                    if (active) {
                        let newOptions: readonly PlaceType[] = [];

                        if (value) {
                            newOptions = [value];
                        }

                        if (results) {
                            newOptions = [...newOptions, ...results];
                        }

                        // if the first option should be set, set it
                        if (shouldSetFirstOption) {
                            if (newOptions.length > 1) {
                                handleValue(newOptions[1]);
                            } else {
                                handleValue(newOptions[0]);
                            }
                        }

                        setOptions(newOptions);
                    }
                }
            );
        },
        [fetch, handleValue, value]
    );

    useEffect(() => {
        let active = true;

        fetchOptions(active, inputValue);

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, fetchOptions]);

    useDebouncedEffect(
        () => {
            // if the map is moving or we don't want to reverse geocode, return
            if (isMapTransitioning || isMouseMoving || !shouldReverseGeocode)
                return;

            reverseGeocode(viewState.latitude, viewState.longitude);
        },
        [viewState, isMapTransitioning, isMouseMoving, shouldReverseGeocode],
        250
    );

    useEffect(() => {
        // if the mouse is moving, set the should reverse geocode to true
        if (isMouseMoving) {
            setShouldReverseGeocode(true);
        }
    }, [isMouseMoving]);

    return (
        <Autocomplete
            sx={{
                background: "#ffffff",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                p: 1,
                maxWidth: 400,
            }}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
            }
            isOptionEqualToValue={(option, value) => {
                if (!value) return false;

                if (typeof value === "string") return false;

                return option.place_id === value.place_id;
            }}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            loading={loading}
            includeInputInList
            blurOnSelect
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            onChange={(_: SyntheticEvent, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);

                // should not reverse geocode when a location is selected
                setShouldReverseGeocode(false);
                handleValue(newValue, true);
            }}
            onInputChange={(_: SyntheticEvent, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <RenderInput params={params} loading={loading} />
            )}
            renderOption={(props, option) => (
                <RenderOption
                    key={option.place_id}
                    props={props}
                    option={option}
                />
            )}
        />
    );
};
