import { getUserById, updateUser } from "@api";
import {
    Card,
    CardContent,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    FormButton as Button,
    FormContainer,
    FormEmailElement,
    FormTextFieldElement,
} from "@rhf-kit/mui";
import {
    useMutation,
    useQueryClient,
    useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { appRoutes } from "@config";

interface IFormData {
    firstName: string;
    lastName: string;
    email: string;
}

/**
 * A card component that allows the user to edit a member's details.
 */
export const MembersEditCard = () => {
    const { memberId } = useParams<{ memberId: string }>();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    const { data: userData } = useSuspenseQuery({
        queryKey: ["member", memberId],
        queryFn: () => getUserById(Number(memberId)),
    });

    const { mutate, isPending: mutationPending } = useMutation({
        mutationFn: updateUser,
        onSuccess: () => {
            enqueueSnackbar("Member details updated successfully", {
                variant: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["member", memberId] });
            navigate(-1);
        },
        onError: () => {
            enqueueSnackbar("An error occurred while updating member details", {
                variant: "error",
            });
        },
    });

    // If the memberId is not present, navigate to the members page
    if (!memberId) {
        navigate(appRoutes.organization.members.base);
    }

    const handleSaveChanges = (data: IFormData) => {
        mutate({
            id: userData.id,
            data: {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                is_admin: userData.is_admin,
                is_disabled: userData.is_disabled,
            },
        });
    };

    return (
        <Card sx={{ borderRadius: 3, maxWidth: "md" }} elevation={2}>
            <CardContent>
                <Typography
                    variant={isMobile ? "h6" : "h5"}
                    fontWeight="600"
                    gutterBottom
                >
                    Edit Member Details
                </Typography>

                <FormContainer
                    defaultValues={{
                        firstName: userData.first_name,
                        lastName: userData.last_name,
                        email: userData.email,
                    }}
                    onSuccess={handleSaveChanges}
                >
                    <FormTextFieldElement
                        fullWidth
                        size={isMobile ? "small" : "medium"}
                        name="firstName"
                        label="First Name"
                        margin={"normal"}
                        required
                    />
                    <FormTextFieldElement
                        fullWidth
                        size={isMobile ? "small" : "medium"}
                        name="lastName"
                        label="Last Name"
                        margin={"normal"}
                        required
                    />

                    <FormEmailElement
                        fullWidth
                        size={isMobile ? "small" : "medium"}
                        renderIcon={false}
                        name="email"
                        label="Email"
                        margin={"normal"}
                        required
                    />

                    <Button
                        variant="contained"
                        size={isMobile ? "small" : "medium"}
                        color="primary"
                        sx={{ mt: 1 }}
                        loadingPosition={"end"}
                        loading={mutationPending}
                    >
                        Edit member
                    </Button>
                </FormContainer>
            </CardContent>
        </Card>
    );
};
