import { Box } from "@mui/material";

export const FormBox = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            display="block"
            maxWidth={800}
            width="100%"
            sx={{
                padding: "20px",
            }}
        >
            {children}
        </Box>
    );
};

export const FormScreenContainer = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            {children}
        </Box>
    );
};
