import { IconButton, Menu } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React from "react";
import { IComment } from "@api";
import { DeleteComment } from "./DeleteComment.tsx";

interface ICommentOptionsProps {
    /**
     * Comment object.
     */
    comment: IComment;
}

/**
 * Comment options component that displays a menu with options to delete a comment.
 */
export const CommentOptions = ({ comment }: ICommentOptionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOptionsClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton size={"small"} edge={"end"} onClick={handleClick}>
                <MoreHorizIcon fontSize={"small"} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleOptionsClose}>
                <DeleteComment
                    comment={comment}
                    handleOptionsClose={handleOptionsClose}
                />
            </Menu>
        </>
    );
};
