import {
    getAnnotations,
    IAnnotation,
    IAnnotationCreate,
    ICollection,
    IFileAttachment,
    IGeometryCreate,
    ILocation,
} from "@api";

import { useAuth } from "@components";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useMutationState, useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { AnnotationCard } from "./AnnotationCard.tsx";
import { AnnotationCreate } from "./AnnotationCreate.tsx";

interface IOptimisticAnnotation {
    newAnnotation: IAnnotationCreate;
    attachments: IFileAttachment[];
}

interface IAnnotationsListProps {
    /**
     * Set the selected annotation
     */
    setSelectedAnnotation: (annotation: IAnnotation | undefined) => void;
    /**
     * The annotation that is currently being edited
     */
    annotationEditing?: IAnnotation;
    /**
     * The selected annotation
     */
    selectedAnnotation?: IAnnotation;
    /**
     * Set a new list of annotations
     */
    setAnnotations: (annotations: IAnnotation[]) => void;
    /**
     * Set the annotation that is currently being edited
     */
    setAnnotationEditing: React.Dispatch<
        React.SetStateAction<IAnnotation | undefined>
    >;
    /**
     * The new geometry that is being created
     */
    newGeometry?: IGeometryCreate;
    /**
     * Set the new geometry that is being created
     */
    setNewGeometry: (geometry: IGeometryCreate | undefined) => void;
    /**
     * The currently selected collection
     */
    collection?: ICollection;
    /**
     * The current location
     */
    location: ILocation;
}

/**
 * Annotations list component that displays a list of annotations and a form to create a new annotation
 * when a new geometry is present.
 */
export const AnnotationsList = ({
    setSelectedAnnotation,
    selectedAnnotation,
    setAnnotations,
    setAnnotationEditing,
    newGeometry,
    setNewGeometry,
    collection,
    location,
    annotationEditing,
}: IAnnotationsListProps) => {
    const { user } = useAuth();
    const refs = useRef<Record<string, HTMLDivElement | null>>({});

    const { data, isPending, isError } = useQuery({
        queryKey: [
            "annotations",
            collection?.id ?? -1,
            location.id,
            collection?.collection_date,
        ],
        queryFn: () => getAnnotations(location.id, collection?.collection_date),
    });

    // Get optimistic annotations that are pending creation
    const optimisticAnnotations = useMutationState<IOptimisticAnnotation>({
        filters: {
            mutationKey: ["createAnnotation"],
            status: "pending",
        },
        select: (mutation) => mutation.state.variables as IOptimisticAnnotation,
    });

    // Set the annotations when the data is available
    useEffect(() => {
        if (data) {
            setAnnotations(data.items);
        }
    }, [data, setAnnotations]);

    useEffect(() => {
        if (selectedAnnotation) {
            const ref = refs.current[selectedAnnotation.id];

            // scroll to the selected annotation
            if (ref) {
                ref.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
    }, [selectedAnnotation]);

    if (!user) return null;

    if (isPending) return <CircularProgress />;
    if (isError) return <div>Error</div>;

    // If there is nothing to show, don't render anything
    if (
        data.items.length === 0 &&
        optimisticAnnotations.length === 0 &&
        !newGeometry
    ) {
        return null;
    }

    return (
        <Box
            maxWidth={{ xl: "sm", lg: "450px", xs: "xl" }}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            pt={{ lg: 0, xs: 2 }}
        >
            <Typography
                variant={"h6"}
                fontWeight={600}
                gutterBottom
                ml={{ lg: 2, xs: 0 }}
            >
                Annotations
            </Typography>
            <Box
                px={{ lg: 2, xs: 0.5 }}
                pt={0.5}
                pb={{ xs: 2, sm: 4 }}
                overflow={"auto"}
            >
                <Stack spacing={2}>
                    {/* Render optimistic annotations */}
                    {optimisticAnnotations.map(
                        (optimisticAnnotation, index) => (
                            <AnnotationCard
                                key={`optimistic-${index}`}
                                annotation={
                                    {
                                        ...optimisticAnnotation.newAnnotation,
                                        id: -1,
                                        attachments:
                                            optimisticAnnotation.attachments,
                                    } as IAnnotation
                                }
                            />
                        )
                    )}

                    {/* Create a new annotation */}
                    {newGeometry && !annotationEditing && (
                        <AnnotationCreate
                            locationId={location.id}
                            newGeometry={newGeometry}
                            setNewGeometry={setNewGeometry}
                            setSelectedAnnotation={setSelectedAnnotation}
                            collection={collection}
                        />
                    )}

                    {/* Listed annotations */}
                    {data.items.map((annotation, index) => (
                        <div
                            key={`${annotation.id}-${index}`}
                            ref={(el) => (refs.current[annotation.id] = el)}
                        >
                            <AnnotationCard
                                selectedAnnotation={selectedAnnotation}
                                setSelectedAnnotation={setSelectedAnnotation}
                                annotation={annotation}
                                setAnnotationEditing={setAnnotationEditing}
                                annotationEditing={annotationEditing}
                                newGeometry={newGeometry}
                                setNewGeometry={setNewGeometry}
                            />
                        </div>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};
