import { Box, Stack, Typography } from "@mui/material";
import { ChangePasswordCard } from "./components";

export const Security = () => {
    return (
        <Box maxWidth={"lg"} width={"100%"}>
            <Typography variant={"h4"} gutterBottom>
                Security
            </Typography>
            <Stack>
                <ChangePasswordCard />
            </Stack>
        </Box>
    );
};
