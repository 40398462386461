import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { ISideNavRoute } from "./SideNav.tsx";
import { theme } from "@config";
import { MouseEvent } from "react";

const listItemButtonSx = (isActive: boolean) => {
    return {
        transition: "250ms",
        borderRadius: 1,
        bgcolor: (isActive && theme.palette.primary.light) || "transparent",
        color:
            (isActive && theme.palette.primary.contrastText) ||
            theme.typography.body1,
        ":hover": {
            bgcolor: isActive && theme.palette.primary.light,
        },
    };
};

interface IProps {
    route: ISideNavRoute;
    handleDrawerClose: () => void;
}

export const SideNavLink = ({ route, handleDrawerClose }: IProps) => {
    const handleClick = (
        event: MouseEvent<HTMLLIElement>,
        isActive: boolean
    ) => {
        if (isActive) {
            event.preventDefault();
        }
        handleDrawerClose();
    };

    return (
        <NavLink
            to={route.path}
            style={{
                textDecoration: "none",
                color: "inherit",
            }}
        >
            {({ isActive }) => (
                <ListItem
                    onClick={(event) => handleClick(event, isActive)}
                    key={route.name}
                >
                    <ListItemButton sx={listItemButtonSx(isActive)}>
                        <ListItemIcon
                            sx={{
                                color:
                                    (isActive &&
                                        theme.palette.primary.contrastText) ||
                                    theme.typography.body1,
                            }}
                        >
                            <route.icon />
                        </ListItemIcon>
                        <ListItemText primary={route.name} />
                    </ListItemButton>
                </ListItem>
            )}
        </NavLink>
    );
};
