import {
    getOrganizationCollections,
    ICollection,
    ICollectionUpdate,
    IImage,
    IUploadImage,
    updateCollection,
} from "@api";
import { DataTable, DateFilterInput } from "@components";
import { appRoutes, generateRoute } from "@config";
import { useFilterModel } from "@hooks";
import EditIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { Box, Stack } from "@mui/material";
import {
    GridActionsCellItem,
    GridColDef,
    GridRowSelectionModel,
    useGridApiRef,
} from "@mui/x-data-grid";
import {
    useMutation,
    useMutationState,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteSelected } from "./DeleteSelected";

export type CollectionRow = {
    id: number;
    collection_date: Date;
    images: number;
    collection: ICollection;
    location: string;
};

interface IProps {
    /**
     * The ID of the organization to fetch collections for
     */
    organizationId: number;
    /**
     * An existing collection that can be viewed
     */
    selectedCollection?: ICollection;
    /**
     * Function to set the selected collection
     */
    setSelectedCollection: (collection: ICollection | undefined) => void;
}

export const DroneCollectionsList = ({
    organizationId,
    setSelectedCollection,
    selectedCollection,
}: IProps) => {
    const [rows, setRows] = useState<CollectionRow[]>([]);
    const [selectedTableRows, setSelectedTableRows] = useState<CollectionRow[]>(
        []
    );
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const { filterModel, handleFilterModelChange } = useFilterModel();

    // handles the checkbox selection of rows
    const handleSelected = (rowSelectionModel: GridRowSelectionModel) => {
        const filteredCollections: CollectionRow[] = [];
        for (const row of rowSelectionModel) {
            const selectedRow = rows.find((r) => r.id === row);
            if (selectedRow) {
                filteredCollections.push(selectedRow);
            }
        }
        setSelectedTableRows(filteredCollections);
    };

    // handles when the collection date is updated using the cell editor
    const handleRowUpdate = (updatedRow: CollectionRow) => {
        const date = updatedRow.collection_date;

        const minDate = new Date("2000-01-01");
        const maxDate = new Date();

        // validate date
        if (isNaN(date.getTime()) || date < minDate || date > maxDate) {
            enqueueSnackbar(
                "Invalid date or not within an acceptable date range.",
                { variant: "error" }
            );
            return;
        }

        const collectionBody: ICollectionUpdate = {
            collection_date: date,
        };

        mutate({
            collectionId: updatedRow.id,
            collectionBody: collectionBody,
        });

        return updatedRow;
    };

    // Fetch collections from the API
    const { data, isFetching } = useQuery({
        queryKey: [
            "collections",
            organizationId,
            paginationModel.page,
            paginationModel.pageSize,
            filterModel,
        ],
        queryFn: () =>
            getOrganizationCollections(
                organizationId,
                paginationModel.page + 1,
                paginationModel.pageSize,
                filterModel
            ),
    });

    // Images that are being staged and uploaded to the API
    const optimisticImages = useMutationState<IUploadImage>({
        filters: {
            mutationKey: ["uploadImage"],
            status: "pending",
        },
        select: (mutation) => mutation.state.variables as IUploadImage,
    });

    // Update the collection when the mutation is triggered
    const { mutate } = useMutation({
        mutationFn: ({
            collectionId,
            collectionBody,
        }: {
            collectionId: number;
            collectionBody: ICollectionUpdate;
        }) => updateCollection(collectionId, collectionBody),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["collections"],
            });
            queryClient.invalidateQueries({
                queryKey: ["location-collections"],
            });
            enqueueSnackbar("Collection date updated successfully.", {
                variant: "success",
            });
        },
        onError: () => {
            enqueueSnackbar(
                "An error occurred while updating the collection.",
                {
                    variant: "error",
                }
            );
        },
    });

    // Update the rows when the data is fetched
    useEffect(() => {
        if (data) {
            const rowData = data.items.map((collection) => {
                // Find optimistic images for the collection if they exist and convert to IImage
                const collectionOptimisticImages = optimisticImages
                    .filter((image) => image.collectionId === collection.id)
                    .map((image) => {
                        const colImg = image as unknown as IImage;
                        colImg.id = -1;
                        colImg.created_at = new Date();
                        colImg.file_name = image.fileName;

                        return colImg;
                    });

                // The number to display in the Images column
                const collectionImages =
                    collection.images.length +
                    collectionOptimisticImages.length;

                // Add optimistic images to the collection
                collection.images.push(...collectionOptimisticImages);

                return {
                    collection: collection,
                    id: collection.id,
                    collection_date: new Date(collection.collection_date),
                    images: collectionImages,
                    location: collection.location.name,
                };
            });
            setRows(rowData);
        }
    }, [data, optimisticImages]);

    useEffect(() => {
        // If the selected collection is in the data, set it as the selected collection
        // This is necessary when moving from an optimistic state of collection images
        // to the actual collection images when the collection dialog is open
        if (data && selectedCollection) {
            const collection = data.items.find(
                (c) => c.id === selectedCollection.id
            );

            if (collection) {
                setSelectedCollection(collection);
            }
        }
    }, [data, selectedCollection, setSelectedCollection]);

    // Define the columns for the table.
    const columns: GridColDef<CollectionRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                display: "flex",
                sortingOrder: ["desc", "asc"],
            },
            {
                field: "collection_date",
                headerName: "Collection Date",
                type: "date",
                filterOperators: [
                    {
                        label: "Date is",
                        value: "dateIs",
                        getApplyFilterFn: (filterItem) => {
                            if (!filterItem.value) {
                                return null;
                            }
                            return ({ value }) => {
                                return value === filterItem.value;
                            };
                        },
                        InputComponent: DateFilterInput,
                    },
                ],
                display: "flex",
                editable: true,
                valueFormatter: (value) => {
                    if (value) {
                        return format(value, "MMM dd, yyyy");
                    }
                    return "";
                },
            },
            {
                field: "location",
                headerName: "Location",
                display: "flex",
            },
            {
                field: "images",
                headerName: "Images",
                display: "flex",
            },
            {
                field: "actions",
                type: "actions",
                getActions: (params) => [
                    <GridActionsCellItem
                        showInMenu
                        icon={<MapIcon color={"primary"} />}
                        label={"View on map"}
                        disabled={
                            !params.row.collection.images.find(
                                (image: IImage) => image.upload_complete
                            )
                        }
                        onClick={() =>
                            navigate(
                                generateRoute(
                                    appRoutes.organization.map.location.base,
                                    {
                                        locationId:
                                            params.row.collection.location_id,
                                    },
                                    {
                                        collectionDate:
                                            params.row.collection_date.toLocaleDateString(),
                                    }
                                )
                            )
                        }
                    />,
                    <GridActionsCellItem
                        showInMenu
                        icon={<EditIcon color={"primary"} />}
                        label={"Edit collection date"}
                        onClick={() =>
                            apiRef.current.startCellEditMode({
                                id: params.id,
                                field: "collection_date",
                            })
                        }
                    />,
                ],
            },
        ],
        [apiRef, navigate]
    );

    const autosizeOptions = {
        columns: ["id", "images", "collection_date", "location"],
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    };

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(data?.total || 0);

    const rowCount = useMemo(() => {
        if (data?.total !== undefined) {
            rowCountRef.current = data?.total;
        }
        return rowCountRef.current;
    }, [data?.total]);

    return (
        <Box minWidth={200} width={"100%"} height={"100%"}>
            {/* Table that displays a list of collections */}
            <DataTable
                apiRef={apiRef}
                loading={isFetching}
                rows={rows}
                columns={columns}
                checkboxSelection
                onRowSelectionModelChange={handleSelected}
                processRowUpdate={handleRowUpdate}
                rowCount={rowCount}
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode={"server"}
                autoSize
                sx={{
                    "& .MuiDataGrid-row:hover": {
                        cursor: "pointer",
                    },
                }}
                isRowSelectable={(params) =>
                    // Disable selection if the collection has images that are being uploaded.
                    // Cannot delete a collection until its images are uploaded.
                    !params.row.collection.images.find(
                        (image: IImage) => !image.upload_complete
                    )
                }
                autosizeOptions={autosizeOptions}
                onRowClick={(params, event) => {
                    if (!(event.target instanceof Element)) return;

                    // Check if the click is on a non-editable cell in the row
                    if (
                        (event.target.matches(".MuiDataGrid-cell") &&
                            !event.target.classList.contains(
                                "MuiDataGrid-cell--editable"
                            )) ||
                        event.target.matches(".MuiDataGrid-row")
                    ) {
                        setSelectedCollection(params.row.collection);
                    }
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                    },
                }}
                filterMode={"server"}
                sortingMode={"server"}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleFilterModelChange}
            />

            {/* Delete selected checked rows */}
            {selectedTableRows.length > 0 && (
                <Stack alignItems={"end"} pt={1}>
                    <DeleteSelected selected={selectedTableRows} />
                </Stack>
            )}
        </Box>
    );
};
