import { Card, CardActions, CardHeader } from "@mui/material";
import { FormContainer, FormButton as Button } from "@rhf-kit/mui";
import { useAuth } from "@components";
import {
    createAnnotation,
    IAnnotation,
    IAnnotationCreate,
    IFileAttachment,
    ICollection,
    IGeometryCreate,
} from "@api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatDate } from "@utils";
import { AnnotationForm } from "./AnnotationForm.tsx";

interface IFormData {
    title: string;
    attachments: IFileAttachment[];
}

interface IAnnotationCreateProps {
    newGeometry: IGeometryCreate;
    setNewGeometry: (geometry: IGeometryCreate | undefined) => void;
    locationId: number;
    setSelectedAnnotation: (annotation: IAnnotation) => void;
    collection?: ICollection;
}

export const AnnotationCreate = ({
    newGeometry,
    locationId,
    setSelectedAnnotation,
    setNewGeometry,
    collection,
}: IAnnotationCreateProps) => {
    const queryClient = useQueryClient();

    const { mutate, isPending } = useMutation({
        mutationFn: ({
            newAnnotation,
            attachments,
        }: {
            newAnnotation: IAnnotationCreate;
            attachments: IFileAttachment[];
        }) => createAnnotation(locationId, newAnnotation, attachments),
        mutationKey: ["createAnnotation"],
        onMutate: () => {
            setNewGeometry(undefined);
        },
        onSettled: (result) => {
            queryClient.invalidateQueries({
                queryKey: ["annotations"],
            });

            if (result) setSelectedAnnotation(result.data);
        },
    });

    const { user } = useAuth();
    if (!user) return null;

    const defaultValues: IFormData = {
        title: "",
        attachments: [],
    };

    const handleSubmit = (formData: IFormData) => {
        const annotationToCreate: IAnnotationCreate = {
            title: formData.title,
            geometry: newGeometry,
            collection_date: collection?.collection_date,
        };

        // create the annotation
        mutate({
            newAnnotation: annotationToCreate,
            attachments: formData.attachments,
        });
    };

    return (
        <Card elevation={4}>
            <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                subheaderTypographyProps={{ variant: "body2" }}
                title={"Add Annotation"}
                subheader={formatDate(new Date(), true)}
            />
            <FormContainer
                defaultValues={defaultValues}
                onSuccess={handleSubmit}
            >
                <AnnotationForm loading={isPending} />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => setNewGeometry(undefined)}
                        color={"secondary"}
                        type={"button"}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 48, maxHeight: 48 }}
                        loading={isPending}
                        variant={"contained"}
                    >
                        Add
                    </Button>
                </CardActions>
            </FormContainer>
        </Card>
    );
};
