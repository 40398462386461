import { ILocation } from "@api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from "@mui/material";
import { FormTextFieldElement } from "@rhf-kit/mui";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";

interface ILocationDetailsProps {
    /**
     * Location to edit
     */
    location?: ILocation;
    /**
     * Handle closing the dialog
     */
    handleClose: () => void;
    /**
     * Handle setting the form progress
     */
    handleFormProgress: (progress: string) => void;
    /**
     * Loading state
     */
    loading: boolean;
}

/**
 *Location details form that handles adding and editing location details
 */
export const LocationDetails = ({
    location,
    handleClose,
    handleFormProgress,
    loading,
}: ILocationDetailsProps) => {
    return (
        <>
            <DialogTitle>
                {/* Back button */}
                <IconButton
                    edge={"start"}
                    onClick={() => handleFormProgress("initial")}
                >
                    <ArrowBackIcon />
                </IconButton>
                {location
                    ? `Edit ${location.name} Details`
                    : "Add Location Details"}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    {/* name input */}
                    <FormTextFieldElement
                        name={"name"}
                        label={"Name"}
                        fullWidth
                        required
                    />

                    {/* description input */}
                    <FormTextFieldElement
                        name={"description"}
                        label={"Description"}
                        required
                        fullWidth
                        multiline
                        rows={5}
                    />

                    {/* latitude input */}
                    <FormTextFieldElement
                        name={"coordinates.latitude"}
                        label={"Latitude"}
                        required
                        fullWidth
                    />

                    {/* longitude input */}
                    <FormTextFieldElement
                        name={"coordinates.longitude"}
                        label={"Longitude"}
                        required
                        fullWidth
                    />

                    {/* zoom input */}
                    <FormTextFieldElement
                        name={"zoom"}
                        label={"Zoom"}
                        required
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                {/* Cancel button*/}
                <Button type={"button"} onClick={handleClose} color={"error"}>
                    Cancel
                </Button>

                {/* Save button */}
                <Button variant={"contained"} loading={loading}>
                    {location ? "Save" : "Add"}
                </Button>
            </DialogActions>
        </>
    );
};
