import axios from "axios";
import { API_URL } from "@config";
import { IFileAttachment } from "../attachments";

const API = `${API_URL}/comments`;

export const uploadCommentAttachments = async (
    commentId: number,
    attachments: IFileAttachment[]
) => {
    const formData = new FormData();
    attachments.forEach((attachment) => {
        formData.append("attachments", attachment.file);
    });

    const result = await axios.post(
        `${API}/${commentId}/attachments`,
        formData
    );

    return result.data;
};

export const deleteComment = async (commentId: number) => {
    const result = await axios.delete(`${API}/${commentId}`);
    return result.data;
};
