import { Navigate, Outlet } from "react-router-dom";
import {
    ErrorResetBoundary,
    OrganizationsProvider,
    useAuth,
} from "@components";
import { Box } from "@mui/material";

/**
 * ProtectedRoute component
 * Handles the protected routes that require auth
 */
const ProtectedRoute = () => {
    const { authenticated } = useAuth();

    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }

    return (
        <Box
            bgcolor={"#F4F7FE"}
            minHeight={"100vh"}
            display={"flex"}
            flexGrow={1}
            flexDirection={"column"}
        >
            <ErrorResetBoundary>
                <OrganizationsProvider>
                    <Outlet />
                </OrganizationsProvider>
            </ErrorResetBoundary>
        </Box>
    );
};

export default ProtectedRoute;
