import { ICollection } from "@api";
import { DashboardContainer, useOrganizations } from "@components";
import { useState } from "react";
import { AddDroneCollection, DroneCollectionsList } from "./components";

export default function DroneData() {
    const { currentOrganization } = useOrganizations();
    const [selectedCollection, setSelectedCollection] = useState<ICollection>();

    if (!currentOrganization) return null;

    return (
        <DashboardContainer
            title="Drone Data Collections"
            action={
                <AddDroneCollection
                    selectedCollection={selectedCollection}
                    setSelectedCollection={setSelectedCollection}
                />
            }
        >
            <DroneCollectionsList
                organizationId={currentOrganization.id}
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
            />
        </DashboardContainer>
    );
}
