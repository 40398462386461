import { ICollection, ILocation } from "@api";
import { useOutletContext } from "react-router-dom";
import { Tile } from "../MapPage";

interface IOutletContext {
    location: ILocation;
    currentCollection?: ICollection;
    tile: Tile;
}

export function useLocation() {
    return useOutletContext<IOutletContext>();
}
