import { Box, Typography } from "@mui/material";
import { FormBox, FormScreenContainer, Link, useAuth } from "@components";
import { resetPassword } from "@api";
import { useAsync } from "@react-hookz/web";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    FormContainer,
    FormPasswordElement,
    FormButton as Button,
} from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { project } from "@config";

interface FormData {
    resetToken: string;
    newPassword: string;
    confirmNewPassword: string;
}

const ResetPassword = () => {
    const { authenticated } = useAuth();
    const [resetPasswordState, resetPasswordActions] = useAsync(resetPassword);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSubmit = (data: FormData) => {
        const token = searchParams.get("token");

        if (!token) {
            return;
        }

        resetPasswordActions.execute({
            token: token,
            new_password: data.newPassword,
        });
    };

    useEffect(() => {
        if (authenticated) {
            navigate("/");
        }
    }, [authenticated, navigate]);

    useEffect(() => {
        if (resetPasswordState.status === "success") {
            enqueueSnackbar("Password reset successfully. Please sign in.", {
                variant: "success",
            });
            navigate("/login");
        }
        if (resetPasswordState.status === "error") {
            enqueueSnackbar(
                "There was an error trying to reset your password. Please verify your reset token.",
                {
                    variant: "error",
                }
            );
        }
    }, [resetPasswordState.status, navigate]);

    if (!searchParams.has("token")) {
        throw new Error("Reset token is required");
    }

    return (
        <FormScreenContainer>
            <FormBox>
                <FormContainer
                    defaultValues={{
                        newPassword: "",
                        confirmNewPassword: "",
                    }}
                    onSuccess={onSubmit}
                >
                    <Typography
                        variant={"h4"}
                        fontWeight={600}
                        textAlign={"center"}
                        gutterBottom
                    >
                        Reset Password
                    </Typography>
                    <Typography gutterBottom>
                        Enter and confirm your new password below to reset it.
                    </Typography>
                    <FormPasswordElement
                        name={"newPassword"}
                        required
                        fullWidth
                        label="New Password"
                        margin="normal"
                    />
                    <FormPasswordElement
                        name={"confirmNewPassword"}
                        required
                        fullWidth
                        label="Confirm New Password"
                        margin="normal"
                        rules={{
                            required: "Confirm New Password is required",
                            validate: (value, formValues) =>
                                value === formValues.newPassword ||
                                "Passwords do not match",
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingTop: 2,
                        }}
                    >
                        <Typography sx={{ mt: 0 }}>
                            <Link href="/login">Back to Sign In</Link>
                        </Typography>
                        <Button
                            disabled={resetPasswordState.status === "loading"}
                            variant="contained"
                        >
                            Reset Password
                        </Button>
                    </Box>
                </FormContainer>
            </FormBox>
            <Box color="GrayText">
                <Typography mt={2}>© 2024 {project.name}</Typography>
            </Box>
        </FormScreenContainer>
    );
};

export default ResetPassword;
