import {
    deleteCollection,
    deleteImage,
    getImage,
    ICollection,
    IImage,
} from "@api";
import { appRoutes, generateRoute } from "@config";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
    Box,
    CircularProgress,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface IImagePanelProps {
    /**
     * Image object
     */
    image: IImage;

    /**
     * Function to set the image object
     */
    setImage: (image: IImage | undefined) => void;
    /**
     * The collection that the image belongs to
     */
    collection: ICollection;
    /**
     * Function to close the modal
     */
    handleClose: () => void;
}

/**
 * This component is used to display an existing image inside the collection modal.
 */
export const ImagePanel = ({
    image,
    setImage,
    collection,
    handleClose,
}: IImagePanelProps) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // Check if the image is an optimistic image
    const isOptimistic = image.id === -1;

    // Query to get the image status every minute unless the image upload is complete
    const imageStatusQuery = useQuery({
        queryKey: ["imageStatus", image.id],
        queryFn: () => getImage(image.id),
        refetchInterval: 1000 * 60, // fetches every minute
        enabled: !image.upload_complete && image.id !== -1 && !image.error, // only fetch if image upload status is not complete and if the image is not optimistic
    });

    const invalidateQueries = useCallback(() => {
        queryClient.invalidateQueries({ queryKey: ["collections"] });
        queryClient.invalidateQueries({
            queryKey: ["location-collections"],
        });
    }, [queryClient]);

    // Update the image object when the image upload is complete
    useEffect(() => {
        if (
            imageStatusQuery.data &&
            (imageStatusQuery.data.upload_complete ||
                imageStatusQuery.data.error)
        ) {
            setImage(imageStatusQuery.data);
            invalidateQueries();
        }
    }, [imageStatusQuery.data, invalidateQueries, setImage]);

    const removeImageMutation = useMutation({
        mutationFn: () => deleteImage(image.id),
        onSuccess: () => {
            // If this is the only image in the collection, remove the collection
            if (collection.images.length <= 1) {
                removeCollectionMutation.mutate();
            } else {
                enqueueSnackbar("Image removed", {
                    variant: "success",
                });
                invalidateQueries();
                setImage(undefined);
            }
        },
        onError: () => {
            enqueueSnackbar("Failed to remove image", {
                variant: "error",
            });
        },
    });

    const removeCollectionMutation = useMutation({
        mutationFn: () => deleteCollection(collection.id),
        onSuccess: () => {
            enqueueSnackbar("Image and collection removed", {
                variant: "success",
            });
            invalidateQueries();
            handleClose();
        },
        onError: () => {
            enqueueSnackbar(
                "Failed to remove collection, successfully removed image",
                {
                    variant: "error",
                }
            );
        },
    });

    return (
        <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            spacing={2}
            border={1}
            borderColor={"primary.main"}
            borderRadius={2}
            width={"100%"}
            direction={"row"}
        >
            <Stack>
                {/* If the image is optimistic we want to show this upload state*/}
                {isOptimistic && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Skeleton
                            variant="circular"
                            width={6}
                            height={6}
                            sx={{
                                bgcolor: "warning.main",
                            }}
                        ></Skeleton>
                        <Typography variant={"caption"} color={"#0e2127"}>
                            Starting upload
                        </Typography>
                    </Stack>
                )}
                {/* If the upload is complete show this */}
                {image.upload_complete && !isOptimistic && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Box
                            bgcolor={"success.main"}
                            width={6}
                            height={6}
                            display={"inline-block"}
                            borderRadius={"9999px"}
                        />
                        <Typography variant={"caption"} color={"#0e2127"}>
                            Upload complete
                        </Typography>
                    </Stack>
                )}
                {/* If the upload is not complete show this */}
                {!image.upload_complete && !isOptimistic && !image.error && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Skeleton
                            variant="circular"
                            width={6}
                            height={6}
                            sx={{
                                bgcolor: "info.main",
                            }}
                        ></Skeleton>
                        <Typography variant={"caption"} color={"#0e2127"}>
                            Upload in progress
                        </Typography>
                    </Stack>
                )}

                {/* If the upload has an error show this */}
                {image.error && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Box
                            bgcolor={"error.main"}
                            width={6}
                            height={6}
                            display={"inline-block"}
                            borderRadius={"9999px"}
                        />
                        <Typography variant={"caption"} color={"#0e2127"}>
                            {image.error}
                        </Typography>
                    </Stack>
                )}

                {/* Name of the image */}
                <Typography variant={"h6"} color={"primary"}>
                    {image.file_name}
                </Typography>

                {/* Type of image */}
                <Typography variant={"body2"}>
                    Image type: {image.type}
                </Typography>

                {/* Uploaded date */}
                <Typography variant={"caption"} color={"#617889"}>
                    Uploaded on{" "}
                    {format(image.created_at, "MMM dd, yyyy 'at' hh:mm a")}
                </Typography>
            </Stack>

            {/* Button to navigate to image on map */}
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                justifyContent={"center"}
            >
                <Tooltip
                    title={
                        !image.error
                            ? "View image on map"
                            : "Remove failed upload"
                    }
                >
                    <span>
                        {!(
                            removeImageMutation.isPending ||
                            removeCollectionMutation.isPending
                        ) && (
                            <IconButton
                                disabled={
                                    !image.upload_complete && !image.error
                                }
                                onClick={() => {
                                    if (!image.error) {
                                        navigate(
                                            generateRoute(
                                                appRoutes.organization.map
                                                    .location.base,
                                                {
                                                    locationId:
                                                        collection.location_id,
                                                },
                                                {
                                                    collectionDate: new Date(
                                                        collection.collection_date
                                                    ).toLocaleDateString(),
                                                    imageType: image.type,
                                                }
                                            )
                                        );
                                    } else {
                                        removeImageMutation.mutate();
                                    }
                                }}
                            >
                                {!image.error && (
                                    <ChevronRightIcon
                                        color={"primary"}
                                        sx={{
                                            opacity: !image.upload_complete
                                                ? 0.6
                                                : 1,
                                        }}
                                    />
                                )}

                                {image.error && (
                                    <RemoveCircleIcon
                                        fontSize={"small"}
                                        color={"error"}
                                    />
                                )}
                            </IconButton>
                        )}

                        {removeImageMutation.isPending ||
                            (removeCollectionMutation.isPending && (
                                <CircularProgress size={16} />
                            ))}
                    </span>
                </Tooltip>
            </Stack>
        </Stack>
    );
};
