import axios from "axios";
import { API_URL } from "@config";
import { IImage, IImageUpdate, IImageUploadStatus } from "./images.types.ts";

const API = `${API_URL}/images`;

export const getImage = async (imageId: number) => {
    const result: { data: IImage } = await axios.get(`${API}/${imageId}`);
    return result.data;
};

export const updateImage = async (imageId: number, body: IImageUpdate) => {
    const result: { data: IImage } = await axios.put(`${API}/${imageId}`, body);
    return result.data;
};

export const getImageStatus = async (imageId: number) => {
    const result: { data: IImageUploadStatus } = await axios.get(
        `${API}/${imageId}/status`
    );
    return result.data;
};

export const deleteImage = async (imageId: number) => {
    const result = await axios.delete(`${API}/${imageId}`);
    return result.data;
};
