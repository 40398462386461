import { IAnnotation, IGeometryCreate, IImage, ImageType } from "@api";
import { MAPBOX_TOKEN } from "@config";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { GeoJSONFeature } from "mapbox-gl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, {
    MapMouseEvent,
    MapRef,
    MapTouchEvent,
    ViewState,
} from "react-map-gl";
import { useLocation } from "../../hooks/useLocation.ts";
import { AnnotationsList } from "../annotations";
import { AllAnnotationsSource } from "./AllAnnotationsSource.tsx";
import { ControlContainer } from "./controls/ControlContainer.tsx";
import { TileSource } from "./TileSource.tsx";

export interface Tile {
    tileRef: string;
    imageType: ImageType;
    createdAt: string | Date;
    image: IImage;
}

/**
 * Location Map component that displays the map, collection, and annotations
 */
export const LocationMap = () => {
    const { location, currentCollection: collection, tile } = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "lg"));
    const mapRef = useRef<MapRef>(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [newGeometry, setNewGeometry] = useState<IGeometryCreate>();
    const [selectedAnnotation, setSelectedAnnotation] = useState<IAnnotation>();
    const [annotations, setAnnotations] = useState<IAnnotation[]>();
    const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>(
        []
    );
    const [annotationEditing, setAnnotationEditing] = useState<IAnnotation>();

    // Initial view state for the map
    const initialViewState: ViewState = useMemo(() => {
        return {
            latitude: location.center_coordinates[0],
            longitude: location.center_coordinates[1],
            zoom: location.zoom,
            bearing: 0,
            pitch: 0,
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        };
    }, [location]);

    // View state for the map
    const [viewState, setViewState] = useState<ViewState>(initialViewState);

    // handle mouse click on map
    const handleAnnotationClick = (event: MapMouseEvent) => {
        // check if annotation is clicked
        annotationSelect(event.features);
    };

    // handle touch on mobile device
    const handleMobileTouch = (event: MapTouchEvent) => {
        // check if annotation is tapped
        annotationSelect(event.features);
    };

    // resize map on container resize
    const onResize = useCallback(() => {
        if (mapRef.current) {
            mapRef.current.resize();
        }
    }, [mapRef]);

    // handles annotation selection
    const annotationSelect = (features?: GeoJSONFeature[]) => {
        // Prevent annotation click if editing an annotation
        if (!annotations || annotationEditing || newGeometry) return;

        // if there are features, then an annotation was clicked or tapped
        if (features && features.length > 0) {
            const feature = features[0];

            if (!feature.layer) return;

            const layer = feature.layer;
            // Find annotation with a geojson id matching the source id
            const annotation = annotations.find(
                (annotation) => annotation.geometry.geojson.id === layer.source
            );

            setSelectedAnnotation(annotation);
        }
    };

    // Reset map state when location changes
    useEffect(() => {
        // Reset map state to initial view state
        const resetMapState = () => {
            setViewState(initialViewState);
        };

        resetMapState();
    }, [initialViewState]);

    // Set interactive layer ids when annotations are loaded
    useEffect(() => {
        if (annotations && mapLoaded) {
            // set interactive layer ids for annotations. These are used to select annotations on click
            setInteractiveLayerIds(
                annotations.map((annotation) =>
                    annotation.geometry.geojson.geometry.type === "LineString"
                        ? `${annotation.geometry.geojson.id}-buffer`
                        : `${annotation.geometry.geojson.id}`
                )
            );
        }
    }, [annotations, mapLoaded]);

    // Reset selected annotation when new geometry is created
    useEffect(() => {
        if (newGeometry) {
            setSelectedAnnotation(undefined);
        }
    }, [newGeometry]);

    return (
        <Box
            gap={1}
            display={isMobile ? "block" : "flex"}
            flexGrow={1}
            maxHeight={isMobile ? "none" : "calc(100vh - 120px)"}
            pt={2}
        >
            {/* Map */}
            <Map
                {...viewState}
                ref={mapRef}
                onLoad={() => setMapLoaded(true)}
                onMove={(event) => setViewState(event.viewState)}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={[...interactiveLayerIds]}
                onClick={handleAnnotationClick}
                onTouchStart={handleMobileTouch}
                onResize={onResize}
                onIdle={onResize}
                mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
                style={{ minHeight: 500, maxHeight: "calc(100vh - 120px)" }}
            >
                {/* Render Tile */}
                {mapLoaded && <TileSource tile={tile} />}

                {/* Render Annotations */}
                <AllAnnotationsSource
                    annotations={annotations}
                    selectedAnnotation={selectedAnnotation}
                    editingAnnotation={annotationEditing}
                />

                {/* Render Map Controls */}
                <ControlContainer
                    mapRef={mapRef}
                    location={location}
                    viewState={viewState}
                    setNewGeometry={setNewGeometry}
                    newGeometry={newGeometry}
                    annotationEditing={annotationEditing}
                />
            </Map>
            {/* Annotations */}
            <AnnotationsList
                selectedAnnotation={selectedAnnotation}
                setSelectedAnnotation={setSelectedAnnotation}
                setAnnotations={setAnnotations}
                setAnnotationEditing={setAnnotationEditing}
                annotationEditing={annotationEditing}
                newGeometry={newGeometry}
                setNewGeometry={setNewGeometry}
                collection={collection}
                location={location}
            />
        </Box>
    );
};
