import { useEffect, useMemo, useRef, useState } from "react";
import {
    GridActionsCellItem,
    GridColDef,
    GridRowParams,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteUser, getUsers, IUser } from "@api";
import { Box, Chip, Tooltip } from "@mui/material";
import { IOrganization } from "@api";
import { EditUsers } from "./EditUsers";
import { DataTable, DeleteConfirmationDialog } from "@components";
import { useQuery } from "@tanstack/react-query";
import { useFilterModel } from "@hooks";

export type UserRow = {
    id: number;
    organizations: IOrganization[];
    first_name: string;
    last_name: string;
    email: string;
    is_admin: boolean;
    is_superuser: boolean;
    user: IUser;
};

export const UsersTable = () => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const { filterModel, handleFilterModelChange } = useFilterModel();

    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [users, setUsers] = useState<UserRow[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserRow | null>(null);

    const autoSizeOptions = {
        columns: ["first_name", "last_name", "email", "organizations"],
        includeHeaders: true,
        expand: true,
    };

    const { data, isFetching } = useQuery({
        queryKey: [
            "users",
            paginationModel.page,
            paginationModel.pageSize,
            filterModel,
        ],
        queryFn: () =>
            getUsers(
                paginationModel.page + 1,
                paginationModel.pageSize,
                filterModel
            ),
    });

    const columns: GridColDef<UserRow>[] = [
        { field: "id", headerName: "ID", display: "flex" },
        { field: "first_name", headerName: "First name", display: "flex" },
        { field: "last_name", headerName: "Last name", display: "flex" },
        { field: "email", headerName: "Email", display: "flex" },
        {
            field: "organizations",
            headerName: "Organizations",
            display: "flex",
            sortable: false,
            renderCell({ row }) {
                return (
                    <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                        {row.organizations.map((organization) => (
                            <Tooltip
                                title={organization.name}
                                key={organization.id}
                            >
                                <Chip label={organization.name} size="small" />
                            </Tooltip>
                        ))}
                    </Box>
                );
            },
        },
        {
            field: "actions",
            type: "actions",
            getActions: ({ row }: GridRowParams<UserRow>) => [
                <GridActionsCellItem
                    showInMenu
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => {
                        setSelectedUser(row);
                        setEdit(true);
                    }}
                />,
                <GridActionsCellItem
                    showInMenu
                    icon={<DeleteIcon color="error" />}
                    label="Delete"
                    disabled={row.is_superuser}
                    onClick={() => {
                        setOpen(true);
                        setSelectedUser(row);
                    }}
                />,
            ],
        },
    ];

    useEffect(() => {
        if (data) {
            // Transform the data from IUser[] to UserRow[]
            const rows = data.items.map((user) => ({
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                is_admin: user.is_admin,
                is_superuser: user.is_superuser,
                organizations: user.organization_accounts.map(
                    (oa) => oa.organization
                ),
                user,
            }));

            setUsers(rows);
        }
    }, [data]);

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(data?.total || 0);

    const rowCount = useMemo(() => {
        if (data?.total !== undefined) {
            rowCountRef.current = data?.total;
        }
        return rowCountRef.current;
    }, [data?.total]);

    return (
        <>
            {edit ? (
                <EditUsers selectedUser={selectedUser!} setEdit={setEdit} />
            ) : (
                <Box minWidth={200} width="100%" height="100%">
                    <DataTable
                        columns={columns}
                        rows={users}
                        loading={isFetching}
                        autoSize
                        autosizeOptions={autoSizeOptions}
                        paginationMode={"server"}
                        rowCount={rowCount}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        filterMode={"server"}
                        sortingMode={"server"}
                        onFilterModelChange={handleFilterModelChange}
                        onSortModelChange={handleFilterModelChange}
                    />
                    {selectedUser && (
                        <DeleteConfirmationDialog
                            open={open}
                            setOpen={setOpen}
                            subject={selectedUser.email}
                            type="User"
                            deleteFunction={deleteUser}
                            queriesToInvalidate={["users"]}
                            idToDelete={selectedUser.id}
                        />
                    )}
                </Box>
            )}
        </>
    );
};
