import { IAnnotation, IGeometryCreate } from "@api";
import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import { AnnotationComments } from "./comments";

import { AnnotationHeader } from "./AnnotationHeader.tsx";
import { AnnotationTitle } from "./AnnotationTitle.tsx";

interface IAnnotationCardProps {
    /**
     * Annotation object.
     *
     */
    annotation: IAnnotation;
    /**
     * Set the selected annotation.
     */
    setSelectedAnnotation?: (annotation: IAnnotation | undefined) => void;
    /**
     * The selected annotation.
     */
    selectedAnnotation?: IAnnotation;
    /**
     * Set the annotation that is currently being edited.
     */
    setAnnotationEditing?: React.Dispatch<
        React.SetStateAction<IAnnotation | undefined>
    >;
    /**
     * The annotation that is currently being edited.
     */
    annotationEditing?: IAnnotation;
    /**
     * The new geometry that is being created.
     */
    newGeometry?: IGeometryCreate;
    /**
     * Set the new geometry that is being created.
     */
    setNewGeometry?: (geometry: IGeometryCreate | undefined) => void;
}

/**
 * Annotation card component that displays the annotation header, title, and comments.
 */
export const AnnotationCard = ({
    annotation,
    setSelectedAnnotation,
    selectedAnnotation,
    setAnnotationEditing,
    annotationEditing,
    newGeometry,
    setNewGeometry,
}: IAnnotationCardProps) => {
    const [isEditingAnnotation, setIsEditingAnnotation] = useState(false);

    // determine if the annotation is currently selected
    const isAnnotationSelected = annotation.id
        ? selectedAnnotation?.id === annotation.id
        : false;

    return (
        <Card
            id={"annotation-card"}
            elevation={isAnnotationSelected ? 4 : 1}
            sx={{
                cursor: "pointer",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                "&:hover": {
                    boxShadow: 4,
                },
            }}
            onClick={(e) => {
                if (
                    e.currentTarget.contains(e.target as Node) &&
                    !isEditingAnnotation &&
                    annotation.id !== -1
                ) {
                    if (
                        setSelectedAnnotation &&
                        !annotationEditing &&
                        !newGeometry
                    ) {
                        setSelectedAnnotation(annotation);
                    }
                }
            }}
        >
            <CardContent
                sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            >
                {/* Header */}
                <AnnotationHeader
                    annotation={annotation}
                    setSelectedAnnotation={setSelectedAnnotation}
                    setIsEditingAnnotation={setIsEditingAnnotation}
                    isAnnotationSelected={isAnnotationSelected}
                    setAnnotationEditing={setAnnotationEditing}
                />

                {/* Title */}
                <AnnotationTitle
                    annotation={annotation}
                    isEditingAnnotation={isEditingAnnotation}
                    setIsEditingAnnotation={setIsEditingAnnotation}
                    setAnnotationEditing={setAnnotationEditing}
                    newGeometry={newGeometry}
                    setNewGeometry={setNewGeometry}
                />

                {/* Comments */}
                <AnnotationComments
                    annotationId={
                        annotation.id !== -1 ? annotation.id : undefined
                    }
                />
            </CardContent>
        </Card>
    );
};
