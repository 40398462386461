import { IAnnotation } from "@api";
import { useAuth } from "@components";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React from "react";
import { DeleteAnnotation } from "./DeleteAnnotation.tsx";

interface IAnnotationOptionsProps {
    /**
     * Comment object.
     */
    annotation: IAnnotation;
    setSelectedAnnotation: (annotation: IAnnotation | undefined) => void;
    setIsEditingAnnotation: (isEditing: boolean) => void;
    setAnnotationEditing: React.Dispatch<
        React.SetStateAction<IAnnotation | undefined>
    >;
}

export const AnnotationOptions = ({
    annotation,
    setSelectedAnnotation,
    setIsEditingAnnotation,
    setAnnotationEditing,
}: IAnnotationOptionsProps) => {
    const { user } = useAuth();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleOptionsClose = () => {
        setAnchorEl(null);
    };

    if (user?.id !== annotation.created_by.id) {
        return null;
    }

    return (
        <>
            <IconButton size={"small"} edge={"end"} onClick={handleClick}>
                <MoreHorizIcon fontSize={"small"} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleOptionsClose}>
                <DeleteAnnotation
                    annotation={annotation}
                    handleOptionsClose={handleOptionsClose}
                    setSelectedAnnotation={setSelectedAnnotation}
                    setAnnotationEditing={setAnnotationEditing}
                />
                <MenuItem
                    dense
                    onClick={() => {
                        setIsEditingAnnotation(true);
                        setAnnotationEditing(annotation);
                        handleOptionsClose();
                    }}
                >
                    <ListItemIcon>
                        <EditIcon color={"primary"} />
                    </ListItemIcon>
                    Edit Annotation
                </MenuItem>
            </Menu>
        </>
    );
};
