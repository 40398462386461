import {
    getLocationCollections,
    ICollection,
    IImage,
    ILocation,
    ImageType,
} from "@api";
import { DashboardContainer, PageHeader, useOrganizations } from "@components";
import { Stack, Toolbar } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
    DroneCollectionSwitcher,
    DroneImageLayerSwitcher,
    LocationToolbar,
} from "./components";

export interface Tile {
    tileRef: string;
    imageType: ImageType;
    createdAt: string | Date;
    image: IImage;
}

const MapPage = () => {
    const { currentOrganization } = useOrganizations();
    const [selectedLocation, setSelectedLocation] = useState<ILocation>();
    const [currentCollection, setCurrentCollection] = useState<ICollection>();
    const [tiles, setTiles] = useState<Tile[]>();
    const [tile, setTile] = useState<Tile>();

    const { data: collections } = useSuspenseQuery({
        queryKey: [
            "location-collections",
            selectedLocation,
            selectedLocation?.id,
        ],
        queryFn: () => {
            if (!selectedLocation) return null;
            return getLocationCollections(selectedLocation.id);
        },
    });

    if (!currentOrganization) return null;

    return (
        <DashboardContainer>
            <Toolbar disableGutters sx={{ alignItems: "flex-start" }}>
                <Stack
                    direction="row"
                    spacing={{ xs: 2, sm: 3, md: 4 }}
                    alignItems={"center"}
                >
                    <PageHeader title="Map" />
                    <LocationToolbar
                        organizationId={currentOrganization.id}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                    />

                    {collections && collections.items.length > 0 && (
                        <>
                            <DroneCollectionSwitcher
                                collections={collections.items}
                                currentCollection={currentCollection}
                                setCollection={setCurrentCollection}
                            />

                            {currentCollection && (
                                <DroneImageLayerSwitcher
                                    collection={currentCollection}
                                    tiles={tiles}
                                    setTiles={setTiles}
                                    setTile={setTile}
                                    tile={tile}
                                />
                            )}
                        </>
                    )}
                </Stack>
            </Toolbar>
            {selectedLocation && (
                <Outlet
                    context={{
                        location: selectedLocation,
                        collections: collections?.items,
                        currentCollection,
                        setCurrentCollection,
                        tile,
                    }}
                />
            )}
        </DashboardContainer>
    );
};

export default MapPage;
